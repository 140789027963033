import React, { useCallback, useState } from "react";
import "./AddMemberModal.css";
import API_PATH from "../Constants/api-path";
import { Button, Divider, Form, Modal, Select, Spin, notification } from "antd";
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  WarningOutlined,
  LoadingOutlined,
} from "@ant-design/icons";

const AddMemberModal = ({
  ID,
  getProjectDetails = () => { },
  open,
  setOpen = () => { },
}) => {
  const [emailList, setEmailList] = useState([]);
  const [spinner, setSpinner] = useState(false);
  const userData = JSON.parse(localStorage.getItem("userData"));
  const [managerList, setManagerList] = useState([]);
  const [form] = Form.useForm();

  const handleSubmit = useCallback(async () => {
    // form.validateFields()
    setSpinner(true);

    let payload = {
      project_id: ID,
      invite_team: emailList?.map((ema) => ema?.split("@")?.[0]),
      login_name: userData?.data?.name,
    };
    if (payload.invite_team.length === 0 || emailList.length === 0) {
      document.getElementById("exampleModal").click();
      setSpinner(false);
      return notification.warning({
        message: "Warning: All fields are mandatory",
        icon: <WarningOutlined style={{ color: "#fff" }} />, // Custom warning icon
        style: {
          backgroundColor: "#f39c12", // Yellow background color
          color: "#fff", // Text color
          border: "1px solid #d35400", // Border color (optional)
          padding: "10px", // Padding (optional)
        },
        duration: 5, // Duration in seconds
        placement: "topRight", // Position of the notification
      });
    } else {
      try {
        const res = await fetch(`${API_PATH.PROJECT_INVITE_ADD}`, {
          method: "POST",
          body: JSON.stringify(payload),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userData?.token}`,
          },
        });
        getProjectDetails();
        const result = await res.json();
        if (result) {
          if (result.status === 200 || result.status === true) {
            setSpinner(false);
            setOpen(false);
            // document.getElementById("exampleModal").click();
            setEmailList([]);
            notification.success({
              message: "Invitation has been created",
              icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
              style: {
                backgroundColor: "#2ecc71",
                color: "#fff !important",
                border: "1px solid #52c41a",
              },
              duration: 5,
              placement: "topRight",
            });
          } else {
            setSpinner(false);
            notification.error({
              message: "Error: Something went wrong server error",
              icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
              style: {
                backgroundColor: "#e74c3c",
                color: "#fff",
                border: "1px solid #c0392b",
              },
              duration: 5,
              placement: "topRight",
            });
          }
        }
      } catch (error) {
        console.log(error, "error");
        setSpinner(false);
        notification.error({
          message: "Error: Something went wrong server error",
          icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
          style: {
            backgroundColor: "#e74c3c",
            color: "#fff",
            border: "1px solid #c0392b",
          },
          duration: 5,
          placement: "topRight",
        });
      }
    }
  }, [
    ID,
    emailList,
    getProjectDetails,
    setOpen,
    userData?.data?.name,
    userData?.token,
  ]);

  const GetManagerList = useCallback(async () => {
    // setLoading(true);
    try {
      const res = await fetch(`${API_PATH.MANAGER_LIST}?limit=50`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userData?.token}`,
        },
      });

      const result = await res.json();
      if (result) {
        setManagerList(result?.data);
      } else {
        notification.error({
          message: "Error: Something went wrong server error",
          icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
          style: {
            backgroundColor: "#e74c3c",
            color: "#fff",
            border: "1px solid #c0392b",
          },
          duration: 5,
          placement: "topRight",
        });
      }
      // setLoading(false);
    } catch (error) {
      // setLoading(false);

      notification.error({
        message: "Error: Something went wrong server error",
        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
        style: {
          backgroundColor: "#e74c3c",
          color: "#fff",
          border: "1px solid #c0392b",
        },
        duration: 5,
        placement: "topRight",
      });
    }
  }, [userData?.token]);

  React.useEffect(() => {
    GetManagerList();
  }, [GetManagerList]);
  return (
    <Modal
      centered
      title={<div>
        Add a team member to the project
        <Divider className="m-2" />
      </div>}
      open={open}
      onCancel={() => {
        setEmailList([]);
        setOpen(false);
        form.resetFields();
      }}
      width={800}
      footer={false}
    >
      <Form
        layout={"vertical"}
        form={form}
        name="form_in_modal1"
        onFinish={async () => {
          await handleSubmit();
        }}
      >
        <Form.Item
          className="mb-1"
          name="email"
          label="Add Team"
          rules={[{ required: true, message: "Please Select the team member" }]}
          style={{ width: "100%" }}
        >
          <Select
            size="large"
            name="email"
            // value={x?.email === "" ? undefined : x?.email}
            value={emailList}
            showSearch
            mode="multiple"
            placeholder="Select team member"
            onChange={(selectedValue) => {
              setEmailList(selectedValue);
            }}
            style={{ width: "100%" }}
            filterOption={(input, option) =>
              option?.props?.children
                ?.toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            virtual={false}
            allowClear
          >
            {managerList?.map((item, index) => (
              <Select.Option
                key={index}
                value={item?.email}
                label={item?.email}
              >
                {`${item?.email}`}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <div className="d-flex justify-content-end mt-3">
          <Form.Item style={{ marginBottom: 0 }}>
            <Button
              className="secondaryBttn "
              onClick={() => {
                setOpen(false);
                setEmailList([]);
                form.resetFields();
              }}
            >
              Cancel
            </Button>
          </Form.Item>
          <Form.Item style={{ marginBottom: 0 }}>
            <Button className="viewButton ms-2" type="submit" htmlType="submit">
              <span>
                {spinner && (
                  <Spin
                    size="small"
                    indicator={
                      <LoadingOutlined
                        style={{
                          color: "white",
                          paddingRight: 5,
                        }}
                      />
                    }
                  />
                )}{" "}
                Invite
              </span>
            </Button>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
};

export default AddMemberModal;
