import { Modal } from 'antd'
import axios from 'axios';
import React, { useCallback, useEffect, useRef, useState } from 'react'
import API_URL from '../../Constants/api-path';
import { Col, Row } from 'react-bootstrap';
import { currencyList } from '../../Constants/Currency';
import {
    LoadingOutlined,
} from "@ant-design/icons";
const PaymentDetailsModal = ({ selectedExpert, setSelectedExpert }) => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    const [expertDetails, setExpertDetails] = useState([]);
    const [loading, setLoading] = useState(false);

    const headers = React.useMemo(
        () => ({
            "x-access-token": userData.access_token,
        }),
        [userData.access_token]
    );

    const getExpertProfileDetails = useCallback(() => {
        setLoading(true);
        axios
            .get(`${API_URL.EXPERT_PROFILE}/${selectedExpert?.data?.expert_id}`, {
                headers,
            })
            .then((res) => {
                setExpertDetails(res.data.data);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                console.log(err);
            });
    }, [headers, selectedExpert?.data?.expert_id]);
    const callOnce = useRef(false)
    useEffect(() => {
        if (!callOnce.current && selectedExpert?.data?.expert_id) {
            callOnce.current = true
            getExpertProfileDetails();
        }
    }, [getExpertProfileDetails, selectedExpert?.data?.expert_id]);
    return (
        <Modal title={"Expert Payment Details"}
            open={selectedExpert?.open}
            onCancel={() => {
                callOnce.current = false
                setSelectedExpert({
                    open: false,
                    data: ""
                })
            }}
            footer={false}
            width={600}
        >
            <div
                className="comon-divs-re"
                style={{ width: "100%", paddingRight: "20px", minHeight: 300, display: "flex", justifyContent: "center", alignItems: "center" }}
            >
                {loading ? <div className="d-flex justify-content-center align-items-center"> <LoadingOutlined style={{ fontSize: 30 }} /> </div> : <div className="pvs-header__title-container">
                    {expertDetails?.[0]?.expertpaymentdetails?.map(
                        (curElemData, index) => (
                            <div key={curElemData?._id}>
                                {curElemData?.payment_method.toLowerCase() ===
                                    "bank" &&
                                    curElemData?.status === true && (
                                        <>
                                            {curElemData?.country && (
                                                <Row>
                                                    <Col className="d-flex text-center align-items-center">
                                                        <strong className="me-2 bank_title">
                                                            Country:
                                                        </strong>
                                                        <p className="pymtP">
                                                            {curElemData?.country}
                                                        </p>
                                                    </Col>
                                                </Row>
                                            )}
                                            {curElemData?.account_holder_name && (
                                                <Row>
                                                    <Col className="d-flex text-center align-items-center">
                                                        <strong className="me-2 bank_title">
                                                            Account holder name:
                                                        </strong>
                                                        <p className="pymtP">
                                                            {
                                                                curElemData?.account_holder_name
                                                            }
                                                        </p>
                                                    </Col>
                                                </Row>
                                            )}

                                            {curElemData?.account_number && (
                                                <Row>
                                                    <Col className="d-flex text-center align-items-center">
                                                        <strong className="me-2 bank_title">
                                                            Account number:
                                                        </strong>
                                                        <p className="pymtP">
                                                            {
                                                                curElemData?.account_number
                                                            }
                                                        </p>
                                                    </Col>
                                                </Row>
                                            )}

                                            {curElemData?.bank_branch_address && (
                                                <Row>
                                                    <Col className="d-flex text-center align-items-center">
                                                        <strong className="me-2 bank_title">
                                                            Bank branch address:
                                                        </strong>
                                                        <p className="pymtP">
                                                            {
                                                                curElemData?.bank_branch_address
                                                            }
                                                        </p>
                                                    </Col>
                                                </Row>
                                            )}

                                            {curElemData?.bank_name && (
                                                <Row>
                                                    <Col className="d-flex text-center align-items-center">
                                                        <strong className="me-2 bank_title">
                                                            Bank name:
                                                        </strong>
                                                        <p className="pymtP">
                                                            {curElemData?.bank_name}
                                                        </p>
                                                    </Col>
                                                </Row>
                                            )}

                                            {curElemData?.branch_code && (
                                                <Row>
                                                    <Col className="d-flex text-center align-items-center">
                                                        <strong className="me-2 bank_title">
                                                            Branch Code/BSB Code:
                                                        </strong>
                                                        <p className="pymtP">
                                                            {curElemData?.branch_code}
                                                        </p>
                                                    </Col>
                                                </Row>
                                            )}
                                            {curElemData?.bic_or_swift && (
                                                <Row>
                                                    <Col className="d-flex text-center align-items-center">
                                                        <strong className="me-2 bank_title">
                                                            BIC/SWIFT:
                                                        </strong>
                                                        <p className="pymtP">
                                                            {
                                                                curElemData?.bic_or_swift
                                                            }
                                                        </p>
                                                    </Col>
                                                </Row>
                                            )}

                                            {curElemData?.iban_ifsc && (
                                                <Row>
                                                    <Col className="d-flex text-center align-items-center">
                                                        <strong className="me-2 bank_title">
                                                            IFSC/IBAN:
                                                        </strong>
                                                        <p className="pymtP">
                                                            {curElemData?.iban_ifsc}
                                                        </p>
                                                    </Col>
                                                </Row>
                                            )}
                                            {curElemData?.micr_code && (
                                                <Row>
                                                    <Col className="d-flex text-center align-items-center">
                                                        <strong className="me-2 bank_title">
                                                            MICR/Sort Code:
                                                        </strong>
                                                        <p className="pymtP">
                                                            {curElemData?.micr_code}
                                                        </p>
                                                    </Col>
                                                </Row>
                                            )}
                                            {curElemData?.ach_routing_code && (
                                                <Row>
                                                    <Col className="d-flex text-center align-items-center">
                                                        <strong className="me-2 bank_title">
                                                            ACH Routing Code:
                                                        </strong>
                                                        <p className="pymtP">
                                                            {
                                                                curElemData?.ach_routing_code
                                                            }
                                                        </p>
                                                    </Col>
                                                </Row>
                                            )}
                                            {curElemData?.clabe && (
                                                <Row>
                                                    <Col className="d-flex text-center align-items-center">
                                                        <strong className="me-2 bank_title">
                                                            CLABE:
                                                        </strong>
                                                        <p className="pymtP">
                                                            {curElemData?.clabe}
                                                        </p>
                                                    </Col>
                                                </Row>
                                            )}
                                            {curElemData?.currency && (
                                                <Row>
                                                    <Col className="d-flex text-center align-items-center">
                                                        <strong className="me-2 bank_title">
                                                            Payment Currency:
                                                        </strong>
                                                        <p className="pymtP">
                                                            {curElemData?.currency} (
                                                            {
                                                                currencyList?.filter(
                                                                    (curr) =>
                                                                        curr.code ===
                                                                        curElemData?.currency
                                                                )?.[0]?.name
                                                            }
                                                            )
                                                        </p>
                                                    </Col>
                                                </Row>
                                            )}
                                            {curElemData?.expert_address && (
                                                <Row>
                                                    <Col className="d-flex text-center align-items-center">
                                                        <strong className="me-2 bank_title">
                                                            Expert Address:
                                                        </strong>
                                                        <p className="pymtP">
                                                            {
                                                                curElemData?.expert_address
                                                            }
                                                        </p>
                                                    </Col>
                                                </Row>
                                            )}
                                            {curElemData?.pan_number ? (
                                                <>
                                                    <Row>
                                                        <Col>
                                                            <h2 className="pvs-header__title text-heading-large mt-0">
                                                                <span
                                                                    aria-hidden="true"
                                                                    style={{
                                                                        fontSize: "20px",
                                                                    }}
                                                                >
                                                                    Other Details
                                                                </span>
                                                            </h2>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col className="d-flex text-center align-items-center">
                                                            <strong className="me-2 bank_title">
                                                                PAN (of the account
                                                                holder):
                                                            </strong>
                                                            <p className="pymtP">
                                                                {curElemData?.pan_number.toUpperCase()}
                                                            </p>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col className="d-flex text-center align-items-center">
                                                            <strong className="me-2 bank_title">
                                                                PAN Link with Aadhar:
                                                            </strong>
                                                            <p className="pymtP">
                                                                {curElemData?.pan_adhar_link_status
                                                                    .charAt(0)
                                                                    .toUpperCase() +
                                                                    curElemData?.pan_adhar_link_status.slice(
                                                                        1
                                                                    )}
                                                            </p>
                                                        </Col>
                                                    </Row>
                                                </>
                                            ) : null}
                                        </>
                                    )}
                            </div>
                        )
                    )}

                    {expertDetails?.[0]?.expertpaymentdetails?.map(
                        (curElemData, index) => (
                            <div key={curElemData?._id}>
                                {curElemData?.payment_method.toLowerCase() ===
                                    "amazon" &&
                                    curElemData?.status === true && (
                                        <>
                                            <Row>
                                                <Col className="d-flex text-center align-items-center">
                                                    <strong className="me-2 bank_title">
                                                        Amazon Email Id:
                                                    </strong>
                                                    <p className="pymtP">
                                                        {
                                                            curElemData?.amazn_email_id
                                                        }
                                                    </p>
                                                </Col>
                                            </Row>
                                        </>
                                    )}
                            </div>
                        )
                    )}

                    {expertDetails?.[0]?.expertpaymentdetails?.map(
                        (curElemData, index) => (
                            <div key={curElemData._id}>
                                {curElemData.payment_method.toLowerCase() ===
                                    "paypal" &&
                                    curElemData?.status === true && (
                                        <>
                                            <Row>
                                                <Col className="d-flex text-center align-items-center">
                                                    <strong className="me-2 bank_title">
                                                        Paypal registered email id:
                                                    </strong>
                                                    <p className="pymtP">
                                                        {curElemData?.paypal_email_id
                                                            ? curElemData?.paypal_email_id
                                                            : curElemData?.amazn_email_id}
                                                    </p>
                                                </Col>
                                            </Row>
                                        </>
                                    )}
                            </div>
                        )
                    )}

                    {expertDetails?.[0]?.expertpaymentdetails?.map(
                        (curElemData, index) => (
                            <div key={curElemData._id}>
                                {curElemData?.payment_method.toLowerCase() ===
                                    "charity" &&
                                    curElemData?.status === true && (
                                        <>
                                            {curElemData?.name_of_charity && (
                                                <Row>
                                                    <Col className="d-flex text-center align-items-center">
                                                        <strong className="me-2 bank_title">
                                                            Name of Charity:
                                                        </strong>
                                                        <p className="pymtP">
                                                            {
                                                                curElemData?.name_of_charity
                                                            }
                                                        </p>
                                                    </Col>
                                                </Row>
                                            )}
                                            {curElemData?.contact_person_name && (
                                                <Row>
                                                    <Col className="d-flex text-center align-items-center">
                                                        <strong className="me-2 bank_title">
                                                            Contact Person Name:
                                                        </strong>
                                                        <p className="pymtP">
                                                            {
                                                                curElemData?.contact_person_name
                                                            }
                                                        </p>
                                                    </Col>
                                                </Row>
                                            )}
                                            {curElemData?.country && (
                                                <Row>
                                                    <Col className="d-flex text-center align-items-center">
                                                        <strong className="me-2 bank_title">
                                                            Country:
                                                        </strong>
                                                        <p className="pymtP">
                                                            {curElemData?.country}
                                                        </p>
                                                    </Col>
                                                </Row>
                                            )}
                                            {curElemData?.contact_number && (
                                                <Row>
                                                    <Col className="d-flex text-center align-items-center">
                                                        <strong className="me-2 bank_title">
                                                            Contact Number With
                                                            Country Code:
                                                        </strong>
                                                        <p className="pymtP">
                                                            {
                                                                curElemData?.contact_number
                                                            }
                                                        </p>
                                                    </Col>
                                                </Row>
                                            )}
                                            {curElemData?.email && (
                                                <Row>
                                                    <Col className="d-flex text-center align-items-center">
                                                        <strong className="me-2 bank_title">
                                                            Email:
                                                        </strong>
                                                        <p className="pymtP">
                                                            {curElemData?.email}
                                                        </p>
                                                    </Col>
                                                </Row>
                                            )}
                                            {curElemData?.website_link && (
                                                <Row>
                                                    <Col className="d-flex text-center align-items-center">
                                                        <strong className="me-2 bank_title">
                                                            Website Link:
                                                        </strong>
                                                        <p className="pymtP">
                                                            {
                                                                curElemData?.website_link
                                                            }
                                                        </p>
                                                    </Col>
                                                </Row>
                                            )}
                                            {curElemData?.donation_link && (
                                                <Row>
                                                    <Col className="d-flex text-center align-items-center">
                                                        <strong className="me-2 bank_title">
                                                            Donation Link:
                                                        </strong>
                                                        <p className="pymtP">
                                                            {
                                                                curElemData?.donation_link
                                                            }
                                                        </p>
                                                    </Col>
                                                </Row>
                                            )}
                                        </>
                                    )}
                            </div>
                        )
                    )}
                </div>}

            </div>

        </Modal>
    )
}

export default PaymentDetailsModal
