import { Checkbox, Form, Input, notification } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import Helmet from "react-helmet";
import { useLocation } from "react-router-dom";
import API_PATH from "../../Constants/api-path";
import Sidebar from "../../components/Sidebar";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { BsChevronLeft } from "../../Utils/Icons";
import { CheckCircleOutlined } from "@ant-design/icons";

const { TextArea } = Input;

const EnquiryPage = () => {
  const [open, setOpen] = useState(false);

  const navigate = useNavigate();
  const history = useNavigate();
  const userData = JSON.parse(localStorage.getItem("userData"));
  const [isRoleName, setIsRoleName] = useState("");
  const [isRoleDescrip, setIsRoleDescript] = useState("");
  const [spinner, setSpinner] = useState(false);
  const [isChecked, setIsChecked] = useState({
    dashboardView: false,
    invoiceView: false,
    clientView: false,
    clientAdd: false,
    clientEdit: false,
    clientDelete: false,
    clientMove: false,
    expertView: false,
    expertAdd: false,
    expertEdit: false,
    expertDelete: false,
    expertPaymentDetails: false,
    liveProjectsView: false,
    liveProjectsAdd: false,
    calendarView: false,
    callRecordsView: false,
    callRecordsAdd: false,
    callRecordsEdit: false,
    expertEnquiryView: false,
    expertEnquiryAdd: false,
    expertEnquiryEdit: false,
    expertEnquiryDelete: false,
    completedProjectsView: false,
    archivedProjectsView: false,
    managersView: false,
    managersAdd: false,
    managersEdit: false,
    managersDelete: false,
    rolesView: false,
    rolesAdd: false,
    rolesEdit: false,
    rolesDelete: false,
    exotelView: false,
    exotelAdd: false,
    exotelEdit: false,
    exotelDelete: false,
    logsView: false,
    internalMeetingView: false,
    internalMeetingAdd: false,
    internalMeetingDelete: false,
    internalMeetingEdit: false,
    internalMeetingPublish: false,
    qcExpertView: false,
    qcExpertAdd: false,
    qcExpertDelete: false,
    qcExpertEdit: false,
    zoomMeetingView: false,
    draftedZoomMeetingView: false,
    draftedZoomMeetingAdd: false,
    draftedZoomMeetingDelete: false,
    draftedZoomMeetingEdit: false,
    draftedZoomMeetingPublish: false,
    draftedZoomMeetingSelect: false,
    creditManagementView: false,
    creditManagementAdd: false,
    creditManagementDelete: false,
    creditManagementEdit: false,
    leadView: false,
    leadAdd: false,
    leadDelete: false,
    leadEdit: false,
  });

  let name
  const handleInputs = (e) => {
    name = e.target.name;

    isChecked[name] === false
      ? setIsChecked({ ...isChecked, [name]: true })
      : setIsChecked({ ...isChecked, [name]: false });
  };
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  let query = useQuery();
  let role_id = query.get("role_id");
  const submitRolesForm = useCallback(async (e) => {
    try {
      if (role_id) {
        const res = await fetch(`${API_PATH?.ROLE_UPDATE}/${role_id}`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userData?.token}`,
          },
          body: JSON.stringify({
            name: isRoleName,
            description: isRoleDescrip,
            role_permission: [
              {
                dashboard: {
                  view: isChecked?.dashboardView,
                },
              },
              {
                client: {
                  view: isChecked?.clientView,
                  add: isChecked?.clientAdd,
                  edit: isChecked?.clientEdit,
                  delete: isChecked?.clientDelete,
                  move: isChecked?.clientMove,
                },
              },
              {
                expert: {
                  view: isChecked?.expertView,
                  add: isChecked?.expertAdd,
                  edit: isChecked?.expertEdit,
                  delete: isChecked?.expertDelete,
                  paymentDetails: isChecked?.expertPaymentDetails,
                },
              },
              {
                live_projects: {
                  add: isChecked?.liveProjectsAdd,
                  view: isChecked?.liveProjectsView,
                },
              },
              { //will put it as it is even if not wanted
                calender: {
                  view: isChecked?.calendarView,
                },
              },
              {
                call_records: {
                  view: isChecked?.callRecordsView,
                  add: isChecked?.callRecordsAdd,
                  edit: isChecked?.callRecordsEdit,
                },
              },
              {
                expert_enquiry: {
                  view: isChecked?.expertEnquiryView,
                  add: isChecked?.expertEnquiryAdd,
                  edit: isChecked?.expertEnquiryEdit,
                  delete: isChecked?.expertEnquiryDelete,
                },
              },
              {
                completed_projects: {
                  view: isChecked?.completedProjectsView,
                },
              },
              {
                archived_projects: {
                  view: isChecked?.archivedProjectsView,
                },
              },
              {
                managers: {
                  view: isChecked?.managersView,
                  add: isChecked?.managersAdd,
                  edit: isChecked?.managersEdit,
                  delete: isChecked?.managersDelete,
                },
              },
              {
                roles: {
                  view: isChecked?.rolesView,
                  add: isChecked?.rolesAdd,
                  edit: isChecked?.rolesEdit,
                  delete: isChecked?.rolesDelete,
                },
              },
              {
                exotel: {
                  view: isChecked?.exotelView,
                  add: isChecked?.exotelAdd,
                  edit: isChecked?.exotelEdit,
                  delete: isChecked?.exotelDelete,
                },
              },
              { //will put it as it is even if not wanted
                logs: {
                  view: isChecked?.logsView,
                },
              },
              {
                internalMeeting: {
                  view: isChecked?.internalMeetingView,
                  add: isChecked?.internalMeetingAdd,
                  delete: isChecked?.internalMeetingDelete,
                  edit: isChecked?.internalMeetingEdit,
                  publish: isChecked?.internalMeetingPublish,
                },
              },
              {
                qcExpert: {
                  view: isChecked?.qcExpertView,
                  add: isChecked?.qcExpertAdd,
                  delete: isChecked?.qcExpertDelete,
                  edit: isChecked?.qcExpertEdit,
                },
              },
              {
                zoomMeeting: {
                  view: isChecked?.zoomMeetingView,
                },
              },
              {
                draftedZoomMeeting: {
                  view: isChecked?.draftedZoomMeetingView,
                  add: isChecked?.draftedZoomMeetingAdd,
                  delete: isChecked?.draftedZoomMeetingDelete,
                  edit: isChecked?.draftedZoomMeetingEdit,
                  publish: isChecked?.draftedZoomMeetingPublish,
                  selectZoomAcc: isChecked?.draftedZoomMeetingSelect,
                },
              },
              {
                creditManagement: {
                  view: isChecked?.creditManagementView,
                  add: isChecked?.creditManagementAdd,
                  delete: isChecked?.creditManagementDelete,
                  edit: isChecked?.creditManagementEdit,
                },
              },
              {
                lead: {
                  view: isChecked?.leadView,
                  add: isChecked?.leadAdd,
                  delete: isChecked?.leadDelete,
                  edit: isChecked?.leadEdit,
                }
              },
              {
                invoice: {
                  view: isChecked?.invoiceView,
                },
              },
            ],
          }),
        });

        const result = await res.json();
        if (result) {
          setSpinner(false);
          if (res.status === 200 || res.status === true) {
            history("/dashboard/roles");
            notification.success({
              message: "Role updated successful",
              icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
              style: {
                backgroundColor: "#2ecc71",
                color: "#fff !important",
                border: "1px solid #52c41a",
              },
              duration: 5,
              placement: "topRight",
            });
          } else {
            // return toast.error(result.msg, {
            //   position: toast.POSITION.TOP_RIGHT,
            //   autoClose: 3000,
            // });
          }
        } else {
          // return toast.error(result.msg, {
          //   position: toast.POSITION.TOP_RIGHT,
          //   autoClose: 3000,
          // });
        }
      } else {
        const res = await fetch(`${API_PATH?.ROLE_Add}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userData?.token}`,
          },
          body: JSON.stringify({
            name: isRoleName,
            description: isRoleDescrip,
            role_permission: [
              {
                dashboard: {
                  view: isChecked?.dashboardView,
                },
              },
              {
                client: {
                  view: isChecked?.clientView,
                  add: isChecked?.clientAdd,
                  edit: isChecked?.clientEdit,
                  delete: isChecked?.clientDelete,
                  move: isChecked?.clientMove,
                },
              },
              {
                expert: {
                  view: isChecked?.expertView,
                  add: isChecked?.expertAdd,
                  edit: isChecked?.expertEdit,
                  delete: isChecked?.expertDelete,
                  paymentDetails: isChecked?.expertPaymentDetails,
                },
              },
              {
                live_projects: {
                  view: isChecked?.liveProjectsView,
                  add: isChecked?.liveProjectsAdd,
                },
              },
              {
                calender: {
                  view: isChecked?.calendarView,
                },
              },
              {
                call_records: {
                  view: isChecked?.callRecordsView,
                  add: isChecked?.callRecordsAdd,
                  edit: isChecked?.callRecordsEdit,
                },
              },
              {
                expert_enquiry: {
                  view: isChecked?.expertEnquiryView,
                  add: isChecked?.expertEnquiryAdd,
                  edit: isChecked?.expertEnquiryEdit,
                  delete: isChecked?.expertEnquiryDelete,
                },
              },
              {
                completed_projects: {
                  view: isChecked?.completedProjectsView,
                },
              },
              {
                archived_projects: {
                  view: isChecked?.archivedProjectsView,
                },
              },
              {
                managers: {
                  view: isChecked?.managersView,
                  add: isChecked?.managersAdd,
                  edit: isChecked?.managersEdit,
                  delete: isChecked?.managersDelete,
                },
              },
              {
                roles: {
                  view: isChecked?.rolesView,
                  add: isChecked?.rolesAdd,
                  edit: isChecked?.rolesEdit,
                  delete: isChecked?.rolesDelete,
                },
              },
              {
                exotel: {
                  view: isChecked?.exotelView,
                  add: isChecked?.exotelAdd,
                  edit: isChecked?.exotelEdit,
                  delete: isChecked?.exotelDelete,
                },
              },
              {
                logs: {
                  view: isChecked?.logsView,
                },
              },
              {
                internalMeeting: {
                  view: isChecked?.internalMeetingView,
                  add: isChecked?.internalMeetingAdd,
                  delete: isChecked?.internalMeetingDelete,
                  edit: isChecked?.internalMeetingEdit,
                  publish: isChecked?.internalMeetingPublish,
                },
              },
              {
                qcExpert: {
                  view: isChecked?.qcExpertView,
                  add: isChecked?.qcExpertAdd,
                  delete: isChecked?.qcExpertDelete,
                  edit: isChecked?.qcExpertEdit,
                },
              },
              {
                zoomMeeting: {
                  view: isChecked?.zoomMeetingView,
                },
              },
              {
                draftedZoomMeeting: {
                  view: isChecked?.draftedZoomMeetingView,
                  add: isChecked?.draftedZoomMeetingAdd,
                  delete: isChecked?.draftedZoomMeetingDelete,
                  edit: isChecked?.draftedZoomMeetingEdit,
                  publish: isChecked?.draftedZoomMeetingPublish,
                  selectZoomAcc: isChecked?.draftedZoomMeetingSelect,
                },
              },
              {
                creditManagement: {
                  view: isChecked?.creditManagementView,
                  add: isChecked?.creditManagementAdd,
                  delete: isChecked?.creditManagementDelete,
                  edit: isChecked?.creditManagementEdit,
                },
              },
              {
                lead: {
                  view: isChecked?.leadView,
                  add: isChecked?.leadAdd,
                  delete: isChecked?.leadDelete,
                  edit: isChecked?.leadEdit,
                }
              },
              {
                invoice: {
                  view: isChecked?.invoiceView,
                },
              },
            ],
          }),
        });

        const result = await res.json();
        if (result) {
          console.log(result, "result");
          setSpinner(false);
          if (res.status === 200 || res.status === true) {
            history("/dashboard/roles");
            notification.success({
              message: "Role added successful",
              icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
              style: {
                backgroundColor: "#2ecc71",
                color: "#fff !important",
                border: "1px solid #52c41a",
              },
              duration: 5,
              placement: "topRight",
            });
          } else {
            // return toast.error(result.msg, {
            //   position: toast.POSITION.TOP_RIGHT,
            //   autoClose: 3000,
            // });
          }
        } else {
          // return toast.error(result.msg, {
          //   position: toast.POSITION.TOP_RIGHT,
          //   autoClose: 3000,
          // });
        }
      }
    } catch (error) {
      console.log(error);
    }
  }, [role_id, userData?.token, isRoleName, isRoleDescrip, isChecked?.dashboardView, isChecked?.clientView, isChecked?.clientAdd, isChecked?.clientEdit, isChecked?.clientDelete, isChecked?.clientMove, isChecked?.expertView, isChecked?.expertAdd, isChecked?.expertEdit, isChecked?.expertDelete, isChecked?.expertPaymentDetails, isChecked?.liveProjectsAdd, isChecked?.liveProjectsView, isChecked?.calendarView, isChecked?.callRecordsView, isChecked?.callRecordsAdd, isChecked?.callRecordsEdit, isChecked?.expertEnquiryView, isChecked?.expertEnquiryAdd, isChecked?.expertEnquiryEdit, isChecked?.expertEnquiryDelete, isChecked?.completedProjectsView, isChecked?.archivedProjectsView, isChecked?.managersView, isChecked?.managersAdd, isChecked?.managersEdit, isChecked?.managersDelete, isChecked?.rolesView, isChecked?.rolesAdd, isChecked?.rolesEdit, isChecked?.rolesDelete, isChecked?.exotelView, isChecked?.exotelAdd, isChecked?.exotelEdit, isChecked?.exotelDelete, isChecked?.logsView, isChecked?.internalMeetingView, isChecked?.internalMeetingAdd, isChecked?.internalMeetingDelete, isChecked?.internalMeetingEdit, isChecked?.internalMeetingPublish, isChecked?.qcExpertView, isChecked?.qcExpertAdd, isChecked?.qcExpertDelete, isChecked?.qcExpertEdit, isChecked?.zoomMeetingView, isChecked?.draftedZoomMeetingView, isChecked?.draftedZoomMeetingAdd, isChecked?.draftedZoomMeetingDelete, isChecked?.draftedZoomMeetingEdit, isChecked?.draftedZoomMeetingPublish, isChecked?.draftedZoomMeetingSelect, isChecked?.creditManagementView, isChecked?.creditManagementAdd, isChecked?.creditManagementDelete, isChecked?.creditManagementEdit, isChecked?.leadView, isChecked?.leadAdd, isChecked?.leadDelete, isChecked?.leadEdit, isChecked?.invoiceView, history]);



  const fetchData = useCallback(async () => {
    try {
      const res = await axios.get(`${API_PATH.ROLE_LIST_BYID}/${role_id}`);
      setIsRoleName(res?.data?.data?.name);
      setIsRoleDescript(res?.data?.data?.description);
      setIsChecked({
        dashboardView: res?.data?.data?.role_permission[0]?.dashboard?.view,
        clientView: res?.data?.data?.role_permission[1]?.client?.view,
        clientAdd: res?.data?.data?.role_permission[1]?.client?.add,
        clientEdit: res?.data?.data?.role_permission[1]?.client?.edit,
        clientDelete: res?.data?.data?.role_permission[1]?.client?.delete,
        clientMove: res?.data?.data?.role_permission[1]?.client?.move,
        expertView: res?.data?.data?.role_permission[2]?.expert?.view,
        expertAdd: res?.data?.data?.role_permission[2]?.expert?.add,
        expertEdit: res?.data?.data?.role_permission[2]?.expert?.edit,
        expertDelete: res?.data?.data?.role_permission[2]?.expert?.delete,
        expertPaymentDetails:
          res?.data?.data?.role_permission[2]?.expert?.paymentDetails,
        liveProjectsView:
          res?.data?.data?.role_permission[3]?.live_projects?.view,
        liveProjectsAdd:
          res?.data?.data?.role_permission[3]?.live_projects?.add,
        calendarView: res?.data?.data?.role_permission[4]?.calender?.view,
        callRecordsView:
          res?.data?.data?.role_permission[5]?.call_records?.view,
        callRecordsAdd: res?.data?.data?.role_permission[5]?.call_records?.add,
        callRecordsEdit:
          res?.data?.data?.role_permission[5]?.call_records?.edit,
        expertEnquiryView:
          res?.data?.data?.role_permission[6]?.expert_enquiry?.view,
        expertEnquiryAdd:
          res?.data?.data?.role_permission[6]?.expert_enquiry?.add,
        expertEnquiryEdit:
          res?.data?.data?.role_permission[6]?.expert_enquiry?.edit,
        expertEnquiryDelete:
          res?.data?.data?.role_permission[6]?.expert_enquiry?.delete,
        completedProjectsView:
          res?.data?.data?.role_permission[7]?.completed_projects?.view,
        archivedProjectsView:
          res?.data?.data?.role_permission[8]?.archived_projects?.view,
        managersView: res?.data?.data?.role_permission[9]?.managers?.view,
        managersAdd: res?.data?.data?.role_permission[9]?.managers?.add,
        managersEdit: res?.data?.data?.role_permission[9]?.managers?.edit,
        managersDelete: res?.data?.data?.role_permission[9]?.managers?.delete,
        rolesView: res?.data?.data?.role_permission[10]?.roles?.view,
        rolesAdd: res?.data?.data?.role_permission[10]?.roles?.add,
        rolesEdit: res?.data?.data?.role_permission[10]?.roles?.edit,
        rolesDelete: res?.data?.data?.role_permission[10]?.roles?.delete,
        exotelView: res?.data?.data?.role_permission[11]?.exotel?.view,
        exotelAdd: res?.data?.data?.role_permission[11]?.exotel?.add,
        exotelEdit: res?.data?.data?.role_permission[11]?.exotel?.edit,
        exotelDelete: res?.data?.data?.role_permission[11]?.exotel?.delete,
        logsView: res?.data?.data?.role_permission[12]?.logs?.view,
        internalMeetingView:
          res?.data?.data?.role_permission[13]?.internalMeeting?.view,
        internalMeetingAdd:
          res?.data?.data?.role_permission[13]?.internalMeeting?.add,
        internalMeetingDelete:
          res?.data?.data?.role_permission[13]?.internalMeeting?.delete,
        internalMeetingEdit:
          res?.data?.data?.role_permission[13]?.internalMeeting?.edit,
        internalMeetingPublish:
          res?.data?.data?.role_permission[13]?.internalMeeting?.publish,
        qcExpertView: res?.data?.data?.role_permission[14]?.qcExpert?.view,
        qcExpertAdd: res?.data?.data?.role_permission[14]?.qcExpert?.add,
        qcExpertDelete: res?.data?.data?.role_permission[14]?.qcExpert?.delete,
        qcExpertEdit: res?.data?.data?.role_permission[14]?.qcExpert?.edit,
        zoomMeetingView: res?.data?.data?.role_permission[15]?.zoomMeeting?.view,
        draftedZoomMeetingView: res?.data?.data?.role_permission[16]?.draftedZoomMeeting?.view,
        draftedZoomMeetingAdd: res?.data?.data?.role_permission[16]?.draftedZoomMeeting?.add,
        draftedZoomMeetingDelete: res?.data?.data?.role_permission[16]?.draftedZoomMeeting?.delete,
        draftedZoomMeetingEdit: res?.data?.data?.role_permission[16]?.draftedZoomMeeting?.edit,
        draftedZoomMeetingPublish: res?.data?.data?.role_permission[16]?.draftedZoomMeeting?.publish,
        draftedZoomMeetingSelect: res?.data?.data?.role_permission[16]?.draftedZoomMeeting?.selectZoomAcc,
        creditManagementView: res?.data?.data?.role_permission[17]?.creditManagement?.view,
        creditManagementAdd: res?.data?.data?.role_permission[17]?.creditManagement?.add,
        creditManagementDelete: res?.data?.data?.role_permission[17]?.creditManagement?.delete,
        creditManagementEdit: res?.data?.data?.role_permission[17]?.creditManagement?.edit,
        leadView: res?.data?.data?.role_permission[18]?.lead?.view,
        leadAdd: res?.data?.data?.role_permission[18]?.lead?.add,
        leadDelete: res?.data?.data?.role_permission[18]?.lead?.delete,
        leadEdit: res?.data?.data?.role_permission[18]?.lead?.edit,
        invoiceView: res?.data?.data?.role_permission[19]?.invoice?.view,

      });
    } catch (error) {
      console.log(error);
    }
  }, [role_id]);

  const handleIconClick = () => {
    navigate(-1);
  };

  useEffect(() => {
    setTimeout(() => {
      setOpen(true);
    }, 100);
  }, []);
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      <Helmet>
        <title>{"Nextyn | Enquiry"}</title>
      </Helmet>
      <div className="page-wrapper chiller-theme toggled">
        <div className="main-sec d-lg-flex">
          <Sidebar />
        </div>
        <main className="body-total content-wrapper float-start w-100">
          <div>
            <div className="d-block d-lg-block">
              <div className="bk-div float-start w-100">
                <div className="body-expart-div request-page projects-scope">
                  <h2
                    className="d-flex align-items-center"
                    onClick={handleIconClick}
                    style={{ cursor: "pointer" }}
                  >
                    <BsChevronLeft className="me-2 search-btn-back" />
                    Add New Role
                  </h2>
                  <div className="request-body-sec projects-details-page">
                    {open ? (
                      <div className="row d-flex justify-content-between align-items-center">
                        <div className="col-md-12">
                          <div className="addexpert-section-divs">
                            <Form
                              onSubmit={submitRolesForm}
                              onFinish={submitRolesForm}
                              method="POST"
                            >
                              <Form.Item>
                                <Input
                                  size="large"
                                  placeholder="Role Name"
                                  name="name"
                                  value={isRoleName}
                                  onChange={(e) =>
                                    setIsRoleName(e.target.value)
                                  }
                                />
                              </Form.Item>

                              <Form.Item>
                                <TextArea
                                  size="large"
                                  name="description"
                                  value={isRoleDescrip}
                                  onChange={(e) =>
                                    setIsRoleDescript(e.target.value)
                                  }
                                  placeholder="Description"
                                  autoSize={{
                                    minRows: 4,
                                    maxRows: 6,
                                  }}
                                />
                              </Form.Item>

                              <Form.Item>
                                <div className="row my-2">
                                  <div className="col-12">
                                    <div className="form-group">
                                      <div className="table-responsive">
                                        <table className="table-flush-spacing table">
                                          <thead>
                                            <tr>
                                              <th
                                                className="fw-bold text-capitalize"
                                                style={{ borderTop: "none" }}
                                              >
                                                Priviledge
                                              </th>
                                              <th
                                                className="fw-bold text-capitalize"
                                                style={{ borderTop: "none" }}
                                              >
                                                View
                                              </th>
                                              <th
                                                className="fw-bold text-capitalize"
                                                style={{ borderTop: "none" }}
                                              >
                                                Add
                                              </th>
                                              <th
                                                className="fw-bold text-capitalize"
                                                style={{ borderTop: "none" }}
                                              >
                                                Edit
                                              </th>
                                              <th
                                                className="fw-bold text-capitalize"
                                                style={{ borderTop: "none" }}
                                              >
                                                Delete
                                              </th>
                                              <th
                                                className="fw-bold text-capitalize"
                                                style={{ borderTop: "none" }}
                                              >
                                                Payment Details
                                              </th>
                                              <th
                                                className="fw-bold text-capitalize"
                                                style={{ borderTop: "none" }}
                                              >
                                                Publish/Approve
                                              </th>
                                              <th
                                                className="fw-bold text-capitalize"
                                                style={{ borderTop: "none" }}
                                              >
                                                Zoom Account
                                              </th>
                                              <th
                                                className="fw-bold text-capitalize"
                                                style={{ borderTop: "none" }}
                                              >
                                                Client Transfer
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr>
                                              <td
                                                style={{ borderTop: "none" }}
                                                className="text-wrap"
                                              >
                                                <span
                                                  className="me-50"
                                                  style={{
                                                    color: "#526fd7",
                                                    fontWeight: "500",
                                                  }}
                                                >
                                                  {" "}
                                                  Dashboard
                                                </span>{" "}
                                              </td>

                                              <td style={{ borderTop: "none" }}>
                                                <div className="checkbox">
                                                  <Checkbox
                                                    size="large"
                                                    checked={
                                                      isChecked?.dashboardView
                                                    }
                                                    name="dashboardView"
                                                    value={
                                                      isChecked?.dashboardView
                                                    }
                                                    onChange={handleInputs}
                                                  />
                                                </div>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td
                                                style={{ borderTop: "none" }}
                                                className="text-wrap"
                                              >
                                                <span
                                                  className="me-50"
                                                  style={{
                                                    color: "#526fd7",
                                                    fontWeight: "500",
                                                  }}
                                                >
                                                  {" "}
                                                  Invoice
                                                </span>{" "}
                                              </td>

                                              <td style={{ borderTop: "none" }}>
                                                <div className="checkbox">
                                                  <Checkbox
                                                    size="large"
                                                    checked={
                                                      isChecked?.invoiceView
                                                    }
                                                    name="invoiceView"
                                                    value={
                                                      isChecked?.invoiceView
                                                    }
                                                    onChange={handleInputs}
                                                  />
                                                </div>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td
                                                style={{ borderTop: "none" }}
                                                className="text-wrap"
                                              >
                                                <span
                                                  className="me-50"
                                                  style={{
                                                    color: "#526fd7",
                                                    fontWeight: "500",
                                                  }}
                                                >
                                                  {" "}
                                                  Clients
                                                </span>{" "}
                                              </td>

                                              <td style={{ borderTop: "none" }}>
                                                <div className="checkbox">
                                                  <Checkbox
                                                    size="large"
                                                    checked={
                                                      isChecked?.clientView
                                                    }
                                                    name="clientView"
                                                    value={
                                                      isChecked?.clientView
                                                    }
                                                    onChange={handleInputs}
                                                  />
                                                </div>
                                              </td>
                                              <td style={{ borderTop: "none" }}>
                                                <div className="checkbox">
                                                  <Checkbox
                                                    size="large"
                                                    checked={
                                                      isChecked?.clientAdd
                                                    }
                                                    name="clientAdd"
                                                    value={isChecked?.clientAdd}
                                                    onChange={handleInputs}
                                                  />
                                                </div>
                                              </td>
                                              <td style={{ borderTop: "none" }}>
                                                <div className="checkbox">
                                                  <Checkbox
                                                    size="large"
                                                    checked={
                                                      isChecked?.clientEdit
                                                    }
                                                    name="clientEdit"
                                                    value={isChecked?.dashboard}
                                                    onChange={handleInputs}
                                                  />
                                                </div>
                                              </td>
                                              <td style={{ borderTop: "none" }}>
                                                <div className="checkbox">
                                                  <Checkbox
                                                    size="large"
                                                    checked={
                                                      isChecked?.clientDelete
                                                    }
                                                    name="clientDelete"
                                                    value={
                                                      isChecked?.clientDelete
                                                    }
                                                    onChange={handleInputs}
                                                  />
                                                </div>
                                              </td>
                                              <td style={{ borderTop: "none" }}>
                                                <div className="checkbox">

                                                </div>
                                              </td>
                                              <td style={{ borderTop: "none" }}>
                                                <div className="checkbox">

                                                </div>
                                              </td>
                                              <td style={{ borderTop: "none" }}>
                                                <div className="checkbox">

                                                </div>
                                              </td>
                                              <td style={{ borderTop: "none" }}>
                                                <div className="checkbox">
                                                  <Checkbox
                                                    size="large"
                                                    checked={
                                                      isChecked?.clientMove
                                                    }
                                                    name="clientMove"
                                                    value={
                                                      isChecked?.clientMove
                                                    }
                                                    onChange={handleInputs}
                                                  />
                                                </div>
                                              </td>
                                            </tr>

                                            <tr>
                                              <td
                                                style={{ borderTop: "none" }}
                                                className="text-wrap"
                                              >
                                                <span
                                                  className="me-50"
                                                  style={{
                                                    color: "#526fd7",
                                                    fontWeight: "500",
                                                  }}
                                                >
                                                  {" "}
                                                  Experts
                                                </span>{" "}
                                              </td>

                                              <td style={{ borderTop: "none" }}>
                                                <div className="checkbox">
                                                  <Checkbox
                                                    size="large"
                                                    checked={
                                                      isChecked?.expertView
                                                    }
                                                    name="expertView"
                                                    value={
                                                      isChecked?.expertView
                                                    }
                                                    onChange={handleInputs}
                                                  />
                                                </div>
                                              </td>
                                              <td style={{ borderTop: "none" }}>
                                                <div className="checkbox">
                                                  <Checkbox
                                                    size="large"
                                                    checked={
                                                      isChecked?.expertAdd
                                                    }
                                                    name="expertAdd"
                                                    value={isChecked?.expertAdd}
                                                    onChange={handleInputs}
                                                  />
                                                </div>
                                              </td>
                                              <td style={{ borderTop: "none" }}>
                                                <div className="checkbox">
                                                  <Checkbox
                                                    size="large"
                                                    checked={
                                                      isChecked?.expertEdit
                                                    }
                                                    name="expertEdit"
                                                    value={
                                                      isChecked?.expertEdit
                                                    }
                                                    onChange={handleInputs}
                                                  />
                                                </div>
                                              </td>
                                              <td style={{ borderTop: "none" }}>
                                                <div className="checkbox">
                                                  <Checkbox
                                                    size="large"
                                                    checked={
                                                      isChecked?.expertDelete
                                                    }
                                                    name="expertDelete"
                                                    value={
                                                      isChecked?.expertDelete
                                                    }
                                                    onChange={handleInputs}
                                                  />
                                                </div>
                                              </td>
                                              <td style={{ borderTop: "none" }}>
                                                <div className="checkbox">
                                                  <Checkbox
                                                    size="large"
                                                    checked={
                                                      isChecked?.expertPaymentDetails
                                                    }
                                                    name="expertPaymentDetails"
                                                    value={
                                                      isChecked?.expertPaymentDetails
                                                    }
                                                    onChange={handleInputs}
                                                  />
                                                </div>
                                              </td>
                                            </tr>

                                            <tr>
                                              <td
                                                style={{ borderTop: "none" }}
                                                className="text-wrap"
                                              >
                                                <span
                                                  className="me-50"
                                                  style={{
                                                    color: "#526fd7",
                                                    fontWeight: "500",
                                                  }}
                                                >
                                                  {" "}
                                                  Live Projects
                                                </span>{" "}
                                              </td>

                                              <td style={{ borderTop: "none" }}>
                                                <div className="checkbox">
                                                  <Checkbox
                                                    size="large"
                                                    checked={
                                                      isChecked?.liveProjectsView
                                                    }
                                                    name="liveProjectsView"
                                                    value={
                                                      isChecked?.liveProjectsView
                                                    }
                                                    onChange={handleInputs}
                                                  />
                                                </div>
                                              </td>
                                              <td style={{ borderTop: "none" }}>
                                                <div className="checkbox">
                                                  <Checkbox
                                                    size="large"
                                                    checked={
                                                      isChecked?.liveProjectsAdd
                                                    }
                                                    name="liveProjectsAdd"
                                                    value={
                                                      isChecked?.liveProjectsAdd
                                                    }
                                                    onChange={handleInputs}
                                                  />
                                                </div>
                                              </td>
                                            </tr>

                                            {/* <tr>
                                              <td
                                                style={{ borderTop: "none" }}
                                                className="text-wrap"
                                              >
                                                <span
                                                  className="me-50"
                                                  style={{
                                                    color: "#526fd7",
                                                    fontWeight: "500",
                                                  }}
                                                >
                                                  {" "}
                                                  Calendar
                                                </span>{" "}
                                              </td>

                                              <td style={{ borderTop: "none" }}>
                                                <div className="checkbox">
                                                  <Checkbox
                                                    size="large"
                                                    checked={
                                                      isChecked?.calendarView
                                                    }
                                                    name="calendarView"
                                                    value={
                                                      isChecked?.calendarView
                                                    }
                                                    onChange={handleInputs}
                                                  />
                                                </div>
                                              </td>
                                            </tr> */}

                                            <tr>
                                              <td
                                                style={{ borderTop: "none" }}
                                                className="text-wrap"
                                              >
                                                <span
                                                  className="me-50"
                                                  style={{
                                                    color: "#526fd7",
                                                    fontWeight: "500",
                                                  }}
                                                >
                                                  {" "}
                                                  Call Records
                                                </span>{" "}
                                              </td>

                                              <td style={{ borderTop: "none" }}>
                                                <div className="checkbox">
                                                  <Checkbox
                                                    size="large"
                                                    checked={
                                                      isChecked?.callRecordsView
                                                    }
                                                    name="callRecordsView"
                                                    value={
                                                      isChecked?.callRecordsView
                                                    }
                                                    onChange={handleInputs}
                                                  />
                                                </div>
                                              </td>
                                              <td style={{ borderTop: "none" }}>
                                                <div className="checkbox">
                                                  <Checkbox
                                                    size="large"
                                                    checked={
                                                      isChecked?.callRecordsAdd
                                                    }
                                                    name="callRecordsAdd"
                                                    value={
                                                      isChecked?.callRecordsAdd
                                                    }
                                                    onChange={handleInputs}
                                                  />
                                                </div>
                                              </td>
                                              <td style={{ borderTop: "none" }}>
                                                <div className="checkbox">
                                                  <Checkbox
                                                    size="large"
                                                    checked={
                                                      isChecked?.callRecordsEdit
                                                    }
                                                    name="callRecordsEdit"
                                                    value={
                                                      isChecked?.callRecordsEdit
                                                    }
                                                    onChange={handleInputs}
                                                  />
                                                </div>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td
                                                style={{ borderTop: "none" }}
                                                className="text-wrap"
                                              >
                                                <span
                                                  className="me-50"
                                                  style={{
                                                    color: "#526fd7",
                                                    fontWeight: "500",
                                                  }}
                                                >
                                                  {" "}
                                                  Expert Enquiry
                                                </span>{" "}
                                              </td>

                                              <td style={{ borderTop: "none" }}>
                                                <div className="checkbox">
                                                  <Checkbox
                                                    size="large"
                                                    checked={
                                                      isChecked?.expertEnquiryView
                                                    }
                                                    name="expertEnquiryView"
                                                    value={
                                                      isChecked?.expertEnquiryView
                                                    }
                                                    onChange={handleInputs}
                                                  />
                                                </div>
                                              </td>
                                              <td style={{ borderTop: "none" }}>
                                                <div className="checkbox">
                                                  <Checkbox
                                                    size="large"
                                                    checked={
                                                      isChecked?.expertEnquiryAdd
                                                    }
                                                    name="expertEnquiryAdd"
                                                    value={
                                                      isChecked?.expertEnquiryAdd
                                                    }
                                                    onChange={handleInputs}
                                                  />
                                                </div>
                                              </td>
                                              <td style={{ borderTop: "none" }}>
                                                <div className="checkbox">
                                                  <Checkbox
                                                    size="large"
                                                    checked={
                                                      isChecked?.expertEnquiryEdit
                                                    }
                                                    name="expertEnquiryEdit"
                                                    value={
                                                      isChecked?.expertEnquiryEdit
                                                    }
                                                    onChange={handleInputs}
                                                  />
                                                </div>
                                              </td>
                                              <td style={{ borderTop: "none" }}>
                                                <div className="checkbox">
                                                  <Checkbox
                                                    size="large"
                                                    checked={
                                                      isChecked?.expertEnquiryDelete
                                                    }
                                                    name="expertEnquiryDelete"
                                                    value={
                                                      isChecked?.expertEnquiryDelete
                                                    }
                                                    onChange={handleInputs}
                                                  />
                                                </div>
                                              </td>
                                            </tr>

                                            <tr>
                                              <td
                                                style={{ borderTop: "none" }}
                                                className="text-wrap"
                                              >
                                                <span
                                                  className="me-50"
                                                  style={{
                                                    color: "#526fd7",
                                                    fontWeight: "500",
                                                  }}
                                                >
                                                  {" "}
                                                  Completed Projects
                                                </span>{" "}
                                              </td>

                                              <td style={{ borderTop: "none" }}>
                                                <div className="checkbox">
                                                  <Checkbox
                                                    size="large"
                                                    checked={
                                                      isChecked?.completedProjectsView
                                                    }
                                                    name="completedProjectsView"
                                                    value={
                                                      isChecked?.completedProjectsView
                                                    }
                                                    onChange={handleInputs}
                                                  />
                                                </div>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td
                                                style={{ borderTop: "none" }}
                                                className="text-wrap"
                                              >
                                                <span
                                                  className="me-50"
                                                  style={{
                                                    color: "#526fd7",
                                                    fontWeight: "500",
                                                  }}
                                                >
                                                  {" "}
                                                  Archived Projects
                                                </span>{" "}
                                              </td>

                                              <td style={{ borderTop: "none" }}>
                                                <div className="checkbox">
                                                  <Checkbox
                                                    size="large"
                                                    checked={
                                                      isChecked?.archivedProjectsView
                                                    }
                                                    name="archivedProjectsView"
                                                    value={
                                                      isChecked?.archivedProjectsView
                                                    }
                                                    onChange={handleInputs}
                                                  />
                                                </div>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td
                                                style={{ borderTop: "none" }}
                                                className="text-wrap"
                                              >
                                                <span
                                                  className="me-50"
                                                  style={{
                                                    color: "#526fd7",
                                                    fontWeight: "500",
                                                  }}
                                                >
                                                  {" "}
                                                  Managers
                                                </span>{" "}
                                              </td>

                                              <td style={{ borderTop: "none" }}>
                                                <div className="checkbox">
                                                  <Checkbox
                                                    size="large"
                                                    checked={
                                                      isChecked?.managersView
                                                    }
                                                    name="managersView"
                                                    value={
                                                      isChecked?.managersView
                                                    }
                                                    onChange={handleInputs}
                                                  />
                                                </div>
                                              </td>
                                              <td style={{ borderTop: "none" }}>
                                                <div className="checkbox">
                                                  <Checkbox
                                                    size="large"
                                                    checked={
                                                      isChecked?.managersAdd
                                                    }
                                                    name="managersAdd"
                                                    value={
                                                      isChecked?.managersAdd
                                                    }
                                                    onChange={handleInputs}
                                                  />
                                                </div>
                                              </td>
                                              <td style={{ borderTop: "none" }}>
                                                <div className="checkbox">
                                                  <Checkbox
                                                    size="large"
                                                    checked={
                                                      isChecked?.managersEdit
                                                    }
                                                    name="managersEdit"
                                                    value={
                                                      isChecked?.managersEdit
                                                    }
                                                    onChange={handleInputs}
                                                  />
                                                </div>
                                              </td>
                                              <td style={{ borderTop: "none" }}>
                                                <div className="checkbox">
                                                  <Checkbox
                                                    size="large"
                                                    checked={
                                                      isChecked?.managersDelete
                                                    }
                                                    name="managersDelete"
                                                    value={
                                                      isChecked?.managersDelete
                                                    }
                                                    onChange={handleInputs}
                                                  />
                                                </div>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td
                                                style={{ borderTop: "none" }}
                                                className="text-wrap"
                                              >
                                                <span
                                                  className="me-50"
                                                  style={{
                                                    color: "#526fd7",
                                                    fontWeight: "500",
                                                  }}
                                                >
                                                  {" "}
                                                  Roles
                                                </span>{" "}
                                              </td>

                                              <td style={{ borderTop: "none" }}>
                                                <div className="checkbox">
                                                  <Checkbox
                                                    size="large"
                                                    checked={
                                                      isChecked?.rolesView
                                                    }
                                                    name="rolesView"
                                                    value={isChecked?.rolesView}
                                                    onChange={handleInputs}
                                                  />
                                                </div>
                                              </td>
                                              <td style={{ borderTop: "none" }}>
                                                <div className="checkbox">
                                                  <Checkbox
                                                    size="large"
                                                    checked={
                                                      isChecked?.rolesAdd
                                                    }
                                                    name="rolesAdd"
                                                    value={isChecked?.rolesAdd}
                                                    onChange={handleInputs}
                                                  />
                                                </div>
                                              </td>
                                              <td style={{ borderTop: "none" }}>
                                                <div className="checkbox">
                                                  <Checkbox
                                                    size="large"
                                                    checked={
                                                      isChecked?.rolesEdit
                                                    }
                                                    name="rolesEdit"
                                                    value={isChecked?.rolesEdit}
                                                    onChange={handleInputs}
                                                  />
                                                </div>
                                              </td>
                                              <td style={{ borderTop: "none" }}>
                                                <div className="checkbox">
                                                  <Checkbox
                                                    size="large"
                                                    checked={
                                                      isChecked?.rolesDelete
                                                    }
                                                    name="rolesDelete"
                                                    value={
                                                      isChecked?.rolesDelete
                                                    }
                                                    onChange={handleInputs}
                                                  />
                                                </div>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td
                                                style={{ borderTop: "none" }}
                                                className="text-wrap"
                                              >
                                                <span
                                                  className="me-50"
                                                  style={{
                                                    color: "#526fd7",
                                                    fontWeight: "500",
                                                  }}
                                                >
                                                  {" "}
                                                  Add/QC Experts
                                                </span>{" "}
                                              </td>

                                              <td style={{ borderTop: "none" }}>
                                                <div className="checkbox">
                                                  <Checkbox
                                                    size="large"
                                                    checked={
                                                      isChecked?.qcExpertView
                                                    }
                                                    name="qcExpertView"
                                                    value={
                                                      isChecked?.qcExpertView
                                                    }
                                                    onChange={handleInputs}
                                                  />
                                                </div>
                                              </td>
                                              <td style={{ borderTop: "none" }}>
                                                <div className="checkbox">
                                                  <Checkbox
                                                    size="large"
                                                    checked={
                                                      isChecked?.qcExpertAdd
                                                    }
                                                    name="qcExpertAdd"
                                                    value={
                                                      isChecked?.qcExpertAdd
                                                    }
                                                    onChange={handleInputs}
                                                  />
                                                </div>
                                              </td>
                                              <td style={{ borderTop: "none" }}>
                                                <div className="checkbox">
                                                  <Checkbox
                                                    size="large"
                                                    checked={
                                                      isChecked?.qcExpertEdit
                                                    }
                                                    name="qcExpertEdit"
                                                    value={
                                                      isChecked?.qcExpertEdit
                                                    }
                                                    onChange={handleInputs}
                                                  />
                                                </div>
                                              </td>
                                              <td style={{ borderTop: "none" }}>
                                                <div className="checkbox">
                                                  <Checkbox
                                                    size="large"
                                                    checked={
                                                      isChecked?.qcExpertDelete
                                                    }
                                                    name="qcExpertDelete"
                                                    value={
                                                      isChecked?.qcExpertDelete
                                                    }
                                                    onChange={handleInputs}
                                                  />
                                                </div>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td
                                                style={{ borderTop: "none" }}
                                                className="text-wrap"
                                              >
                                                <span
                                                  className="me-50"
                                                  style={{
                                                    color: "#526fd7",
                                                    fontWeight: "500",
                                                  }}
                                                >
                                                  {" "}
                                                  Exotel
                                                </span>{" "}
                                              </td>

                                              <td style={{ borderTop: "none" }}>
                                                <div className="checkbox">
                                                  <Checkbox
                                                    size="large"
                                                    checked={
                                                      isChecked?.exotelView
                                                    }
                                                    name="exotelView"
                                                    value={
                                                      isChecked?.exotelView
                                                    }
                                                    onChange={handleInputs}
                                                  />
                                                </div>
                                              </td>
                                              <td style={{ borderTop: "none" }}>
                                                <div className="checkbox">
                                                  <Checkbox
                                                    size="large"
                                                    checked={
                                                      isChecked?.exotelAdd
                                                    }
                                                    name="exotelAdd"
                                                    value={isChecked?.exotelAdd}
                                                    onChange={handleInputs}
                                                  />
                                                </div>
                                              </td>
                                              <td style={{ borderTop: "none" }}>
                                                <div className="checkbox">
                                                  <Checkbox
                                                    size="large"
                                                    checked={
                                                      isChecked?.exotelEdit
                                                    }
                                                    name="exotelEdit"
                                                    value={
                                                      isChecked?.exotelEdit
                                                    }
                                                    onChange={handleInputs}
                                                  />
                                                </div>
                                              </td>
                                              <td style={{ borderTop: "none" }}>
                                                <div className="checkbox">
                                                  <Checkbox
                                                    size="large"
                                                    checked={
                                                      isChecked?.exotelDelete
                                                    }
                                                    name="exotelDelete"
                                                    value={
                                                      isChecked?.exotelDelete
                                                    }
                                                    onChange={handleInputs}
                                                  />
                                                </div>
                                              </td>
                                            </tr>
                                            {/* <tr>
                                              <td
                                                style={{ borderTop: "none" }}
                                                className="text-wrap"
                                              >
                                                <span
                                                  className="me-50"
                                                  style={{
                                                    color: "#526fd7",
                                                    fontWeight: "500",
                                                  }}
                                                >
                                                  {" "}
                                                  Logs
                                                </span>{" "}
                                              </td>

                                              <td style={{ borderTop: "none" }}>
                                                <div className="checkbox">
                                                  <Checkbox
                                                    size="large"
                                                    checked={
                                                      isChecked?.logsView
                                                    }
                                                    name="logsView"
                                                    value={isChecked?.logsView}
                                                    onChange={handleInputs}
                                                  />
                                                </div>
                                              </td>
                                            </tr> */}
                                            <tr>
                                              <td
                                                style={{ borderTop: "none" }}
                                                className="text-wrap"
                                              >
                                                <span
                                                  className="me-50"
                                                  style={{
                                                    color: "#526fd7",
                                                    fontWeight: "500",
                                                  }}
                                                >
                                                  {" "}
                                                  Internal Meetings
                                                </span>{" "}
                                              </td>

                                              <td style={{ borderTop: "none" }}>
                                                <div className="checkbox">
                                                  <Checkbox
                                                    size="large"
                                                    checked={
                                                      isChecked?.internalMeetingView
                                                    }
                                                    name="internalMeetingView"
                                                    value={
                                                      isChecked?.internalMeetingView
                                                    }
                                                    onChange={handleInputs}
                                                  />
                                                </div>
                                              </td>
                                              <td style={{ borderTop: "none" }}>
                                                <div className="checkbox">
                                                  <Checkbox
                                                    size="large"
                                                    checked={
                                                      isChecked?.internalMeetingAdd
                                                    }
                                                    name="internalMeetingAdd"
                                                    value={
                                                      isChecked?.internalMeetingAdd
                                                    }
                                                    onChange={handleInputs}
                                                  />
                                                </div>
                                              </td>
                                              <td style={{ borderTop: "none" }}>
                                                <div className="checkbox">
                                                  <Checkbox
                                                    size="large"
                                                    checked={
                                                      isChecked?.internalMeetingEdit
                                                    }
                                                    name="internalMeetingEdit"
                                                    value={
                                                      isChecked?.internalMeetingEdit
                                                    }
                                                    onChange={handleInputs}
                                                  />
                                                </div>
                                              </td>
                                              <td style={{ borderTop: "none" }}>
                                                <div className="checkbox">
                                                  <Checkbox
                                                    size="large"
                                                    checked={
                                                      isChecked?.internalMeetingDelete
                                                    }
                                                    name="internalMeetingDelete"
                                                    value={
                                                      isChecked?.internalMeetingDelete
                                                    }
                                                    onChange={handleInputs}
                                                  />
                                                </div>
                                              </td>
                                              <td style={{ borderTop: "none" }}>
                                                <div className="checkbox">
                                                  {/* <Checkbox
                                                    size="large"
                                                    checked={
                                                      isChecked?.logsView
                                                    }
                                                    name="logsView"
                                                    value={isChecked?.logsView}
                                                    onChange={handleInputs}
                                                  /> */}
                                                </div>
                                              </td>
                                              <td style={{ borderTop: "none" }}>
                                                <div className="checkbox">
                                                  <Checkbox
                                                    size="large"
                                                    checked={
                                                      isChecked?.internalMeetingPublish
                                                    }
                                                    name="internalMeetingPublish"
                                                    value={
                                                      isChecked?.internalMeetingPublish
                                                    }
                                                    onChange={handleInputs}
                                                  />
                                                </div>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td
                                                style={{ borderTop: "none" }}
                                                className="text-wrap"
                                              >
                                                <span
                                                  className="me-50"
                                                  style={{
                                                    color: "#526fd7",
                                                    fontWeight: "500",
                                                  }}
                                                >
                                                  {" "}
                                                  Zoom Meetings
                                                </span>{" "}
                                              </td>

                                              <td style={{ borderTop: "none" }}>
                                                <div className="checkbox">
                                                  <Checkbox
                                                    size="large"
                                                    checked={
                                                      isChecked?.zoomMeetingView
                                                    }
                                                    name="zoomMeetingView"
                                                    value={
                                                      isChecked?.zoomMeetingView
                                                    }
                                                    onChange={handleInputs}
                                                  />
                                                </div>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td
                                                style={{ borderTop: "none" }}
                                                className="text-wrap"
                                              >
                                                <span
                                                  className="me-50"
                                                  style={{
                                                    color: "#526fd7",
                                                    fontWeight: "500",
                                                  }}
                                                >
                                                  {" "}
                                                  Drafted Meetings
                                                </span>{" "}
                                              </td>

                                              <td style={{ borderTop: "none" }}>
                                                <div className="checkbox">
                                                  <Checkbox
                                                    size="large"
                                                    checked={
                                                      isChecked?.draftedZoomMeetingView
                                                    }
                                                    name="draftedZoomMeetingView"
                                                    value={
                                                      isChecked?.draftedZoomMeetingView
                                                    }
                                                    onChange={handleInputs}
                                                  />
                                                </div>
                                              </td>
                                              <td style={{ borderTop: "none" }}>
                                                <div className="checkbox">
                                                  <Checkbox
                                                    size="large"
                                                    checked={
                                                      isChecked?.draftedZoomMeetingAdd
                                                    }
                                                    name="draftedZoomMeetingAdd"
                                                    value={
                                                      isChecked?.draftedZoomMeetingAdd
                                                    }
                                                    onChange={handleInputs}
                                                  />
                                                </div>
                                              </td>
                                              <td style={{ borderTop: "none" }}>
                                                <div className="checkbox">
                                                  <Checkbox
                                                    size="large"
                                                    checked={
                                                      isChecked?.draftedZoomMeetingEdit
                                                    }
                                                    name="draftedZoomMeetingEdit"
                                                    value={
                                                      isChecked?.draftedZoomMeetingEdit
                                                    }
                                                    onChange={handleInputs}
                                                  />
                                                </div>
                                              </td>
                                              <td style={{ borderTop: "none" }}>
                                                <div className="checkbox">
                                                  <Checkbox
                                                    size="large"
                                                    checked={
                                                      isChecked?.draftedZoomMeetingDelete
                                                    }
                                                    name="draftedZoomMeetingDelete"
                                                    value={
                                                      isChecked?.draftedZoomMeetingDelete
                                                    }
                                                    onChange={handleInputs}
                                                  />
                                                </div>
                                              </td>
                                              <td style={{ borderTop: "none" }}>
                                                <div className="checkbox">
                                                  {/* <Checkbox
                                                    size="large"
                                                    checked={
                                                      isChecked?.logsView
                                                    }
                                                    name="logsView"
                                                    value={isChecked?.logsView}
                                                    onChange={handleInputs}
                                                  /> */}
                                                </div>
                                              </td>
                                              <td style={{ borderTop: "none" }}>
                                                <div className="checkbox">
                                                  <Checkbox
                                                    size="large"
                                                    checked={
                                                      isChecked?.draftedZoomMeetingPublish
                                                    }
                                                    name="draftedZoomMeetingPublish"
                                                    value={
                                                      isChecked?.draftedZoomMeetingPublish
                                                    }
                                                    onChange={handleInputs}
                                                  />
                                                </div>
                                              </td>
                                              <td style={{ borderTop: "none" }}>
                                                <div className="checkbox">
                                                  <Checkbox
                                                    size="large"
                                                    checked={
                                                      isChecked?.draftedZoomMeetingSelect
                                                    }
                                                    name="draftedZoomMeetingSelect"
                                                    value={
                                                      isChecked?.draftedZoomMeetingSelect
                                                    }
                                                    onChange={handleInputs}
                                                  />
                                                </div>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td
                                                style={{ borderTop: "none" }}
                                                className="text-wrap"
                                              >
                                                <span
                                                  className="me-50"
                                                  style={{
                                                    color: "#526fd7",
                                                    fontWeight: "500",
                                                  }}
                                                >
                                                  {" "}
                                                  Credit Management
                                                </span>{" "}
                                              </td>

                                              <td style={{ borderTop: "none" }}>
                                                <div className="checkbox">
                                                  <Checkbox
                                                    size="large"
                                                    checked={
                                                      isChecked?.creditManagementView
                                                    }
                                                    name="creditManagementView"
                                                    value={
                                                      isChecked?.creditManagementView
                                                    }
                                                    onChange={handleInputs}
                                                  />
                                                </div>
                                              </td>
                                              <td style={{ borderTop: "none" }}>
                                                <div className="checkbox">
                                                  <Checkbox
                                                    size="large"
                                                    checked={
                                                      isChecked?.creditManagementAdd
                                                    }
                                                    name="creditManagementAdd"
                                                    value={
                                                      isChecked?.creditManagementAdd
                                                    }
                                                    onChange={handleInputs}
                                                  />
                                                </div>
                                              </td>
                                              <td style={{ borderTop: "none" }}>
                                                <div className="checkbox">
                                                  <Checkbox
                                                    size="large"
                                                    checked={
                                                      isChecked?.creditManagementEdit
                                                    }
                                                    name="creditManagementEdit"
                                                    value={
                                                      isChecked?.creditManagementEdit
                                                    }
                                                    onChange={handleInputs}
                                                  />
                                                </div>
                                              </td>
                                              <td style={{ borderTop: "none" }}>
                                                <div className="checkbox">
                                                  <Checkbox
                                                    size="large"
                                                    checked={
                                                      isChecked?.creditManagementDelete
                                                    }
                                                    name="creditManagementDelete"
                                                    value={
                                                      isChecked?.creditManagementDelete
                                                    }
                                                    onChange={handleInputs}
                                                  />
                                                </div>
                                              </td>
                                              <td style={{ borderTop: "none" }}>
                                                <div className="checkbox">
                                                  {/* <Checkbox
                                                    size="large"
                                                    checked={
                                                      isChecked?.logsView
                                                    }
                                                    name="logsView"
                                                    value={isChecked?.logsView}
                                                    onChange={handleInputs}
                                                  /> */}
                                                </div>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td
                                                style={{ borderTop: "none" }}
                                                className="text-wrap"
                                              >
                                                <span
                                                  className="me-50"
                                                  style={{
                                                    color: "#526fd7",
                                                    fontWeight: "500",
                                                  }}
                                                >
                                                  {" "}
                                                  Lead
                                                </span>{" "}
                                              </td>

                                              <td style={{ borderTop: "none" }}>
                                                <div className="checkbox">
                                                  <Checkbox
                                                    size="large"
                                                    checked={
                                                      isChecked?.leadView
                                                    }
                                                    name="leadView"
                                                    value={
                                                      isChecked?.leadView
                                                    }
                                                    onChange={handleInputs}
                                                  />
                                                </div>
                                              </td>
                                              <td style={{ borderTop: "none" }}>
                                                <div className="checkbox">
                                                  <Checkbox
                                                    size="large"
                                                    checked={
                                                      isChecked?.leadAdd
                                                    }
                                                    name="leadAdd"
                                                    value={
                                                      isChecked?.leadAdd
                                                    }
                                                    onChange={handleInputs}
                                                  />
                                                </div>
                                              </td>
                                              <td style={{ borderTop: "none" }}>
                                                <div className="checkbox">
                                                  <Checkbox
                                                    size="large"
                                                    checked={
                                                      isChecked?.leadEdit
                                                    }
                                                    name="leadEdit"
                                                    value={
                                                      isChecked?.leadEdit
                                                    }
                                                    onChange={handleInputs}
                                                  />
                                                </div>
                                              </td>
                                              <td style={{ borderTop: "none" }}>
                                                <div className="checkbox">
                                                  <Checkbox
                                                    size="large"
                                                    checked={
                                                      isChecked?.leadDelete
                                                    }
                                                    name="leadDelete"
                                                    value={
                                                      isChecked?.leadDelete
                                                    }
                                                    onChange={handleInputs}
                                                  />
                                                </div>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Form.Item>

                              <Form.Item className="mb-0">
                                <div className="col-lg-12 d-flex justify-content-end">
                                  <button
                                    key="submit"
                                    htmlType="submit"
                                    className="viewButton btn"
                                  >
                                    {spinner ? (
                                      <>
                                        <Spinner
                                          size="sm"
                                          style={{
                                            width: "1rem",
                                            height: "1rem",
                                            marginRight: "5px",
                                          }}
                                        />
                                        Save
                                      </>
                                    ) : (
                                      "Save"
                                    )}
                                  </button>
                                </div>
                              </Form.Item>
                            </Form>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <>
                        <div
                          className="d-flex justify-content-center"
                          style={{ marginTop: "20px" }}
                        >
                          <div role="status">
                            <Spinner animation="border" />
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default EnquiryPage;
