import {
  Button,
  Divider,
  Form,
  Input,
  Modal,
  notification,
  Select,
  Spin,
} from "antd";
import React, { useCallback, useMemo, useState } from "react";
import API_URL from "../../Constants/api-path";
import {
  ExclamationCircleOutlined,
  CheckCircleOutlined,
  LoadingOutlined,
  CloseCircleOutlined,
  MailOutlined,
} from "@ant-design/icons";
import PhoneInput from "react-phone-input-2";
import { countryOptions } from "../../Constants/CountryOptions";
import DebounceSelect from "../CloudFeatures/InternalMeetings/Components/DebounceSelect";
import { Country } from "country-state-city";
import axios from "axios";
import useZeroBounce from "../../Utils/useZeroBounce";
const CreateClientModal = ({
  showCreateModal,
  setCreateModal,
  GetClientList,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState({
    isLoading: false,
    index: 0
  })
  const { validateEmailWithZeroBounce } = useZeroBounce()
  const [clienData, setClienData] = useState({
    website: "",
    companyName: "",
    country: "",
    registrationType: "",
    client_type: null,
    bd_owner: null,
    cs_owner: null,
    users: [
      {
        name: "",
        email: "",
        contact_number: "",
        contact_code: "",
        phone: "",
        country: "",
        isValidEmail: ""
      },
    ],
  });
  const countries = Country?.getAllCountries();

  const [phone, setPhone] = useState("");
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const userData = JSON.parse(localStorage.getItem("userData"));
  const resetForm = useCallback(() => {
    setClienData({
      companyName: "",
      registrationType: "",
      client_type: null,
      bd_owner: null,
      cs_owner: null,
      users: [
        {
          name: "",
          email: "",
          contact_number: "",
          contact_code: "",
          phone: "",
          country: "",
          isValidEmail: ""
        },
      ],
    });
    setPhone("");
    setCreateModal(false);
    form.resetFields();
  }, [form, setCreateModal]);
  const AddClientData = useCallback(async () => {
    setLoadingSubmit(true);

    try {
      const res = await fetch(`${API_URL.CLIENT_CREATE_MULTIPLE}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userData?.token}`,
        },
        body: JSON.stringify({
          companyName: clienData.companyName,
          client_website: clienData.website,
          client_type: clienData?.client_type,
          bd_owner: clienData?.bd_owner?.key,
          cs_owner: clienData?.cs_owner?.key,
          registrationType: clienData.registrationType,
          user_details: clienData?.users?.map((user) => {
            return {
              name: user?.name,
              email: user?.email,
              contact_code: user?.contact_code,
              contact_number: user?.contact_number,
              country: user?.country,
            };
          }),
        }),
      });

      const result = await res.json();
      if (result && (result.status === 200 || result.status === true)) {
        GetClientList();
        resetForm();
        notification.success({
          message: "Client created",
          icon: <CheckCircleOutlined style={{ color: "#fff" }} />,
          style: {
            backgroundColor: "#2ecc71",
            color: "#fff",
            border: "1px solid #52c41a",
          },
          duration: 5,
          placement: "topRight",
        });
        setLoadingSubmit(false);
      } else if (result && result.message === "Email is already exists.") {
        setLoadingSubmit(false);
        notification.error({
          message: "Client domain already exists.",
          icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
          style: {
            backgroundColor: "#e74c3c",
            color: "#fff",
            border: "1px solid #c0392b",
          },
          duration: 5,
          placement: "topRight",
        });
      } else {
        setLoadingSubmit(false);
        notification.error({
          message: "Error: Something went wrong server error",
          icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
          style: {
            backgroundColor: "#e74c3c",
            color: "#fff",
            border: "1px solid #c0392b",
          },
          duration: 5,
          placement: "topRight",
        });
      }
    } catch (error) {
      setLoadingSubmit(false);
      notification.error({
        message: "Error: Something went wrong server error",
        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
        style: {
          backgroundColor: "#e74c3c",
          color: "#fff",
          border: "1px solid #c0392b",
        },
        duration: 5,
        placement: "topRight",
      });
    }
  }, [GetClientList, clienData, resetForm, userData?.token]);
  const handleInputsClient = useCallback(
    (e) => {
      const name = e.target.name;
      const value = e.target.value;
      setClienData({ ...clienData, [name]: value });
    },
    [clienData]
  );
  const updatedCountries = useMemo(
    () =>
      countries?.map((country, index) => {
        return (
          <Select.Option key={country.isoCode} value={country.name}>
            {country.name}
          </Select.Option>
        );
      }),
    [countries]
  );

  const handleInputUsers = useCallback(
    (e, index) => {
      let value = e.target.value;
      let name = e.target.name;
      const updatedUsers = clienData.users.map((user, i) =>
        i === index
          ? {
            ...user,
            [name]: value,
          }
          : user
      );
      // Update the state with new user data
      setClienData((prevStep3) => ({
        ...prevStep3,
        users: updatedUsers,
      }));
    },
    [clienData.users]
  );

  const validateEmail = useCallback(async (_, value) => {
    let findIndex = _?.field?.split(".")?.[0]
    if (!value) {
      return Promise.reject(new Error("Client user email is required."));
    } else if (value) {
      let isEmailValidated = value.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/);
      console.log('isEmailValidated: ', isEmailValidated);
      // let isEmailValidated = value.match(
      //   /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      // );
      if (isEmailValidated) {
        handleInputUsers({
          target: {
            name: "isValidEmail",
            value: true
          }
        }, Number(findIndex))
        setLoading({
          index: Number(findIndex),
          isLoading: true
        })
        try {
          const response = await validateEmailWithZeroBounce(value);
          if (response?.status === "valid" || response?.status === "unknown") {
            setLoading({
              index: Number(findIndex),
              isLoading: false
            })
            try {
              // Make an API call to check if the email is valid
              let res = await axios.get(
                API_URL?.VALIDATE_CLIENT_EMAIL + `?email=${value}`
              );
              if (res?.data?.data?.length === 0) {
                return Promise.resolve();
              } else {
                return Promise.reject(new Error("This domain is already in use."));
              }
            } catch (error) {
              return Promise.reject(new Error("This domain is already in use."));
            }
          } else if (response?.error) {
            setLoading({
              index: Number(findIndex),
              isLoading: false
            })
            notification.error({
              message: "Account ran out of credits please contact to administrator",
              icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
              style: {
                backgroundColor: "#e74c3c",
                color: "#fff",
                border: "1px solid #c0392b",
              },
              duration: 5,
              placement: "topRight",
            });
            return Promise.reject(new Error("Account ran out of credits."));

          } else {
            setLoading({
              index: Number(findIndex),
              isLoading: false
            })
            return Promise.reject(new Error("Please enter a valid email address."));
          }
        } catch (error) {
          setLoading({
            index: Number(findIndex),
            isLoading: false
          })
          console.error(error);
        }
      } else {
        handleInputUsers({
          target: {
            name: "isValidEmail",
            value: false
          }
        }, Number(findIndex))
      }

    }
  }, [handleInputUsers, validateEmailWithZeroBounce]);
  const handlePhoneChange = useCallback(
    (value, country, index) => {
      setPhone(value);
      const updatedUsers = clienData.users.map((user, i) =>
        i === index
          ? {
            ...user,
            phone: value,
            contact_number: value?.replace(country?.dialCode, ""),
            contact_code: country?.dialCode,
            // country: selectedCountry?.name,
          }
          : user
      );
      // Update the state with new user data
      setClienData((prevStep3) => ({
        ...prevStep3,
        users: updatedUsers,
      }));
    },
    [clienData.users]
  );

  return (
    <Modal
      open={showCreateModal}
      title={
        <div>
          Create New Client
          <Divider className="m-2" />
        </div>
      }
      onCancel={() => {
        resetForm();
      }}
      width={800}
      centered
      scrollable={true}
      footer={false}
    >
      <Form
        form={form}
        layout="vertical"
        name="create_client_form"
        onFinish={() => {
          // const emails = clienData?.users;
          // const domain = emails?.[0]?.email?.split("@")?.[1]; // Get domain of first email

          // const invalidEmails = emails.filter(
          //   ({ email }) => email?.split("@")?.[1] !== domain
          // );

          // if (invalidEmails.length > 0) {
          //   emails?.forEach(({ email }, index) => {
          //     form.setFields([
          //       {
          //         name: [index, "email"],
          //         errors: ["Email domains must match!"],
          //       },
          //     ]);
          //   });
          // } else {
          // Submit form or proceed with further logic if validation passes
          AddClientData();
          // }
        }}
      >
        <div>
          <div className="row">
            <div className="col-md-6">
              <Form.Item
                label="Company Name"
                name={"companyName"}
                rules={[
                  {
                    required: true,
                    message: "Company Name is required.",
                  },
                ]}
              >
                <Input
                  size="large"
                  name="companyName"
                  value={clienData?.companyName}
                  onChange={handleInputsClient}
                />
              </Form.Item>
            </div>
            <div className="col-md-6">
              <Form.Item label="Client Website" name={"website"}>
                <Input
                  size="large"
                  name="website"
                  value={clienData?.website}
                  onChange={handleInputsClient}
                />
              </Form.Item>
            </div>
            <div className="col-md-6">
              <Form.Item
                label="Platform to be used"
                name={"move_to"}
                rules={[
                  {
                    required: true,
                    message: "Platform to be used is required.",
                  },
                ]}
              >
                <Select
                  size="large"
                  style={{
                    width: "100%",
                  }}
                  value={clienData?.registrationType}
                  onChange={(value) => {
                    setClienData({ ...clienData, registrationType: value });
                  }}
                >
                  <Select.Option value={"client"}>Nextyn</Select.Option>
                  <Select.Option value={"cloud"}>Nextyn IQ</Select.Option>
                </Select>
              </Form.Item>
            </div>

            <div className="col-md-6">
              <Form.Item
                label="Client Type"
                name={"client_type"}
                rules={[
                  {
                    required: true,
                    message: "Client type is required.",
                  },
                ]}
              >
                <Select
                  size="large"
                  style={{
                    width: "100%",
                  }}
                  value={clienData?.client_type}
                  onChange={(value) => {
                    setClienData({ ...clienData, client_type: value });
                  }}
                >
                  <Select.Option value={"PSFs"}>PSFs</Select.Option>
                  <Select.Option value={"Corporates"}>Corporates</Select.Option>
                  <Select.Option value={"Investment Firms"}>
                    Investment Firms
                  </Select.Option>
                </Select>
              </Form.Item>
            </div>
            <div className="col-md-6">
              <Form.Item
                label="BD Owner"
                name={"bd_owner"}
                rules={[
                  {
                    required: true,
                    message: "BD Owner is required.",
                  },
                ]}
              >
                <DebounceSelect
                  type="manager"
                  size="large"
                  optionType="email"
                  name={`bd_owner`}
                  // mode="multiple"
                  value={clienData.bd_owner}
                  placeholder="Select Team Member"
                  style={{ flex: 1, marginRight: "8px" }}
                  virtual={false}
                  onChange={(selectedValue) => {
                    setClienData({ ...clienData, bd_owner: selectedValue });
                  }}
                  allowClear
                />
              </Form.Item>
            </div>
            <div className="col-md-6">
              <Form.Item
                label="CS Owner"
                name={"cs_owner"}
                rules={[
                  {
                    required: true,
                    message: "CS Owner is required.",
                  },
                ]}
              >
                <DebounceSelect
                  type="manager"
                  size="large"
                  optionType="email"
                  name={`cs_owner`}
                  value={clienData.cs_owner}
                  placeholder="Select Team Member"
                  style={{ flex: 1, marginRight: "8px" }}
                  virtual={false}
                  onChange={(selectedValue) => {
                    setClienData({ ...clienData, cs_owner: selectedValue });
                  }}
                  allowClear
                />
              </Form.Item>
            </div>
            <Divider className="mt-0 mb-3" />
            {clienData?.users?.map((user, index) => {
              console.log("error val", form.getFieldError([index, "email"]));
              return (
                <>
                  <div className="col-md-6">
                    <Form.Item
                      label="Client User Name"
                      name={[index, "name"]}
                      rules={[
                        {
                          required: true,
                          message: "Name is required.",
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        name="name"
                        value={user.name}
                        onChange={(e) => handleInputUsers(e, index)}
                      />
                    </Form.Item>
                  </div>
                  <div className="col-md-6">
                    <Form.Item
                      label="Client User Email"
                      name={[index, "email"]}
                      rules={[
                        {
                          required: true,
                          validator: validateEmail,
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        name="email"
                        value={user.email}
                        onChange={(e) => handleInputUsers(e, index)}
                        suffix={loading?.index === index && loading?.isLoading ? <LoadingOutlined /> : user.email !== "" && !user?.isValidEmail && form.getFieldError([index, "email"])?.length > 0 ? <CloseCircleOutlined /> : user.email !== "" && user?.isValidEmail && form.getFieldError([index, "email"])?.length === 0 ?
                          <CheckCircleOutlined style={{ color: "#52c41a" }} /> : <MailOutlined />
                        } />
                    </Form.Item>
                  </div>
                  <div className="col-md-6">
                    <Form.Item
                      label="Client User Location"
                      name={[index, "country"]}
                      rules={[
                        {
                          required: true,
                          message: "Location is required",
                        },
                      ]}
                    >
                      <Select
                        size="large"
                        style={{
                          width: "100%",
                        }}
                        showSearch
                        filterOption={(input, option) =>
                          option?.children
                            ?.toLowerCase()
                            .indexOf(input?.toLowerCase()) >= 0
                        }
                        value={user.country}
                        onChange={(value) => {
                          handleInputUsers(
                            {
                              target: {
                                name: "country",
                                value: value,
                              },
                            },
                            index
                          );
                        }}
                      >
                        {updatedCountries}
                      </Select>
                    </Form.Item>
                  </div>
                  <div
                    className={`${index === clienData?.users?.length - 1
                      ? "col-md-4"
                      : "col-md-6"
                      }`}
                  >
                    <Form.Item label="Contact Number">
                      <PhoneInput
                        country={"us"}
                        enableSearch={true}
                        name="contact_number"
                        placeholder="Contact Number"
                        onChange={(value, country) => {
                          handlePhoneChange(value, country, index);
                        }}
                        inputStyle={{
                          width: "100%",
                        }}
                        value={user.phone}
                      />
                    </Form.Item>
                  </div>
                  {index === clienData?.users?.length - 1 && (
                    <div
                      className="col-md-2 mt-4"
                      style={{
                        alignContent: "center",
                      }}
                    >
                      <Form.Item>
                        <Button
                          onClick={() => {
                            if (clienData?.users?.length > 1) {
                              form.resetFields([
                                [index, "name"],
                                [index, "email"],
                                [index, "country"],
                              ]);

                              const newInputs = [...clienData?.users];
                              newInputs.splice(index, 1);
                              setClienData({
                                ...clienData,
                                users: newInputs,
                              });
                            }
                          }}
                        >
                          Remove
                        </Button>
                      </Form.Item>
                    </div>
                  )}
                </>
              )
            })}
          </div>
        </div>
        <div className="col-md-12 d-flex justify-content-center">
          <Form.Item>
            <Button
              onClick={() => {
                setClienData({
                  ...clienData,
                  users: [
                    ...clienData.users,
                    {
                      name: "",
                      email: "",
                      contact_number: "",
                      contact_code: "",
                      phone: "",
                      country: "",
                      isValidEmail: ""
                    },
                  ],
                });
              }}
            >
              Add User
            </Button>
          </Form.Item>
        </div>
        <div className="d-flex justify-content-end">
          <Form.Item className="mb-0">
            <Button
              htmlType="button"
              className="secondaryBttn "
              onClick={() => {
                resetForm();
              }}
            >
              Cancel
            </Button>
          </Form.Item>
          <Form.Item className="mb-0">
            <Button className="viewButton ms-2" htmlType="submit">
              Submit{" "}
              {loadingSubmit && (
                <Spin
                  size="small"
                  indicator={
                    <LoadingOutlined
                      style={{
                        color: "white",
                      }}
                    />
                  }
                />
              )}
            </Button>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
};

export default CreateClientModal;
