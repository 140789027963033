import ZeroBounceSDK from '@zerobounce/zero-bounce-sdk';

import React from 'react'
import ENV from '../Constants/env';

const useZeroBounce = () => {
    const zeroBounce = React.useMemo(() => new ZeroBounceSDK(), [])
    zeroBounce.init(ENV.ZERO_BOUNCE_API_KEY)

    const validateEmailWithZeroBounce = React.useCallback(async (email) => {
        try {
            const response = await zeroBounce.validateEmail(email);
            return response
        } catch (error) {
            return error
        }
    }, [zeroBounce])

    const values = React.useMemo(
        () => ({
            validateEmailWithZeroBounce
        }), [validateEmailWithZeroBounce])
    return values
}

export default useZeroBounce