import {
  Form,
  Input,
  Select,
  Button,
  DatePicker,
  Checkbox,
  Spin,
  Tooltip,
} from "antd";
import { Country } from "country-state-city";

import React, { useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import dayjs from "dayjs";
import "./AddMeetingForm.css";
import { useNavigate, useSearchParams } from "react-router-dom";
import ConfirmationModal from "../../components/ConfirmationModal";
import useZoomMeeting from "./useZoomMeeting";
import { useDispatch } from "react-redux";
import {
  resetZoomMeeting,
  setIsAutoRecordingCheckboxChecked,
  setIsCheckboxChecked,
  setIsMediatorCheckboxChecked,
  setIsMeetingLinkCheckboxChecked,
  setMediator,
  setStep1,
} from "./zoomMeeting.slice";
import { LocalizationProvider, MobileTimePicker } from "@mui/x-date-pickers";
import { LoadingOutlined } from "@ant-design/icons";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { BsChevronLeft } from "../../Utils/Icons";
import { convertToTitleCase, getUpdatedCountriesTimeZone } from "../../Utils/util";
import { ACCOUNT_AND_FINANCE_ID } from "../../Utils/Constants";
import API_URL from "../../Constants/api-path";
import axios from "axios";
import debounce from "lodash/debounce";
import CustomSelect from "./CustomSelect";
import CustomSelectExpert from "./CustomSelectExpert";
const { Option } = Select;

function UpdateMeetingForm() {
  const {
    zoomAccountList,
    userProjectList,
    zoomHostAccountList,
    form,
    expertCurrency,
    handleDeleteInputExpert,
    handleDeleteInput,
    handleInputChangeExpert,
    handleInputChange,
    handleAddInputExpert,
    handleAddInput,
    isCheckboxChecked, isMediatorCheckboxChecked, autoRecordingCheckboxChecked, meetingLinkCheckboxChecked, handleExpertCountryChange, handleMediatorCountryChange, handleInputChangeExpertTimezone, handleInputChangeMediatorTimezone,
    step1,
    step3,
    inputs, mediators,
    updateDraftMeeting,
    allResult,
    isLoadingList,
    isLoadingMeetingData,
    isValidatingList,
    isValidatingMeetingData,
    zoomCompanyList,
    debounceFetchClient,
    loading, loadingClient, isLoadingClients, loadingExpert, isLoadingExperts, fetchAllClientList, fetchAllExpertList, handleAddMediator,
    handleMediatorChange,
    handleDeleteMediator, debounceFetchExpert, debounceFetcherProject, zoomExpertList
  } = useZoomMeeting();

  const inputRef = React.useRef(null);
  const [email, setEmail] = useState("");
  const [open, setOpen] = useState("");
  const [exist, setExist] = useState({
    message: "",
    exist: false,
  });
  const checkClientExist = React.useCallback(
    async (email, index) => {
      let resp = await axios.get(
        API_URL.CHECK_CLIENT_EXIST + `?email=${email}`
      );
      if (resp?.data?.data) {
        setExist({
          message: resp?.data?.message,
          exist: true,
        });
        form.setFields([
          {
            name: [index, "client_email"],
            errors: [],
          },
        ]);
      } else {
        setExist({
          message: resp?.data?.message,
          exist: false,
        });
        form.setFields([
          {
            name: [index, "client_email"],
            errors: [resp?.data?.message],
          },
        ]);
      }
    },
    [form]
  );
  const onEmailChange = useMemo(() => {
    let loadOptions = (event, index) => {
      checkClientExist(event.target.value, index);
    };
    return debounce(loadOptions, 800);
  }, [checkClientExist]);
  const addItem = (e, index) => {
    e.preventDefault();
    let id = Math.floor(100000 + Math.random() * 900000);
    let obj = {
      label: email,
      value: email,
      key: id,
      title: "",
      in_list: false,
    };

    form.setFieldValue([index, "client_email"], email);
    form.setFieldValue([index, "client_name"], "");
    handleInputChange(obj, "client_email", index);
    form.validateFields([[index, "client_email"]])
    setEmail("");
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };
  const dispatch = useDispatch();
  const [type, setType] = useState("draft");
  const countries = Country?.getAllCountries();

  const [openConfirm, setOpenConfirm] = useState(false);
  let [searchParams] = useSearchParams();
  let action = searchParams.get("action");
  const updatedCountries = useMemo(
    () =>
      countries?.map((country, index) => {
        return (
          <Option key={country.isoCode} value={country.name}>
            {country.name}
          </Option>
        );
      }),
    [countries]
  );
  // const updatedCountriesTimeZone = useMemo(
  //     () =>
  //         countries?.map((country, index) => {
  //             return (
  //                 <Option key={country?.timezones[0]?.zoneName} value={country?.timezones[0]?.zoneName}>
  //                     {country?.timezones[0]?.zoneName}{" "}
  //                 </Option>
  //             );
  //         }),
  //     [countries]
  // );

  const dateFormat = "DD/MM/YYYY";
  const userData = JSON.parse(localStorage.getItem("userData"));

  const selectBefore = (
    <div className="d-flex flex-row">
      <Select
        style={{
          width: 80,
        }}
        defaultValue="USD"
        size="large"
        value={step1?.hourly_currency || "USD"} // Use addData?.hourly_currency if it exists, otherwise default to "USD"
        onChange={expertCurrency}
        disabled={action === "view"}
      >
        <Select.Option value="USD">USD</Select.Option>
        <Select.Option value="INR">INR</Select.Option>
        <Select.Option value="AED">AED</Select.Option>
        <Select.Option value="EUR">EUR</Select.Option>
        <Select.Option value="JPY">JPY</Select.Option>
        <Select.Option value="GBP">GBP</Select.Option>
        <Select.Option value="AUD">AUD</Select.Option>
        <Select.Option value="CAD">CAD</Select.Option>
        <Select.Option value="NZD">NZD</Select.Option>
        <Select.Option value="SGD">SGD</Select.Option>
        <Select.Option value="HKD">HKD</Select.Option>
      </Select>
    </div>
  );

  const validateName = (_, value) => {
    if (!value) {
      return Promise.reject(new Error("Client full name is required!"));
    } else if (value) {
      let splitName = value?.split(" ");
      if (splitName?.length > 1) {
        return Promise.resolve();
      } else {
        return Promise.reject(new Error("Please enter full name!"));
      }
    }
  };
  const validateEmail = (_, value) => {
    if (!value) {
      return Promise.reject(new Error("Moderator email is required."));
    } else if (value) {
      let isEmailValidated = value.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
      if (isEmailValidated) {
        return Promise.resolve();
      } else {
        return Promise.reject(new Error("Please enter a valid email address."));
      }
    }
  };
  const validateExpertName = (_, value) => {
    if (!value) {
      return Promise.reject(new Error("Expert full name is required."));
    } else if (value) {
      let splitName = value?.split(" ");
      if (splitName?.length > 1) {
        return Promise.resolve();
      } else {
        return Promise.reject(new Error("Please enter full name."));
      }
    }
  };
  const clientOptions = useMemo(() => {
    return zoomCompanyList?.map((itemData) => {
      return {
        label: itemData?.name,
        value: itemData?._id,
        key: itemData?._id,
      };
    });
  }, [zoomCompanyList]);
  const navigate = useNavigate();
  const zoomAccountOptions = useMemo(
    () =>
      zoomHostAccountList?.map((item) => ({
        label: item?.email,
        value: item?._id,
      })),
    [zoomHostAccountList]
  );

  const optionsDatasEmailZoom = useMemo(() => {
    return zoomExpertList?.map((itemData) => {
      const dataEmail = itemData?.email;
      return {
        key: itemData?._id,
        value: dataEmail,
        label: dataEmail,
        title: itemData,
      };
    });
  }, [zoomExpertList]);
  return (
    <div>
      <div className="bk-div float-start w-100">
        <div className="body-expart-div">
          <div className="d-flex justify-content-start align-items-center">
            <span
              onClick={() => {
                navigate(-1);
              }}
              style={{ cursor: "pointer" }}
            >
              <BsChevronLeft className="me-2 search-btn-back" />
            </span>
            <h2>Update Zoom Meeting</h2>
          </div>

          {isLoadingList ||
            isLoadingMeetingData ||
            isValidatingList ||
            isValidatingMeetingData ? (
            <div className="d-flex justify-content-center align-items-center h-100">
              <Spin size={"large"} />
            </div>
          ) : (
            <div className="zoom-form-body">
              <Form
                scrollToFirstError
                layout="vertical"
                autoComplete="off"
                form={form}
                onFinish={() => {
                  // if (type === "draft") {
                  //   updateDraftMeeting("draft");
                  // }
                  if (type === "submit") {
                    updateDraftMeeting("submit");
                  }
                  if (type === "approve") {
                    setOpenConfirm(true);
                  }
                }}
              >
                <Form.Item
                  label={<h5 className="mb-2">Project Details</h5>}
                  name="project_information"
                  required
                >
                  <Row>
                    <Col md={12}>
                      <Form.Item
                        name={"engagement_type"}
                        rules={[
                          {
                            required: true,
                            message: "Engagement Type is required.",
                          },
                        ]}
                      >
                        <Select
                          name="engagement_type"
                          size="large"
                          labelInValue
                          allowClear
                          placeholder="Select Engagement Type"
                          autoComplete="off"
                          showSearch
                          value={step1.engagement_type}
                          onChange={(value, option) => {
                            dispatch(
                              setStep1({
                                ...step1,
                                engagement_type: value?.value,
                              })
                            );
                          }}
                        >
                          <Option value={"micro_consulting_call"}>Micro-Consulting Call</Option>
                          <Option value={"file_or_sheet_filling"}>File/Sheet Filling</Option>
                          <Option value={"focus_group_discuession"}>Focus Group Discussion</Option>
                          <Option value={"in_person_meeting"}>In Person Meeting</Option>
                          <Option value={"b2b_survey"}>B2B Survey</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    {!["focus_group_discuession", "in_person_meeting", "b2b_survey", "file_or_sheet_filling"]?.includes(step1?.engagement_type) && userData?.data?.roleData?.[0]?.role_permission?.[16]
                      ?.draftedZoomMeeting?.selectZoomAcc && !meetingLinkCheckboxChecked && (
                        <Col md={4}>
                          <Form.Item
                            name="zoom_id"
                            rules={[
                              {
                                required: true,
                                message: "Zoom account is required.",
                              },
                            ]}
                          >
                            <Select
                              size="large"
                              name="zoom_id"
                              allowClear
                              placeholder="Select Zoom Account"
                              disabled={action === "view" || !step1?.project_id}
                              onChange={(value) => {
                                dispatch(
                                  setStep1({
                                    ...step1,
                                    zoom_id: value,
                                  })
                                );
                              }}
                              value={step1?.zoom_id}
                              options={zoomAccountOptions}
                            />
                          </Form.Item>
                        </Col>
                      )}
                    <Col md={userData?.data?.roleData?.[0]?.role_permission?.[16]
                      ?.draftedZoomMeeting?.selectZoomAcc && !meetingLinkCheckboxChecked ? 8 : 12}>
                      <Form.Item
                        name="project_name"
                        // label="Project Name"
                        rules={[
                          {
                            required: true,
                            message: "Project name is required.",
                            // validator: validateName,
                          },
                        ]}
                      >
                        {/* <Input
                          type="text"
                          name="project_name"
                          autoComplete="nope"
                          size="large"
                          placeholder="Project Name"
                          value={step1?.project_name}
                          onChange={handleStep1Change}
                        /> */}
                        <Select
                          showSearch
                          allowClear
                          filterOption={false}
                          // onDropdownVisibleChange={handleDropdownVisibleChange}
                          onSearch={debounceFetcherProject}
                          size="large"
                          value={step1?.project_name || null}
                          placeholder="Select Project"
                          onChange={(newValue, option) => {
                            if (step1?.project_name) {
                              dispatch(resetZoomMeeting());
                              form.resetFields()
                            }
                            dispatch(
                              setStep1({
                                ...step1,
                                call_time: "",
                                project_name: newValue,
                                project_id: option?.key,
                              })
                            );
                            form.setFieldValue("project_name", newValue)
                          }}
                          style={{ width: "100%" }}
                          options={userProjectList?.map((item) => ({
                            label: item?.project_title,
                            value: item?.project_title,
                            key: item?._id,
                          }))}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  {/* <Row>
                    <Col>
                      <Form.Item
                        name="call_name"
                        rules={[
                          {
                            required: true,
                            message: "Call name is required.",
                          },
                        ]}
                      >
                        <Input
                          type="text"
                          name="call_name"
                          autoComplete="nope"
                          size="large"
                          placeholder="Call Name"
                          value={step1?.call_name}
                          onChange={(e) => {
                            dispatch(
                              setStep1({
                                ...step1,
                                call_name: e.target.value,
                              })
                            )
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row> */}
                  <Row>
                    <Col xs={6} md={4}>
                      <Form.Item
                        name="call_date"
                        // label="Call Date"
                        rules={[
                          {
                            required: true,
                            message: "Call Date is required.",
                            // validator: validateName,
                          },
                        ]}
                      >
                        <DatePicker
                          style={{ width: "100%" }}
                          value={dayjs(step1.call_date, dateFormat)}
                          size="large"
                          disabled={action === "view" || !step1?.project_id}
                          format={dateFormat}
                          placeholder="Call Date"
                          disabledDate={(current) =>
                            current && current?.isBefore(dayjs(), "day")
                          }
                          onChange={(value) => {
                            dispatch(
                              setStep1({
                                ...step1,
                                call_date: value
                                  ? dayjs(value)?.format(dateFormat)
                                  : null,
                              })
                            );
                          }}
                        />
                      </Form.Item>
                    </Col>
                    {!["file_or_sheet_filling", "focus_group_discuession", "b2b_survey"]?.includes(step1?.engagement_type) && <Col xs={6} md={3} lg={2}>
                      <Form.Item
                        name="call_time"
                        // label="Call Time"

                        rules={[
                          {
                            required: true,
                            message: "Call Time is required.",
                            // validator: validateName,
                          },
                        ]}
                      >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <MobileTimePicker
                            label={"Call Time (IST)"}
                            // orientation="landscape"
                            views={["hours", "minutes"]}
                            value={dayjs(step1?.call_time, "HH:mm")}
                            className="fsfe"
                            defaultValue={dayjs("00:00", "HH:mm")}
                            disabled={!step1?.project_id}
                            ampm={false}
                            format="HH:mm"
                            onChange={(value) => {
                              dispatch(
                                setStep1({
                                  ...step1,
                                  call_time: value?.format("HH:mm"),
                                  call_time_string: value?.format("HH:mm"),
                                })
                              );
                            }}
                            onAccept={(value) => {
                              form.setFieldValue("call_time", value);
                            }}
                          />
                        </LocalizationProvider>
                        {/* <TimePicker
                                                showSecond={false}
                                                size="large"
                                                style={{ width: "100%" }}
                                                minuteStep={15}
                                                disabled={action === "view"}
                                                placeholder="Call Time"
                                                format={'HH:mm'}
                                                onChange={(time, timeString) => {
                                                    dispatch(
                                                        setStep1({
                                                            ...step1,
                                                            call_time: timeString,
                                                            call_time_string: timeString,
                                                        })
                                                    );
                                                }}
                                                value={dayjs(step1?.call_time || "00:00", "HH:mm")}
                                            /> */}
                      </Form.Item>
                    </Col>}

                    {!["focus_group_discuession", "in_person_meeting", "b2b_survey"]?.includes(step1?.engagement_type) && <Col md={4}>
                      <Form.Item
                        name={"duration"}
                        // label={
                        //     <span className="">Country</span>
                        // }
                        rules={[
                          {
                            required: true,
                            message: "Duration is required.",
                          },
                        ]}
                      >
                        <Select
                          name="duration"
                          size="large"
                          labelInValue
                          allowClear
                          disabled={action === "view" || !step1.project_id}
                          placeholder="Select Duration"
                          autoComplete="off"
                          showSearch
                          value={step1.duration}
                          onChange={(value) => {
                            dispatch(
                              setStep1({
                                ...step1,
                                duration: value,
                              })
                            );
                          }}
                        >
                          <Option value={15}>15 Min</Option>
                          <Option value={30}>30 Min</Option>
                          <Option value={45}>45 Min</Option>
                          <Option value={60}>60 Min</Option>
                          <Option value={75}>75 Min</Option>
                          <Option value={90}>90 Min</Option>
                          <Option value={105}>105 Min</Option>
                          <Option value={120}>120 Min</Option>
                          <Option value={135}>135 Min</Option>
                          <Option value={150}>150 Min</Option>
                          <Option value={165}>166 Min</Option>
                          <Option value={180}>180 Min</Option>
                        </Select>
                      </Form.Item>
                    </Col>}
                  </Row>
                </Form.Item>
                <Form.Item
                  label={<h5 className="mb-2">Client Details</h5>}
                  name="client_information"
                  required
                >
                  <Row>
                    {!["file_or_sheet_filling", "focus_group_discuession", "in_person_meeting", "b2b_survey"]?.includes(step1?.engagement_type) && <Col md={4} >
                      <Form.Item
                        name="country"
                        rules={[
                          {
                            required: true,
                            message: "Location is required.",
                          },
                        ]}
                      >
                        <Select
                          name="country"
                          size="large"
                          labelInValue
                          allowClear
                          disabled={action === "view" || !step1.project_id}
                          placeholder="Select Location"
                          autoComplete="off"
                          showSearch
                          value={step1.country}
                          onChange={(value) => {
                            dispatch(
                              setStep1({
                                ...step1,
                                country: value?.label,
                                timezone: "",
                                abbreviation: ""
                              })
                            );
                            form.setFieldValue("timezone", null)
                          }}
                        >
                          {updatedCountries}
                        </Select>
                      </Form.Item>
                    </Col>}
                    {!["file_or_sheet_filling", "focus_group_discuession", "in_person_meeting", "b2b_survey"]?.includes(step1?.engagement_type) && <Col md={4}>
                      <Form.Item
                        name="timezone"
                        // label={
                        //     <span className="">City</span>
                        // }
                        rules={[
                          {
                            required: true,
                            message: "Timezone is required.",
                          },
                        ]}
                      >
                        <Select
                          name="timezone"
                          size="large"
                          labelInValue
                          // disabled={action === "view"}
                          placeholder="Select Timezone"
                          filterOption={(input, option) => {
                            return (
                              option?.children
                                ?.join("")
                                ?.toLowerCase()
                                ?.indexOf(input?.toLowerCase()) >= 0
                            );
                          }}
                          autoComplete="off"
                          allowClear
                          disabled={["", undefined, "undefined"]?.includes(
                            step1?.country
                          ) || !step1.project_id}
                          // disabled={Object?.keys(step1?.country || {})?.length === 0}
                          showSearch
                          value={step1?.timezone}
                          onChange={(value) => {
                            dispatch(
                              setStep1({
                                ...step1,
                                timezone: value?.value,
                                abbreviation: value?.title
                              })
                            );
                          }}
                        >
                          {getUpdatedCountriesTimeZone(step1?.country)}
                        </Select>
                      </Form.Item>
                    </Col>}
                    <Col md={4}>
                      <Form.Item
                        name="hourly_rate_call_cunsltn"
                        // label="Call Rate"
                        rules={[
                          {
                            required: true,
                            message: "Call rate is required.",
                          },
                        ]}
                      >
                        <Input
                          name="hourly_rate_call_cunsltn"
                          type="number"
                          placeholder="Client Call Rate (Hourly)"
                          disabled={action === "view" || !step1.project_id}
                          addonBefore={selectBefore}
                          size="large"
                          value={step1?.hourly_rate_call_cunsltn}
                          onChange={(e) => {
                            dispatch(
                              setStep1({
                                ...step1,
                                hourly_rate_call_cunsltn: e.target.value,
                              })
                            );
                          }}
                        />
                      </Form.Item>
                    </Col>
                    {/* <Col  md={3}>
                      <Form.Item
                        name={"client"}
                        rules={[
                          {
                            required: true,
                            message: "Client is required.",
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          size="large"
                          filterOption={false}
                          placeholder="Select Client"
                          onSearch={debounceFetcher}
                          onClear={() => {
                            fetchCompanyList("");
                            form.resetFields(
                              inputs?.map((val, index) => [
                                index,
                                "client_email",
                              ])
                            );
                            form.resetFields(
                              inputs?.map((val, index) => [
                                index,
                                "client_name",
                              ])
                            );
                            dispatch(
                              setInputs([
                                {
                                  client_name: "",
                                  client_email: "",
                                },
                              ])
                            );
                          }}
                          onChange={(newValue, option) => {
                            if (option) {
                              fetchZoomAccountList(option);
                            }
                            dispatch(
                              setStep1({
                                ...step1,
                                client: newValue,
                              })
                            );
                          }}
                          //   name="client"
                          value={step1.client}
                          options={clientOptions}
                          virtual={false}
                          allowClear
                        />
                      </Form.Item>
                    </Col> */}
                  </Row>
                  <div>
                    {inputs?.map((input, index) => {
                      return (
                        <div key={index} className="mb-3">
                          <Row className="align-items-center">
                            <Col md={5}>
                              <Form.Item
                                name={[index, "client_email"]}
                                className="mb-md-0"
                                rules={[
                                  {
                                    required: true,
                                    message: "Client email is required.",
                                  },
                                ]}
                              >
                                <CustomSelect
                                  debounceFetchClient={debounceFetchClient}
                                  input={input}
                                  handleInputChange={(value, field, index) => {
                                    handleInputChange(value, field, index)
                                    if (value?.title?.split(" ")?.length < 2) {
                                      form.setFields([{
                                        name: [index, "client_name"],
                                        errors: ["Please enter full name."]
                                      }])
                                    }
                                  }}
                                  index={index}
                                  dispatch={dispatch}
                                  setStep1={setStep1}
                                  step1={step1}
                                  form={form}
                                  zoomAccountList={zoomAccountList}
                                  loadingClient={loadingClient}
                                  isLoadingClients={isLoadingClients}
                                  setEmail={setEmail}
                                  email={email}
                                  onEmailChange={onEmailChange}
                                  exist={exist}
                                  disabled={!step1?.project_id}
                                  addItem={addItem}
                                  creatableSelect={false}
                                  fetchAllClientList={fetchAllClientList}
                                />
                                {/* <Select
                                  showSearch
                                  type="client"
                                  optionType="email"
                                  filterOption={false}
                                  size="large"
                                  onSearch={debounceFetchClient}
                                  value={input.client_email}
                                  placeholder="Client Email"
                                  onChange={(newValue, option) => {
                                    handleInputChange(
                                      option,
                                      "client_email",
                                      index
                                    );
                                    dispatch(
                                      setStep1({
                                        ...step1,
                                        client: option?.company_id,
                                      })
                                    );
                                    form.setFieldValue(
                                      [index, "client_name"],
                                      option?.title
                                    );
                                  }}
                                  style={{
                                    width: "100%",
                                  }}
                                  options={zoomAccountList?.map((item) => ({
                                    label: item?.email,
                                    value: item?.email,
                                    key: item?._id,
                                    title: item?.name,
                                    company_id: item?.company_id,
                                    in_list: true,
                                  }))}
                                  dropdownRender={(menu) => (
                                    <>
                                      {loadingClient || isLoadingClients ? <span className='d-flex align-items-center justify-content-center' style={{
                                        height: 40,
                                      }}><Spin size="small" /> </span> : <>
                                        {menu}
                                        <Divider
                                          style={{
                                            margin: "8px 0",
                                          }}
                                        />
                                        <Space
                                          style={{
                                            padding: "0 8px 4px",
                                            width: "100%",
                                          }}
                                        >
                                          <Input
                                            placeholder="Please enter client email"
                                            ref={inputRef}
                                            width={100}
                                            value={email}
                                            onChange={(e) => {
                                              setEmail(e.target.value);
                                              if (e.target.value !== "") {
                                                onEmailChange(e, index);
                                              }
                                            }}
                                            onKeyDown={(e) => e.stopPropagation()}
                                          />
                                          <Button
                                            type="text"
                                            disabled={!exist?.exist}
                                            icon={<PlusOutlined />}
                                            onClick={(e) => addItem(e, index)}
                                          >
                                            Add
                                          </Button>
                                        </Space>
                                      </>
                                      }
                                    </>

                                  )}
                                /> */}
                              </Form.Item>
                            </Col>
                            <Col md={5}>
                              <Form.Item
                                className="mb-md-0"
                                name={[index, "client_name"]}
                                rules={[
                                  {
                                    required: true,
                                    validator: validateName,
                                  },
                                ]}
                              >
                                <Input
                                  name="client_name"
                                  type="text"
                                  placeholder="Client Name"
                                  disabled={!step1.project_id}
                                  size="large"
                                  value={input?.client_name}
                                  onChange={(e) => {
                                    handleInputChange(
                                      e.target.value,
                                      "client_name",
                                      index
                                    );
                                  }}
                                />

                              </Form.Item>
                            </Col>

                            <Col md={2}>
                              <div className="text-end text-md-start">
                                {(index === inputs?.length - 1 && index !== 0) && <Button
                                  type="primary"
                                  onClick={() => {
                                    if (inputs.length > 1) {
                                      let allData = form.getFieldValue([index]) || {}
                                      form.setFieldsValue({
                                        [index]: {
                                          ...allData,
                                          client_email: undefined,
                                          client_name: undefined,
                                        }
                                      });
                                    }
                                    handleDeleteInput(index)
                                  }}
                                  disabled={action === "view"}
                                >
                                  Remove
                                </Button>}
                              </div>
                            </Col>
                          </Row>
                        </div>
                      );
                    })}

                    <div className="d-flex justify-content-center align-items-center">
                      <Button
                        size="large"
                        className="text-center btn-responsive expertButtonNext"
                        onClick={handleAddInput}
                        disabled={action === "view"}
                      >
                        Add Client User
                      </Button>
                    </div>
                  </div>
                </Form.Item>
                <Form.Item
                  label={<h5 className="mb-2">{["focus_group_discuession", "b2b_survey"]?.includes(step1?.engagement_type) ? "Respondent/External Partner Details" : "Expert Details"}</h5>}
                  name="expert_information"
                  required
                >
                  <div>
                    {step3?.map((input, index) => {
                      return (
                        <div key={index} className="mb-2">
                          <h6 className="py-2">{["focus_group_discuession", "b2b_survey"]?.includes(step1?.engagement_type) ? "Partner" : "Expert"} #{index + 1}</h6>
                          <Row>
                            <Col md={6}>
                              <Tooltip title={["focus_group_discuession", "b2b_survey"]?.includes(step1?.engagement_type) ? "For Registered Partners Only" : "For Registered Experts Only"}>
                                <Form.Item
                                  name={[index, "expert_email"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Expert email is required.",
                                    },
                                  ]}
                                >
                                  {/* <DebounceSelect
                                  type="zoomExpert"
                                  size="large"
                                  value={input.expert_email}
                                  placeholder="Select Expert (if the expert is not in the list then add their email using the Add button)"
                                  optionType="email"
                                  disabled={action === "view"}
                                  extraFunction={(email) => {
                                    form.setFieldValue(
                                      [index, "expert_email"],
                                      email
                                    );
                                    form.setFieldValue(
                                      [index, "expert_name"],
                                      ""
                                    );
                                    handleInputChangeExpert(
                                      email,
                                      "expert_email",
                                      index
                                    )
                                    form.validateFields([[index, "expert_email"]])
                                  }}
                                  onChange={(newValue) => {
                                    handleInputChangeExpert(
                                      newValue,
                                      "expert_email",
                                      index
                                    );
                                    form.setFieldValue(
                                      [index, "expert_name"],
                                      newValue?.title
                                    );
                                  }}
                                  style={{
                                    width: "100%",
                                  }}
                                  creatableSelect={true}
                                /> */}
                                  <CustomSelectExpert
                                    showSearch
                                    labelInValue
                                    disabled={!step1?.project_id}
                                    optionType="email"
                                    filterOption={false}
                                    placeholder={["focus_group_discuession", "b2b_survey"]?.includes(step1?.engagement_type) ? "Select Partner" : "Select Expert"}
                                    fetchExperts={fetchAllExpertList}
                                    debounceFetcher={debounceFetchExpert}
                                    optionsDatasEmail={optionsDatasEmailZoom}
                                    fetching={loadingExpert || isLoadingExperts}
                                    creatableSelect={false}
                                    email={email}
                                    onEmailChange={onEmailChange}
                                    addItem={addItem}
                                    onChange={(newValue) => {
                                      handleInputChangeExpert(
                                        newValue,
                                        "expert_email",
                                        index
                                      );
                                      if (newValue?.title?.name?.trim()?.split(" ")?.length < 2) {
                                        form.setFields([{
                                          name: [index, "expert_name"],
                                          errors: ["Please enter full name."]
                                        }])
                                      }
                                      form.setFieldValue(
                                        [index, "expert_name"],
                                        convertToTitleCase(newValue?.title?.name?.trim())
                                      );
                                      form.setFieldValue(
                                        [index, "expert_hourly_rate_call_cunsltn"],
                                        newValue?.title?.hourly_consulting_rate
                                      );
                                      form.setFieldValue(
                                        [index, "expert_designation"],
                                        convertToTitleCase(newValue?.title?.admin_expert_experience?.current_designation || "")
                                      );
                                      form.setFieldValue(
                                        [index, "expert_company"],
                                        convertToTitleCase(newValue?.title?.admin_expert_experience?.current_compny || "")
                                      );
                                    }}
                                  />
                                </Form.Item>
                              </Tooltip>
                            </Col>
                            <Col md={6}>
                              <Form.Item
                                name={[index, "expert_name"]}
                                rules={[
                                  {
                                    required: true,
                                    validator: validateExpertName,
                                  },
                                ]}
                              >
                                <Input
                                  name="expert_name"
                                  type="text"
                                  placeholder={["focus_group_discuession", "b2b_survey"]?.includes(step1?.engagement_type) ? "Partner Name" : "Expert Name"}
                                  disabled={!step1.project_id}
                                  size="large"
                                  value={input?.expert_name}
                                  onChange={(e) => {
                                    handleInputChangeExpert(
                                      e.target.value,
                                      "expert_name",
                                      index
                                    );
                                  }}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row>
                            {!["file_or_sheet_filling", "focus_group_discuession", "in_person_meeting", "b2b_survey"]?.includes(step1?.engagement_type) && <Col md={6}>
                              <Form.Item
                                name={[index, "country"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Location is required.",
                                  },
                                ]}
                              >
                                <Select
                                  name="country"
                                  size="large"
                                  labelInValue
                                  allowClear
                                  disabled={action === "view" || !step1.project_id}
                                  placeholder="Select Location"
                                  autoComplete="off"
                                  showSearch
                                  value={input.country}
                                  onChange={(value) => {
                                    handleExpertCountryChange(
                                      value?.label,
                                      index
                                    );
                                    form.setFieldValue([index, "timezone"], null)
                                  }}
                                >
                                  {updatedCountries}
                                </Select>
                              </Form.Item>
                            </Col>}
                            {!["file_or_sheet_filling", "focus_group_discuession", "in_person_meeting", "b2b_survey"]?.includes(step1?.engagement_type) && <Col md={6}>
                              <Form.Item
                                name={[index, "timezone"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Timezone is required.",
                                  },
                                ]}
                              >
                                <Select
                                  name="timezone"
                                  size="large"
                                  labelInValue
                                  placeholder="Select Timezone"
                                  autoComplete="off"
                                  allowClear
                                  disabled={action === "view" || !step1.project_id}
                                  showSearch
                                  value={input?.timezone}
                                  filterOption={(input, option) => {
                                    return (
                                      option?.children
                                        ?.join("")
                                        ?.toLowerCase()
                                        ?.indexOf(input?.toLowerCase()) >= 0
                                    );
                                  }}
                                  onChange={(value) => {
                                    handleInputChangeExpertTimezone(
                                      value?.value,
                                      "timezone",
                                      index
                                    );
                                  }}
                                >
                                  {getUpdatedCountriesTimeZone(input?.country)}
                                </Select>
                              </Form.Item>
                            </Col>}
                          </Row>
                          <Row className="align-items-center">
                            <Col md={4}>
                              <Form.Item
                                name={[
                                  index,
                                  "expert_hourly_rate_call_cunsltn",
                                ]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Call rate is required.",
                                  },
                                ]}
                              >
                                <Input
                                  name="expert_hourly_rate_call_cunsltn"
                                  type="number"
                                  disabled={!step1?.project_id}
                                  addonBefore={
                                    <div className="d-flex flex-row">
                                      <Select
                                        style={{
                                          width: 80,
                                        }}
                                        defaultValue="USD"
                                        size="large"
                                        disabled={action === "view" || !step1?.project_id}
                                        value={
                                          input?.expert_hourly_currency || "USD"
                                        } // Use addData?.hourly_currency if it exists, otherwise default to "USD"
                                        onChange={(value) => {
                                          handleInputChangeExpert(
                                            value,
                                            "expert_hourly_currency",
                                            index
                                          );
                                        }}
                                      >
                                        <Select.Option value="USD">
                                          USD
                                        </Select.Option>
                                        <Select.Option value="INR">INR</Select.Option>
                                        <Select.Option value="AED">AED</Select.Option>
                                        <Select.Option value="EUR">
                                          EUR
                                        </Select.Option>
                                        <Select.Option value="JPY">
                                          JPY
                                        </Select.Option>
                                        <Select.Option value="GBP">
                                          GBP
                                        </Select.Option>
                                        <Select.Option value="AUD">
                                          AUD
                                        </Select.Option>
                                        <Select.Option value="CAD">
                                          CAD
                                        </Select.Option>
                                        <Select.Option value="NZD">
                                          NZD
                                        </Select.Option>
                                        <Select.Option value="SGD">
                                          SGD
                                        </Select.Option>
                                        <Select.Option value="HKD">
                                          HKD
                                        </Select.Option>
                                      </Select>
                                    </div>
                                  }
                                  size="large"
                                  placeholder={["focus_group_discuession", "b2b_survey"]?.includes(step1?.engagement_type) ? "Partner Call Rate (Hourly)" : "Expert Call Rate (Hourly)"}
                                  value={input?.expert_hourly_rate_call_cunsltn}
                                  onChange={(e) => {
                                    handleInputChangeExpert(
                                      e.target.value,
                                      "expert_hourly_rate_call_cunsltn",
                                      index
                                    );
                                  }}
                                />
                              </Form.Item>
                            </Col>
                            {!["file_or_sheet_filling", "focus_group_discuession", "in_person_meeting", "b2b_survey"]?.includes(step1?.engagement_type) && <Col md={4}>
                              <Form.Item
                                name={[index, "expert_designation"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Designation is required.",
                                  },
                                ]}
                              >
                                <Input
                                  name="expert_designation"
                                  type="text"
                                  size="large"
                                  disabled={!step1?.project_id}
                                  placeholder="Expert Designation"
                                  value={input?.expert_designation}
                                  onChange={(e) => {
                                    handleInputChangeExpert(
                                      e.target.value,
                                      "expert_designation",
                                      index
                                    );
                                  }}
                                />
                              </Form.Item>
                            </Col>}
                            {!["file_or_sheet_filling", "focus_group_discuession", "in_person_meeting", "b2b_survey"]?.includes(step1?.engagement_type) && <Col md={4}>
                              <Form.Item
                                name={[index, "expert_company"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Company is required.",
                                  },
                                ]}
                              >
                                <Input
                                  name="expert_company"
                                  type="text"
                                  size="large"
                                  disabled={!step1?.project_id}
                                  placeholder="Expert Company"
                                  value={input?.expert_company}
                                  onChange={(e) => {
                                    handleInputChangeExpert(
                                      e.target.value,
                                      "expert_company",
                                      index
                                    );
                                  }}
                                />
                              </Form.Item>
                            </Col>}
                          </Row>
                          <Row>
                            <Col xs={12} md={12}>
                              <Form.Item
                                name={[index, "additional_details"]}
                              >
                                <Input.TextArea
                                  name="additional_details"
                                  type="text"
                                  placeholder="Additional Details"
                                  size="large"
                                  disabled={!step1?.project_id}
                                  value={input.additional_details}
                                  onChange={(e) => {
                                    handleInputChangeExpert(
                                      e.target.value,
                                      "additional_details",
                                      index
                                    );
                                  }}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row className="mb-md-3 justify-content-between">
                            <Col xs={8} md={6}>
                              {input?.in_list && (
                                <Form.Item
                                  name={[index, "opt_out_check"]}
                                  className="mb-0"
                                >
                                  <Checkbox
                                    checked={input.opt_out_check}
                                    name="opt_out_check"
                                    disabled={!step1?.project_id}
                                    onChange={(e) => {
                                      handleInputChangeExpert(
                                        e.target.checked,
                                        "opt_out_check",
                                        index
                                      );
                                    }}
                                  >
                                    <span className="font-weight-bold" style={{
                                      fontWeight: "bold"
                                    }}><strong>DO NOT</strong> {["focus_group_discuession", "b2b_survey"]?.includes(step1?.engagement_type) ? "send pre call email to partner" : "send pre call email to expert"}</span>                                  </Checkbox>
                                </Form.Item>
                              )}
                            </Col>
                            <Col xs={4} md={2}>
                              <div className="text-end">
                                {(index === step3?.length - 1 && index !== 0) && <Button
                                  type="primary"
                                  onClick={() => {
                                    if (step3.length > 1) {
                                      let allData = form.getFieldValue([index]) || {}
                                      form.setFieldsValue({
                                        [index]: {
                                          ...allData,
                                          expert_company: undefined,
                                          expert_designation: undefined,
                                          expert_email: undefined,
                                          expert_hourly_rate_call_cunsltn: undefined,
                                          country: undefined,
                                          timezone: undefined,
                                          abbreviation: undefined,
                                          opt_out_check: undefined,
                                          additional_details: undefined,
                                          expert_name: undefined,
                                        }
                                      });
                                      handleDeleteInputExpert(index)
                                    }
                                  }}
                                  disabled={action === "view"}
                                >
                                  Remove
                                </Button>}
                              </div>
                            </Col>
                          </Row>
                        </div>
                      );
                    })}

                    <div className="d-flex justify-content-center align-items-center">
                      <Button
                        size="middle"
                        className="text-center btn-responsive expertButtonNext"
                        onClick={handleAddInputExpert}
                        disabled={action === "view"}
                      >
                        {["focus_group_discuession", "b2b_survey"]?.includes(step1?.engagement_type) ? "Add Partner" : "Add Expert"}
                      </Button>
                    </div>
                  </div>
                </Form.Item>
                {/* <Col >
                  <Form.Item>
                    <Checkbox
                      checked={isExpertCheckboxChecked}
                      onChange={(e) => {
                        dispatch(setIsExpertCheckboxChecked(e.target.checked))
                      }
                      }
                    >
                      <span>Opt Out the expert for pre-call and post-call emails</span>
                    </Checkbox>
                  </Form.Item>
                </Col> */}
                {/* <Col>
                  <Form.Item>
                    <Checkbox
                      checked={isCheckboxChecked}
                      onChange={(e) =>
                        dispatch(setIsCheckboxChecked(e.target.checked))
                      }
                      disabled={action === "view"}
                    >
                      <span>Get call transcript for internal purpose</span>
                    </Checkbox>
                  </Form.Item>
                </Col> */}

                {isMediatorCheckboxChecked && <Form.Item
                  label={<h5 className="mb-2">Moderator Details</h5>}
                  name="mediator_information"
                  required
                >

                  <div>
                    {mediators?.map((medi, index) => {
                      return (
                        <div key={index} className="mb-3">
                          <Row>
                            <Col md={6}>
                              <Form.Item
                                name={[index, "mediator_email"]}
                                className="mb-md-0"
                                rules={[
                                  {
                                    required: true,
                                    validator: validateEmail,
                                  },
                                ]}
                              >
                                <Input
                                  name="mediator_email"
                                  type="text"
                                  placeholder="Moderator Email"
                                  size="large"
                                  disabled={!step1?.project_id}
                                  value={medi.mediator_email}
                                  onChange={(e) => {
                                    handleMediatorChange(
                                      e.target.value,
                                      "mediator_email",
                                      index
                                    );
                                  }}
                                />


                              </Form.Item>
                            </Col>
                            <Col md={6}>
                              <Form.Item
                                name={[index, "mediator_name"]}
                                className="mb-md-0"
                                rules={[
                                  {
                                    required: true,
                                    validator: validateName,
                                  },
                                ]}
                              >
                                <Input
                                  name="mediator_name"
                                  type="text"
                                  placeholder="Moderator Name"
                                  size="large"
                                  disabled={!step1?.project_id}
                                  value={medi.mediator_name}
                                  onChange={(e) => {
                                    handleMediatorChange(
                                      convertToTitleCase(e.target.value),
                                      "mediator_name",
                                      index
                                    );
                                  }}
                                />
                              </Form.Item>
                            </Col>

                          </Row>
                          <Row className="mt-3">
                            <Col md={5}>
                              <Form.Item
                                name={[index, "mediator_country"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Location is required.",
                                  },
                                ]}
                              >
                                <Select
                                  name="mediator_country"
                                  size="large"
                                  labelInValue
                                  disabled={!step1?.project_id}
                                  allowClear
                                  placeholder="Select Location"
                                  autoComplete="off"
                                  showSearch
                                  value={medi.mediator_country || null}
                                  onChange={(value) => {
                                    handleMediatorCountryChange(
                                      value?.label,
                                      index
                                    );
                                    form.setFieldValue([index, "mediator_timezone"], null)
                                  }}
                                >
                                  {updatedCountries}
                                </Select>
                              </Form.Item>
                            </Col>
                            <Col md={5} >
                              <Form.Item
                                name={[index, "mediator_timezone"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Timezone is required.",
                                  },
                                ]}
                              >
                                <Select
                                  name="mediator_timezone"
                                  size="large"
                                  labelInValue
                                  disabled={["", undefined, "undefined"]?.includes(
                                    medi?.mediator_country
                                  ) || !step1?.project_id}
                                  placeholder="Select Timezone"
                                  autoComplete="off"
                                  allowClear
                                  showSearch
                                  value={medi?.mediator_timezone || null}
                                  filterOption={(input, option) => {
                                    return (
                                      option?.children
                                        ?.join("")
                                        ?.toLowerCase()
                                        ?.indexOf(input?.toLowerCase()) >= 0
                                    );
                                  }}
                                  onChange={(value) => {
                                    handleInputChangeMediatorTimezone(
                                      value,
                                      index
                                    );

                                  }}
                                >
                                  {getUpdatedCountriesTimeZone(medi?.mediator_country)}
                                </Select>
                              </Form.Item>
                            </Col>

                            <Col md={2} className="mt-2">
                              <div className="text-end text-md-start">
                                {(index === mediators?.length - 1 && index !== 0) &&
                                  <Button
                                    type="primary"
                                    onClick={() => {
                                      // form.setFieldsValue({ [index]: undefined });
                                      handleDeleteMediator(index);
                                      let allData = form.getFieldValue([index]) || {}
                                      if (mediators.length > 1) {
                                        form.setFieldsValue({
                                          [index]: {
                                            ...allData,
                                            mediator_email: undefined,
                                            mediator_name: undefined,
                                            mediator_country: undefined,
                                            mediator_timezone: undefined
                                          }
                                        });
                                      }

                                    }}
                                  >
                                    Remove
                                  </Button>}
                              </div>
                            </Col>
                          </Row>
                        </div>
                      );
                    })}

                    <div className="d-flex justify-content-center align-items-center">
                      <Button
                        size="middle"
                        className="text-center btn-responsive expertButtonNext"
                        onClick={handleAddMediator}
                      >
                        Add Moderator
                      </Button>
                    </div>
                  </div>
                </Form.Item>}
                <Col>
                  <Form.Item>
                    <div className="d-flex justify-content-center align-items-center">
                      <Button
                        size="middle"
                        className="text-center btn-responsive expertButtonNext"
                        onClick={() => {
                          dispatch(setIsMediatorCheckboxChecked(!isMediatorCheckboxChecked))
                          dispatch(setIsMediatorCheckboxChecked(!isMediatorCheckboxChecked))
                          let allData = form.getFieldsValue() || {}
                          mediators?.forEach((medi, index) => {
                            form.setFieldsValue({
                              [index]: {
                                ...allData[index],
                                mediator_email: undefined,
                                mediator_name: undefined,
                                mediator_country: undefined,
                                mediator_timezone: undefined
                              }
                            });
                          })
                          dispatch(setMediator([
                            {
                              mediator_email: "",
                              mediator_name: "",
                              mediator_country: "",
                              mediator_timezone: "",

                            },
                          ]))
                        }}
                      >
                        {isMediatorCheckboxChecked ? "Remove All Moderator Details" : "Add Moderator Details"}
                      </Button>

                    </div>
                  </Form.Item>

                </Col>
                <Row className="mb-4">
                  {!["file_or_sheet_filling", "focus_group_discuession", "in_person_meeting", "b2b_survey"]?.includes(step1?.engagement_type) && <Col md={6}>
                    <Checkbox
                      checked={meetingLinkCheckboxChecked}
                      onChange={(e) => {
                        if (e.target.checked) {
                          dispatch(setIsMeetingLinkCheckboxChecked(e.target.checked))
                          dispatch(setIsAutoRecordingCheckboxChecked(false))
                        } else {
                          let allData = form.getFieldsValue() || {}
                          dispatch(setIsAutoRecordingCheckboxChecked(true))
                          dispatch(setIsMeetingLinkCheckboxChecked(false))
                          dispatch(
                            setStep1({
                              ...step1,
                              meeting_link: "",
                              meeting_id: "",
                              meeting_passcode: "",
                            })
                          );

                          form.setFieldsValue({
                            ...allData,
                            meeting_link: "",
                            meeting_id: "",
                            meeting_passcode: "",
                          });
                        }
                      }
                      }
                    >
                      <span>Meeting Link Provided By Client</span>
                    </Checkbox>
                  </Col>}
                  {!["file_or_sheet_filling", "focus_group_discuession", "in_person_meeting", "b2b_survey"]?.includes(step1?.engagement_type) && <Col md={6} className="text-end">
                    {/* <Col md={6} className=""> */}
                    {!meetingLinkCheckboxChecked && <Checkbox
                      checked={autoRecordingCheckboxChecked}
                      onChange={(e) => {
                        if (!e.target.checked) {
                          setOpen(true)
                        } else {
                          dispatch(setIsAutoRecordingCheckboxChecked(e.target.checked))
                        }
                      }
                      }
                    >
                      <span>Record this call</span>
                    </Checkbox>}
                  </Col>}

                </Row>
                {!["file_or_sheet_filling", "focus_group_discuession", "b2b_survey", "micro_consulting_call"]?.includes(step1?.engagement_type) && <Row>
                  <Col md={12}>
                    <Form.Item
                      name={"location_address"}
                      label="Location Address"
                      className="mb-md-0"
                      rules={[
                        {
                          required: true,
                          message: "Location Address is required"
                        },
                      ]}
                    >
                      <Input
                        name="location_address"
                        type="text"
                        placeholder="Location Address"
                        size="large"
                        disabled={!step1?.project_id}
                        value={step1.location_address}
                        onChange={(e) => {
                          dispatch(
                            setStep1({
                              ...step1,
                              location_address: e.target.value
                            })
                          );
                        }}
                      />
                    </Form.Item></Col>
                </Row>}
                {meetingLinkCheckboxChecked &&
                  <Row className="align-items-center mt-3">
                    <Col md={4}>
                      <Form.Item
                        name={"meeting_link"}
                        label="Meeting Link"
                        className="mb-md-0"
                        rules={[
                          {
                            required: true,
                            message: "Meeting Link is required"
                          },
                        ]}
                      >
                        <Input
                          name="meeting_link"
                          type="text"
                          placeholder="Meeting Link"
                          size="large"
                          disabled={!step1?.project_id}
                          value={step1.meeting_link}
                          onChange={(e) => {
                            dispatch(
                              setStep1({
                                ...step1,
                                meeting_link: e.target.value
                              })
                            );
                          }}
                        />
                      </Form.Item></Col>
                    <Col md={4}>
                      <Form.Item
                        name={"meeting_id"}
                        className="mb-md-0"
                        label="Meeting ID (Please enter if applicable)"
                      >
                        <Input
                          name="meeting_id"
                          type="text"
                          placeholder="Meeting Id"
                          size="large"
                          disabled={!step1?.project_id}
                          value={step1.meeting_id}
                          onChange={(e) => {
                            dispatch(
                              setStep1({
                                ...step1,
                                meeting_id: e.target.value
                              })
                            );
                          }}
                        />
                      </Form.Item></Col>
                    <Col md={4}>
                      <Form.Item
                        name={"meeting_passcode"}
                        className="mb-md-0"
                        label="Passcode (Please enter if applicable)"

                      >
                        <Input
                          name="meeting_passcode"
                          type="text"
                          placeholder="Meeting Passcode"
                          size="large"
                          disabled={!step1?.project_id}
                          value={step1.meeting_passcode}
                          onChange={(e) => {
                            dispatch(
                              setStep1({
                                ...step1,
                                meeting_passcode: e.target.value
                              })
                            );
                          }}
                        />
                      </Form.Item></Col>
                  </Row>}
                <Row>
                  <div
                    className="d-flex align-items-center
                  justify-content-center justify-content-lg-end gap-2 mt-3"
                  >
                    {action !== "view" && (
                      <Form.Item shouldUpdate className="text-end">
                        <Button
                          onClick={() => {
                            // form.validateFields()
                            setType("submit");
                          }}
                          disabled={action === "view"}
                          className="btn btn-responsive expertButtonNext"
                          size="large"
                          htmlType="submit"
                        >
                          {loading?.load && loading.type === "submit" && (
                            <Spin
                              size="small"
                              indicator={
                                <LoadingOutlined
                                  style={{
                                    color: "white",
                                    paddingRight: 5,
                                  }}
                                />
                              }
                            />
                          )}{" "}
                          Submit
                        </Button>
                      </Form.Item>
                    )}
                    {action !== "view" && allResult?.approve_count !== 2 && (
                      <Form.Item shouldUpdate className="text-end">
                        <Button
                          onClick={() => {
                            updateDraftMeeting("draft");
                          }}
                          className="btn btn-responsive expertButtonNext"
                          size="large"
                          htmlType="button"
                        >
                          {loading?.load && loading.type === "draft" && (
                            <Spin
                              size="small"
                              indicator={
                                <LoadingOutlined
                                  style={{
                                    color: "white",
                                    paddingRight: 5,
                                  }}
                                />
                              }
                            />
                          )}
                          Save as Draft
                        </Button>
                      </Form.Item>
                    )}
                    {userData?.data?.roleData?.[0]?.role_permission?.[16]
                      ?.draftedZoomMeeting?.publish &&
                      !(
                        userData?.data?.roleData?.[0]?.role_id ===
                        ACCOUNT_AND_FINANCE_ID
                      ) &&
                      ![
                        allResult?.approve_by_user1,
                        allResult?.approve_by_user2,
                      ]?.includes(userData?.data?._id) && (
                        <Form.Item shouldUpdate className="text-end">
                          <Button
                            onClick={() => {
                              setType("approve");
                            }}
                            disabled={action === "view"}
                            className="btn btn-responsive expertButtonNext"
                            size="large"
                            htmlType="submit"
                          >
                            {loading?.load && loading.type === "approve" && (
                              <Spin
                                size="small"
                                indicator={
                                  <LoadingOutlined
                                    style={{
                                      color: "white",
                                      paddingRight: 5,
                                    }}
                                  />
                                }
                              />
                            )}{" "}
                            Approve
                          </Button>
                        </Form.Item>
                      )}
                    {userData?.data?.roleData?.[0]?.role_permission?.[16]
                      ?.draftedZoomMeeting?.publish &&
                      userData?.data?.roleData?.[0]?.role_id ===
                      ACCOUNT_AND_FINANCE_ID &&
                      allResult?.approve_by_user1 &&
                      ![
                        allResult?.approve_by_user1,
                        allResult?.approve_by_user2,
                      ]?.includes(userData?.data?._id) && (
                        <Form.Item shouldUpdate className="text-end">
                          <Button
                            onClick={() => {
                              setType("approve");
                            }}
                            disabled={action === "view"}
                            className="btn btn-responsive expertButtonNext"
                            size="large"
                            htmlType="submit"
                          >
                            {loading?.load && loading.type === "approve" && (
                              <Spin
                                size="small"
                                indicator={
                                  <LoadingOutlined
                                    style={{
                                      color: "white",
                                      paddingRight: 5,
                                    }}
                                  />
                                }
                              />
                            )}
                            Approve
                          </Button>
                        </Form.Item>
                      )}
                  </div>
                </Row>
              </Form>
            </div>
          )}
        </div>
      </div>
      <ConfirmationModal
        open={openConfirm}
        setOpen={() => {
          setOpenConfirm(!openConfirm);
        }}
        okFunction={async () => {
          await updateDraftMeeting("approve");
          setOpenConfirm(false);
        }}
        cancelFunction={() => {
          setOpenConfirm(false);
        }}
        message={"Are you sure?"}
        title={"Approve Meeting"}
      />
      <ConfirmationModal
        open={open}
        setOpen={setOpen}
        message={"Are you sure you want recording turned off for this call?"}
        title={"Turn Off Recording"}
        okFunction={async () => {
          dispatch(setIsAutoRecordingCheckboxChecked(false))
          setOpen(false)
        }}
        cancelFunction={() => {
          setOpen(false)
        }}
        okText={"Yes"}
      />
    </div>
  );
}

export default UpdateMeetingForm;
