import { Modal, Table } from 'antd';
import axios from 'axios';
import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import useSWR from 'swr';

const ProjectExpertListModal = ({
    setIsOpenExpertListModale,
    isOpenExpertListModal
}) => {
    const useQuery = () => {
        return new URLSearchParams(useLocation().search);
    };
    let query = useQuery();
    let project_id = query.get("project_id");
    const [expertList, setExpertList] = useState([])
    const navigate = useNavigate()
    const data = expertList?.map((exp) => ({
        expert_name: exp?.name,
        country: exp?.country,
        company: exp?.company,
        designation: exp?.industry?.[0],
        expert_rate: exp?.score,
        key: exp?._id?.$oid
    }))

    const columns = [

        {
            title: "Expert Name",
            dataIndex: "expert_name",
            key: "name",
            // render: (text, record) => {
            //     const truncatedTitle = record?.name
            //         ? record.name.split(" ").slice(0, 4).join(" ")
            //         : "";

            //     // Add three dots at the end if the title was truncated
            //     const displayTitle =
            //         truncatedTitle !== record?.name
            //             ? truncatedTitle + "..."
            //             : truncatedTitle;
            //     return (
            //         <>
            //             <Tooltip color="#5746EB" title={record?.name} placement="topLeft">
            //                 <h5 className="projectTitle">{displayTitle}</h5>
            //             </Tooltip>
            //             <small>{convertDate(record?.created_at)}</small>
            //         </>
            //     );
            // },
        },
        {
            title: "Country",
            dataIndex: "country",
            key: "country",
            render: (text, record) => (
                <>
                    <p>{record?.country}</p>
                </>
            ),
        },
        {
            title: "Company",
            dataIndex: "company",
            key: "company",
            render: (text, record) => (
                <>
                    <p>{record?.company}</p>
                </>
            ),
        },
        {
            title: "Designation",
            dataIndex: "designation",
            key: "designation",
            render: (text, record) => (
                <>
                    <p>{record?.designation}</p>
                </>
            ),
        },

        {
            title: "Expert Rate",
            dataIndex: "rate",
            key: "name",
            render: (text, record) => {
                return `${record?.expertCurrency || "USD"} ${record?.consulting_rate || ""
                    }`;
            },
        },

        {
            title: "Action",
            dataIndex: "_id",
            key: "action",
            render: (_id, record) => (
                <div className="d-flex flex-row gap-2 g-2">
                    <button
                        className="viewButton btn"
                        onClick={() => {
                            navigate(
                                `/dashboard/addExpert?project_id=${project_id}&expertID=${record?.key}&redirect=team`
                            );
                        }}
                    >
                        Add
                    </button>
                </div>
            ),
        },
    ];
    const getRecommendedExperts = React.useCallback(async () => {
        const formData = new FormData()
        formData.append("id", project_id)
        try {
            const res = await axios.post(`https://dev.py.api.nextyn.com/search`, formData);
            return res
        } catch (error) {
            console.error('error: ', error);
        }
    }, [project_id])

    const { isLoading } = useSWR("recommended_experts", () => getRecommendedExperts(), {
        revalidateOnMount: true,
        revalidateOnFocus: false,
        shouldRetryOnError: false,
        onSuccess: (res) => {
            if (res?.data) {
                let parseData = JSON.parse(res?.data)
                setExpertList(parseData)
            }

        }
    })
    return (
        <Modal
            open={isOpenExpertListModal}
            footer={false}
            onCancel={() => setIsOpenExpertListModale(false)}
            title="Recommended Experts"
            width={1000}
        >
            <div>
                {/* <List
                    itemLayout="horizontal"
                    dataSource={data}
                    renderItem={(item, index) => (
                        <List.Item >
                            <List.Item.Meta
                                avatar={<Avatar src={`https://api.dicebear.com/7.x/miniavs/svg?seed=${index}`} />}
                                title={<a href="https://ant.design">{item.title}</a>}
                                description="Ant Design, a design language for background applications, is refined by Ant UED Team"

                            />
                            <button
                                className="viewButton btn"
                                onClick={() => {
                                    navigate(
                                        `/dashboard/addExpert?project_id=${"ddasd"}&expertID=${"asdada"}&redirect=team`
                                    );
                                }}
                            >
                                Add
                            </button>
                        </List.Item>
                    )}
                /> */}
                <Table
                    dataSource={data}
                    columns={columns}
                    pagination={false}
                    loading={isLoading}
                    size='middle'
                />
            </div>
        </Modal>
    )
}

export default ProjectExpertListModal