const ENV = {
  // BASE_URL: "http://192.168.0.255:8081",

  // Production
  // BASE_URL: "https://api.platform.nextyn.com",
  // FRONTEND_BASE_URL: "https://platform.nextyn.com",

  // development
  BASE_URL: "https://dev.api.platform.nextyn.com",
  FRONTEND_BASE_URL: "https://dev.platform.nextyn.com",

  // Staging
  // BASE_URL: "https://staging.api.platform.nextyn.com",
  // FRONTEND_BASE_URL: "https://staging.platform.nextyn.com",

  // localhost
  // BASE_URL: "http://localhost:3200",
  // FRONTEND_BASE_URL: "http://localhost:3001",

  REACT_APP_SITE_KEY: "6LdqiMYpAAAAADARFk-fvT9J2q02N3h4LgZ8Sigz",
  REACT_APP_SECRET_KEY: "6LdqiMYpAAAAAPDazRhadqaOoyj0IIqbkgL-ze8e",
  ZERO_BOUNCE_API_KEY_OLD: "c6861eb6280e4b2790237ea890e69a26",
  ZERO_BOUNCE_API_KEY: "d1711a0232c64b478bfd48af380135cb"
};

export default ENV;
