import {
  LockOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Alert, Button, Form, Input, } from "antd";
import React, { useState, useContext } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { Spinner } from "reactstrap";
import { ApiContext } from '../../pages/NotificationApi';
import { useDispatch } from "react-redux";
import { authActions, email_login } from "../../Utils/auth.action";
import { useSelector } from "react-redux";


function LoginForm() {
  const [form] = Form.useForm();
  const [userData, setValue] = useState({
    password: "",
    email: "",
  });
  const { error, loading } = useSelector((state) => state.auth)
  const dispatch = useDispatch()
  const { getData } = useContext(ApiContext);

  const emailRules = [
    {
      type: "email",
      message: "Please enter a valid email address!",
    },
    {
      required: true,
      message: "Email is required!",
    },
  ];


  const handleInputs = (e) => {
    const key = e.target.name;
    let value = e.target.value;

    // if (key === "email") {
    //   value = value?.trim()?.toLowerCase();
    //   form.setFieldValue(key, value)
    //   form.validateFields()
    // }

    setValue({ ...userData, [key]: value });
  };

  // const loginUser = async (e) => {
  //   e.preventDefault();
  //   console.log("here");
  //   setIsAuth(false);
  //   setSpinner(true);
  //   try {
  //     const res = await fetch(`${API_PATH.ADMINLOGIN}`, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify(userData),
  //     });

  //     const result = await res.json();
  //     console.log('result: ', result);
  //     if (result) {
  //       setSpinner(false);
  //       if (result?.status === 200 || result?.status === true) {
  //         console.log(result.token, "result.token");
  //         localStorage.setItem("userData", JSON.stringify(result));
  //         if (result.token) {
  //           console.log("inside the token result");
  //           setIsAuth(true);
  //           localStorage.setItem("isAuth", true);
  //           localStorage.setItem(
  //             "ChangePassword",
  //             JSON.stringify(userData.password)
  //           );
  //           localStorage.setItem('lastActiveTime', new Date().toString()); // Set the last active time when the user logs in
  //           localStorage.setItem('lastLoginTime', new Date().getTime().toString()); // Set the last login time
  //           getData(result);
  //           history("/dashboard");

  //           notification.success({
  //             message: "Login Successful",
  //             icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
  //             style: {
  //               backgroundColor: "#2ecc71",
  //               color: "#fff !important",
  //               border: "1px solid #52c41a",
  //             },
  //             duration: 5,
  //             placement: "topRight",
  //           });
  //           // setTimeout(() => {
  //           //   if (JSON.parse(localStorage.getItem("userData"))) {


  //           //   }
  //           // }, 200);

  //         }
  //       } else {
  //         setSpinner(false);
  //         setError("Invalid email or password");
  //       }
  //     } else {
  //       notification.error({
  //         message: "Error: Something went wrong server error",
  //         icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
  //         style: {
  //           backgroundColor: "#e74c3c",
  //           color: "#fff",
  //           border: "1px solid #c0392b",
  //         },
  //         duration: 5,
  //         placement: "topRight",
  //       });
  //     }
  //   } catch (error) {
  //     console.error(error);
  //     setError("An error occurred while logging in");
  //   }
  // };

  const loginUser = async () => {
    const { email, password } = userData
    localStorage.setItem('auth', email);
    localStorage.setItem("ChangePassword", JSON.stringify(password));
    dispatch(email_login({ email, password }));
    //! getData() NOTIFICATION API COMMENTED FOR NOW
  }


  const something = (event) => {
    if (event.keyCode === 13) {
      // loginUser(event);
      const { email, password } = userData
      localStorage.setItem('auth', email);
      localStorage.setItem("ChangePassword", JSON.stringify(password));
      dispatch(email_login({ email, password }));
      //! getData() NOTIFICATION API COMMENTED FOR NOW
    }
  };


  return (
    <>
      {error && <Alert message={error} type="error" />}
      <Form form={form} layout="vertical" autoComplete="off" onFinish={() => {
        loginUser()
      }}>
        <Form.Item name="email" label="Email Address" rules={emailRules}>
          <Input
            prefix={<UserOutlined className="site-form-item-icon" />}
            type="text"
            name="email"
            value={userData?.email}
            onChange={handleInputs}
            autoComplete="nope"
            placeholder="Enter Your Email Address"
            size="large"
          />
        </Form.Item>

        <Form.Item
          name="password"
          label="Password"
          rules={[
            {
              required: true,
              message: "Please input your password!",
            },
          ]}
        >
          <Input.Password
            prefix={<LockOutlined className="site-form-item-icon" />}
            name="password"
            onChange={handleInputs}
            value={userData?.password}
            id="password-field"
            size="large"
            autoComplete="nope"
            onKeyDown={(e) => something(e)}
            placeholder="Password"
          />
        </Form.Item>

        <div className="form-group">
          <Form.Item shouldUpdate>
            {() => (
              <Button
                // onClick={loginUser}
                className="btn login-btn"
                type="primary"
                htmlType="submit"
                disabled={
                  loading ||
                  !form.isFieldsTouched(true) ||
                  !!form.getFieldsError().filter(({ errors }) => errors.length)
                    .length
                }
              >
                {loading ? (
                  <>
                    <Spinner
                      size="sm"
                      style={{
                        width: "1rem",
                        height: "1rem",
                        marginRight: "5px",
                      }}
                    />
                    Login
                  </>
                ) : (
                  "Login"
                )}
              </Button>
            )}
          </Form.Item>
        </div>
      </Form>

      <div className="form-group">
        <p className="text-center">
          <NavLink onClick={() => {
            dispatch(authActions.resetState());
          }} to="/forgot-password" className="register-btn px-2">
            Forgot Password?
          </NavLink>
        </p>
      </div>
    </>
  );
}
export default LoginForm;
