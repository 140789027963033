import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
// import MobileMenu from "../MobileMenu";
import { Col, Modal, Row } from "react-bootstrap";
import AddMemberModal from "../../components/AddMemberModal";
import Sidebar from "../../components/Sidebar";
import { BsChevronLeft } from "../../Utils/Icons";
import NextynLi from "./NextynLi";
import ProjectScopeLeftSection from "./ProjectScopeLeftSection";
import ScopeOverview from "./ScopeOverview";
import ScopeProjectInfomation from "./ScopeProjectInfomation";
import ScopeTeamsli from "./ScopeTeamsli";
import { createSearchParams, useLocation } from "react-router-dom";
import API_PATH from "../../Constants/api-path";
import { MdOutlineClose } from "react-icons/md";
import axios from "axios";
import { Dropdown, Menu, Space, notification, Spin } from "antd";
import {
  EllipsisOutlined,
  CheckCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import AddExpertSvg from "../../assests/images/addExpert.svg";
import { BsPlusLg } from "react-icons/bs";

function ProjectsDetails() {
  const navigate = useNavigate();
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  let query = useQuery();
  let project_id = query.get("project_id");

  const [Loading, setLoading] = useState(true);
  const userData = JSON.parse(localStorage.getItem("userData"));
  const [projectDetails, setProjectDetails] = useState();
  const [clientTeam, setClientTeam] = useState({});
  const [nextynTeam, setNextynTeam] = useState();
  const [projectinfo, setProjectinfo] = useState({});
  const [show, setShow] = useState(false);
  const [open, setOpen] = useState(false);
  const [method, setMethod] = useState("");

  const headers = React.useMemo(
    () => ({
      "Content-Type": "application/json",
      Authorization: `Bearer ${userData?.token}`,
    }),
    [userData?.token]
  );

  const getProjectDetails = React.useCallback(async () => {
    setLoading(true);
    axios
      .get(`${API_PATH.PRJECT_DETAILS}/${project_id}`, { headers })
      .then((res) => {
        setProjectDetails(res?.data?.data);
        setClientTeam(res?.data?.clientTeam);
        setNextynTeam(res?.data?.nextynTeam?.filter((team) => team?._id) || []);
        setProjectinfo(res?.data?.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.messagex);
      });
  }, [headers, project_id]);

  useEffect(() => {
    getProjectDetails();
  }, [getProjectDetails]);

  console.log(nextynTeam > 0 ? "data found" : "data not found", "nextynTeam");

  const menu = (
    <Menu>
      <Menu.Item
        onClick={() => openModal("accept")}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Space align="center" size={8}>
          <span>Accept</span>
        </Space>
      </Menu.Item>
      <Menu.Item
        onClick={() => openModal("decline")}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Space align="center" size={8}>
          <span>Decline</span>
        </Space>
      </Menu.Item>
    </Menu>
  );

  const menuTwo = (
    <Menu>
      {/* {projectDetails?.status !== 2 && <Menu.Item
        onClick={() => openModal("close")}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Space align="center" size={8}>

          <span>Close Project</span>
        </Space>
      </Menu.Item>} */}
      <Menu.Item
        onClick={() => openModal("complete")}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Space align="center" size={8}>
          <span>Mark As Complete</span>
        </Space>
      </Menu.Item>
    </Menu>
  );

  function openModal(item) {
    setShow(true);
    setMethod(item);
  }

  const handleClose = () => setShow(false);

  const AddTeamProject = async (status) => {
    setLoading(true);
    console.log(status, "status");

    try {
      const res = await fetch(`${API_PATH?.PROJECT_DETAIL_STATUS}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userData?.token}`,
        },
        body: JSON.stringify({
          project_id: project_id,
          status: status,
        }),
      });

      const result = await res?.json();
      if (result) {
        if (result && (res.status === 200 || res.status === true)) {
          setShow(false);
          // GetLiveProjectList();
          // GetCompleteProjectList();
          if (status === 1) {
            notification.success({
              message: "Project has been accepted",
              icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
              style: {
                backgroundColor: "#2ecc71",
                color: "#fff !important",
                border: "1px solid #52c41a",
              },
              duration: 5,
              placement: "topRight",
            });
          } else if (status === 3) {
            notification.success({
              message: "Project has been completed",
              icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
              style: {
                backgroundColor: "#2ecc71",
                color: "#fff !important",
                border: "1px solid #52c41a",
              },
              duration: 5,
              placement: "topRight",
            });
          } else {
            notification.success({
              message: "Project has been closed",
              icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
              style: {
                backgroundColor: "#2ecc71",
                color: "#fff !important",
                border: "1px solid #52c41a",
              },
              duration: 5,
              placement: "topRight",
            });
          }
        }
      }
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
      notification.error({
        message: "Error: Something went wrong server error",
        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
        style: {
          backgroundColor: "#e74c3c",
          color: "#fff",
          border: "1px solid #c0392b",
        },
        duration: 5,
        placement: "topRight",
      });
    }

    setLoading(false);
  };

  useEffect(() => {
    document.body.style.overflow = "";
    return () => {
      document.body.style.overflow = "";
    };
  }, []);

  console.log("nextynTeam", nextynTeam);
  console.log("projectinfo", projectinfo);

  // console.log("projectDetails", projectDetails);
  console.log("clientTeam", clientTeam);

  const handleIconClick = () => {
    navigate(-1);
  };

  document.title = "Nextyn | Projects Details";

  if (projectDetails?.status === false) {
    alert("The action you have requested is not allowed.");
    window.location = "/projects";
    return;
  }
  if (Loading) {
    return (
      <>
        <div
          className="d-flex justify-content-center align-items-center text-center"
          style={{ minHeight: "100vh" }}
        >
          <Spin size="large" />
        </div>
      </>
    );
  } else
    return (
      <>
        {/* <MobileMenu /> */}
        <div className="page-wrapper chiller-theme toggled">
          <div className="main-sec d-lg-flex">
            <div className="sidebar-menu" id="navAccordion">
              <Sidebar />
            </div>
          </div>
          <main className="body-total content-wrapper float-start w-100">
            <div>
              {/* <div className="d-block d-lg-block">
                <div className="top-bar-menu float-start">
                  <TopMenu2 />
                </div>
              </div> */}

              <div className="bk-div float-start w-100">
                <div className="body-expart-div request-page projects-scope">
                  <div className="d-md-flex top-ps-seection justify-content-between align-items-center mb-3">
                    <h4
                      className="d-flex align-items-center"
                      onClick={handleIconClick}
                      style={{ cursor: "pointer" }}
                    >
                      <BsChevronLeft className="me-2 search-btn-back" />
                      Project Scope
                    </h4>

                    <div className="d-flex justify-content-center align-items-center">
                      <ul
                        className="nav nav-pills mb-3 mt-4"
                        id="pills-tab"
                        role="tablist"
                      >
                        <li className="nav-item" role="presentation">
                          <NavLink
                            to={{
                              pathname: "/dashboard/project_details",
                              search: `?${createSearchParams({ project_id })}`,
                            }}
                            className="nav-link"
                          >
                            Overview
                          </NavLink>
                        </li>
                        <li className="nav-item" role="presentation">
                          <NavLink
                            to={{
                              pathname: "/dashboard/chats",
                              search: `?${createSearchParams({ project_id })}`,
                            }}
                            className="nav-link"
                          >
                            Messages
                          </NavLink>
                        </li>
                        <li className="nav-item" role="presentation">
                          <NavLink
                            to={{
                              pathname: "/dashboard/expert_list",
                              search: `?${createSearchParams({ project_id })}`,
                            }}
                            className="nav-link"
                          >
                            Experts
                          </NavLink>
                        </li>
                      </ul>
                    </div>

                    <div className="d-flex justify-content-end align-items-center">
                      {/* { */}
                      {/* // projectDetails.status === 0 ? (
                        //   <>
                        //     <Dropdown overlay={menu} trigger={['click']}>
                        //       <a onClick={(e) => e.preventDefault()}>
                        //         <Space>
                        //           <EllipsisOutlined style={{ fontSize: "24px", cursor: "pointer" }} />

                        //         </Space>
                        //       </a>
                        //     </Dropdown>
                        //   </>
                        // ) :  */}
                      {/* projectDetails.status !== 0 && projectDetails?.status !== 3 && projectDetails?.status !== 2 && (
                          <>
                            <Dropdown overlay={menuTwo} trigger={['click']}>
                              <a onClick={(e) => e.preventDefault()}>
                                <Space>
                                  <EllipsisOutlined style={{ fontSize: "24px", cursor: "pointer" }} />
                                </Space>
                              </a>
                            </Dropdown>
                          </>
                        )} */}
                    </div>
                  </div>

                  {/* <span className="sp-hr w-100" /> */}

                  <div className="request-body-sec projects-details-page">
                    <div className="d-md-flex justify-content-between">
                      <div className="left-section-divs">
                        <ProjectScopeLeftSection datas={projectDetails} />
                      </div>

                      <div className="right-section-div">
                        <div className="top-right-projects mt-4 mt-md-0">
                          <h2> Project Overview </h2>
                          <ScopeOverview datas={projectDetails} />
                        </div>

                        <div
                          className="middle-right"
                          style={{ marginTop: "14px" }}
                        >
                          <h2 className="my-0"> Project Information </h2>
                          <ScopeProjectInfomation
                            datas={projectinfo}
                            projectDetails={projectDetails}
                          />
                        </div>
                        {clientTeam ? (
                          <>
                            <div
                              className="client-temas-div"
                              style={{ marginTop: "14px" }}
                            >
                              <div className="d-flex justify-content-between">
                                <h2 className="my-0"> Client </h2>
                              </div>
                              <ScopeTeamsli
                                datas={clientTeam}
                                id={project_id}
                              />
                            </div>
                          </>
                        ) : null}

                        <div
                          className="client-temas-div"
                          style={{ marginTop: "14px" }}
                        >
                          {Array.isArray(nextynTeam) &&
                          nextynTeam?.filter(
                            (item) => item?.name !== userData?.data?.name
                          )?.length >= 1 ? (
                            <>
                              <div className="d-flex justify-content-between">
                                <h2 className="my-0"> Nextyn </h2>
                                <button
                                  onClick={() => {
                                    setOpen(true);
                                  }}
                                  variant="outlined"
                                  className="btn add-pls"
                                >
                                  {" "}
                                  <BsPlusLg /> Add{" "}
                                </button>
                              </div>

                              <NextynLi
                                inviteTeamDashboard={nextynTeam}
                                project_id={project_id}
                                getProjectDetails={getProjectDetails}
                                projectDetails={projectDetails}
                              />
                            </>
                          ) : (
                            <>
                              <Row className="d-flex justify-content-center align-center text-center">
                                <Col>
                                  <img
                                    src={AddExpertSvg}
                                    width={"50%"}
                                    alt="No records available"
                                  />
                                  <p
                                    style={{ fontSize: "14px" }}
                                    className="mb-3"
                                  >
                                    Click on the button below to add an expert
                                    team member in the project.
                                  </p>
                                  <button
                                    onClick={() => {
                                      setOpen(true);
                                    }}
                                    variant="outlined"
                                    className="btn add-pls"
                                  >
                                    {" "}
                                    <BsPlusLg /> Add{" "}
                                  </button>
                                  {/* <AddMemberModal ID={project_id} /> */}
                                </Col>
                              </Row>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
        <AddMemberModal
          ID={project_id}
          getProjectDetails={getProjectDetails}
          open={open}
          setOpen={setOpen}
        />

        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          centered
          size={"lg"}
          scrollable={true}
          dialogClassName={"bootStrapModal"}
          contentClassName={"bootStrapModalContent"}
        >
          <Modal.Body className="position-relative">
            <MdOutlineClose
              className="position-absolute"
              style={{ right: "15px", top: "15px", fontSize: "25px" }}
              onClick={() => setShow(false)}
            />
            <div className="text-left">
              <h4 className="modalHeader">
                {method === "accept"
                  ? "Accept project"
                  : method === "decline"
                  ? "Decline project"
                  : method === "close"
                  ? "Close project"
                  : "Mark as complete"}
              </h4>
            </div>
            <div>
              {method === "accept"
                ? "Please note, this action cannot be undone. Are you sure you want to mark this project as accept ?"
                : method === "decline"
                ? "Please note, this action cannot be undone. Are you sure you want to mark this project as decline ?"
                : method === "close"
                ? "Please note, this action cannot be undone. Are you sure you want to mark this project as closed ?"
                : "Please note, this action cannot be undone. Are you sure you want to mark this project as complete ?"}
            </div>

            {method === "accept" && (
              <div className="d-flex justify-content-end mt-3">
                <button
                  className="secondaryBttn btn"
                  onClick={() => setShow(false)}
                >
                  Close
                </button>
                <button
                  className="viewButton btn ms-2"
                  onClick={() => AddTeamProject(1)}
                >
                  Submit
                </button>
              </div>
            )}

            {method === "decline" && (
              <div className="d-flex justify-content-end mt-3">
                <button
                  className="secondaryBttn btn"
                  onClick={() => setShow(false)}
                >
                  Close
                </button>
                <button
                  className="viewButton btn ms-2"
                  onClick={() => AddTeamProject(2)}
                >
                  Submit
                </button>
              </div>
            )}

            {method === "close" && (
              <div className="d-flex justify-content-end mt-3">
                <button
                  className="secondaryBttn btn"
                  onClick={() => setShow(false)}
                >
                  Close
                </button>
                <button
                  className="viewButton btn ms-2"
                  onClick={() => AddTeamProject(2)}
                >
                  Submit
                </button>
              </div>
            )}

            {method === "complete" && (
              <div className="d-flex justify-content-end mt-3">
                <button
                  className="secondaryBttn btn"
                  onClick={() => setShow(false)}
                >
                  Close
                </button>
                <button
                  className="viewButton btn ms-2"
                  onClick={() => AddTeamProject(3)}
                >
                  Submit
                </button>
              </div>
            )}
          </Modal.Body>
        </Modal>
      </>
    );
}

export default ProjectsDetails;
