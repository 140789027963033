import React, { useState } from 'react';
import { Divider, Modal } from 'antd';
const ConfirmationModal = ({ message, title, open, setOpen, okFunction, cancelFunction, okText = "Yes" }) => {
    const [confirmLoading, setConfirmLoading] = useState(false);

    const handleOk = () => {
        setConfirmLoading(true);
        setTimeout(async () => {
            await okFunction()
            setConfirmLoading(false);
        }, 1000);
    };
    const handleCancel = () => {
        cancelFunction()
        setOpen(false);
    };
    return (
        <>
            <Modal
                centered
                title={<div>
                    {title}
                    <Divider className="m-2" />
                </div>}
                open={open}
                onOk={handleOk}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
                okText={okText}
            >
                {/* <Divider style={{ marginTop: "0px" }} /> */}
                <p>{message}</p>
                {/* <Divider style={{ marginBottom: "0px" }} /> */}
            </Modal>
        </>
    );
};
export default ConfirmationModal;