import axios from "axios";
import $ from "jquery";
import queryString from "query-string";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Pagination1 from "react-js-pagination";
import { useNavigate, useSearchParams } from "react-router-dom";
import "../../App.css";
import API_PATH from "../../Constants/api-path";
import "../../assests/css/custom.css";
import TopMenu1 from "../../components/TopMenu1";
import "./Dashboard.css";
import SelectionOption from "./SelectionOption";
import TopIndustry from "./TopIndustry";
import { Pagination, Tag, Tooltip } from "antd";
import { Spin } from "antd";
import { getQueryParams, toQueryString } from "../../Utils/util";

function FindConsultant() {
  let queryParams = getQueryParams(window.location.search);
  const [loading, setLoading] = useState(false);
  const [loadingOption, setLoadingOption] = useState(false);
  const [experts, setExperts] = useState();
  const userData = JSON.parse(localStorage.getItem("userData"));
  const [filter, setFilter] = useState(false);
  const [currentPage, setCurrentPage] = useState(
    ![undefined, "undefined"].includes(queryParams?.page)
      ? parseInt(queryParams.page)
      : 1
  );
  const [allDatas, setAlldatas] = useState(0);
  const getFilterCount = useCallback(() => {
    let count = 0;
    Object.entries(queryParams || {})?.forEach(([key, value]) => {
      if (!["page", "limit", "key", "expert_type", ""].includes(key)) {
        count = count + value?.length;
      }
    });
    return count;
  }, [queryParams]);
  const filterRef = useRef({
    count: getFilterCount() || 0,
    search: false,
  });
  const [current_company, setCompany] = useState(
    ![undefined, "undefined", ""].includes(queryParams?.current_company)
      ? queryParams?.current_company
      : []
  );
  const [email, setEmail] = useState(
    ![undefined, "undefined", ""].includes(queryParams?.email)
      ? queryParams?.email
      : []
  );
  const [industry, setIndustry] = useState(
    ![undefined, "undefined", ""].includes(queryParams?.industry)
      ? queryParams?.industry
      : []
  );
  const [country, setCountry] = useState(
    ![undefined, "undefined", ""].includes(queryParams?.country)
      ? queryParams?.country
      : []
  );
  const [designation, setDesignation] = useState(
    ![undefined, "undefined", ""].includes(queryParams?.designation)
      ? queryParams?.designation
      : []
  );
  const [searchRgx, setSearchRgx] = useState(
    ![undefined, "undefined", ""].includes(queryParams?.key)
      ? queryParams?.key
      : []
  );

  const [previous_company, setPreviousCompany] = useState(
    ![undefined, "undefined", ""].includes(queryParams?.previous_company)
      ? queryParams?.previous_company
      : []
  );
  const [experties, setExperties] = useState(
    ![undefined, "undefined", ""].includes(queryParams?.tags)
      ? queryParams?.tags
      : []
  );
  const [allEmail, setAllEmail] = useState([]);
  const [allGeog, setAllGeog] = useState([]);
  const [allIndustry, setAllIndustry] = useState([]);
  const [allCompany, setAllCompany] = useState([]);
  const [allPreviousCompany, setAllPreviousCompany] = useState([]);
  const [allDesig, setAllDesig] = useState([]);
  const [allExperties, setAllExperties] = useState([]);
  const [wordEntered, setWordEntered] = useState("");
  const [searchParams] = useSearchParams();

  const filterValues = useMemo(() => {
    let applied = [];
    searchParams?.forEach((value, key) => {
      if (!["key", "page", "limit", "expert_type"].includes(key)) {
        if (value !== "") {
          applied.push(value);
        }
      }
    });
    if (applied?.length > 0) {
      return true;
    } else {
      return false;
    }
  }, [searchParams]);
  const stateListFilter = {
    allEmail,
    allGeog,
    allIndustry,
    allCompany,
    allPreviousCompany,
    allDesig,
    allExperties,
  };
  const stateFunctionListFilter = {
    setAllEmail,
    setAllGeog,
    setAllIndustry,
    setAllCompany,
    setAllPreviousCompany,
    setAllDesig,
    setAllExperties,
  };

  const queryParamater = React.useMemo(
    () => ({
      email: email ? email : "",
      industry: industry ? industry : "",
      country: country ? country : "",
      designation: designation ? designation : "",
      key: searchRgx ? searchRgx : "",
      tags: experties ? experties : "",
      current_company: current_company ? current_company : "",
      previous_company: previous_company ? previous_company : "",
      page: currentPage ? currentPage : "1",
      limit: 10,
    }),
    [
      currentPage,
      current_company,
      designation,
      email,
      experties,
      country,
      industry,
      previous_company,
      searchRgx,
    ]
  );

  const history = useNavigate();
  const callOnce = useRef(false);
  const callFilter = useRef(false);

  useEffect(() => {
    document.body.style.overflow = "";
    return () => {
      document.body.style.overflow = "";
    };
  }, []);

  const handlePageChange = async (pageNumber) => {
    setCurrentPage(pageNumber);
    await getExpertsOptions();
    await getExperts(false, false, pageNumber);
  };

  $(window).scroll(function () {
    var scroll = $(window).scrollTop();
    if (scroll > 0) {
      $("#header").addClass("active");
    } else {
      $("#header").removeClass("active");
    }
  });
  function filterDuplicates(array) {
    return Array.from(new Set(array));
  }

  const getExperts = React.useCallback(
    async (clearAll = false, clearSearch = false, page_no = null) => {
      setLoading(true);
      let params_obj = {};
      if (clearAll) {
        params_obj = {
          email: "",
          industry: "",
          country: "",
          designation: "",
          key: clearSearch ? "" : searchRgx,
          tags: "",
          current_company: "",
          previous_company: "",
          page: "1",
          limit: 10,
        };
      } else {
        if (page_no !== null) {
          params_obj = { ...queryParamater, page: page_no };
        } else {
          params_obj = { ...queryParamater, page: 1 };
        }
      }
      let new_params = new URLSearchParams([
        ...Object.entries({ ...params_obj }),
      ]);
      let paramsforQuery = new URLSearchParams([
        ...Object.entries({
          ...toQueryString(params_obj),
        }),
      ]);
      history({
        pathname: `/dashboard/consultant_search`,
        search: `expert_type=consultant&${paramsforQuery}`,
        // search: `expert_type=consultant&${new_params?.toString()}`,
      });
      await axios
        .get(
          `${API_PATH.EXPERT_LIST
          // }?expert_type=consultant&${new_params.toString()}`,
          }?expert_type=consultant&${paramsforQuery}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userData?.token}`,
            },
          }
        )
        .then((res) => {
          setExperts([...res.data.data]);
          setAlldatas(res.data.countData);
          if (callOnce.current) {
            setAllCompany(filterDuplicates(res?.data?.company));
            setAllPreviousCompany(filterDuplicates(res?.data?.previousCompany));
            setAllEmail(filterDuplicates(res?.data?.email));
            setAllGeog(filterDuplicates(res?.data?.country));
            setAllIndustry(filterDuplicates(res?.data?.industry));
            setAllDesig(filterDuplicates(res?.data?.designationNew));
            setAllExperties(filterDuplicates(res?.data?.tags));
          }
          setLoading(false);
          return true;
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
          return false;
        });
    },
    [history, queryParamater, searchRgx, userData?.token]
  );
  const getExpertsOptions = React.useCallback(() => {
    const queryParamater = {
      email: email ? email : "",
      industry: industry ? industry : "",
      country: country ? country : "",
      designation: designation ? designation : "",
      tags: experties ? experties : "",
      current_company: current_company ? current_company : "",
      previous_company: previous_company ? previous_company : "",
      key: searchRgx ? searchRgx : "",
      expert_type: "consultant",
    };
    // const queryParamater = {
    //   email: emailOption ? emailOption : email ? email : "",
    //   industry: industriesOption ? industriesOption : industry ? industry : "",
    //   country: geogOption ? geogOption : country ? country : "",
    //   designation: desigOption ? desigOption : designation ? designation : "",
    //   tags: expertiesOption ? expertiesOption : experties ? experties : "",
    //   current_company: companyOption ? companyOption : current_company ? current_company : "",
    //   previous_company: previousCompanyOption ? previousCompanyOption : previous_company ? previous_company : "",
    //   key: searchRgx ? searchRgx : "",
    //   expert_type: expert_type === "consultant" ? expert_type : "",
    // };
    setLoadingOption(true);
    // let new_params = new URLSearchParams([
    //   ...Object.entries({ ...queryParamater }),
    // ]);
    let paramsforQuery = new URLSearchParams([
      ...Object.entries({
        ...toQueryString({ ...queryParamater }),
      }),
    ]);
    axios
      // .get(`${API_PATH.EXPERT_OPTION}?${new_params.toString()}`, {
      .get(`${API_PATH.EXPERT_OPTION}?${paramsforQuery.toString()}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userData?.token}`,
        },
      })
      .then((res) => {
        setAllCompany(filterDuplicates(res?.data?.company));
        setAllPreviousCompany(filterDuplicates(res?.data?.previousCompany));
        setAllEmail(filterDuplicates(res?.data?.email));
        setAllGeog(filterDuplicates(res?.data?.country));
        setAllIndustry(filterDuplicates(res?.data?.industry));
        setAllDesig(filterDuplicates(res?.data?.designationNew));
        setAllExperties(filterDuplicates(res?.data?.tags));
        setLoadingOption(false); // Move setLoading to false here to trigger a re-render after data is fetched
      })
      .catch((error) => {
        console.error(error);
        setLoadingOption(false);
      });
    setLoadingOption(false);
  }, [
    current_company,
    designation,
    email,
    experties,
    country,
    industry,
    previous_company,
    searchRgx,
    setAllCompany,
    setAllDesig,
    setAllEmail,
    setAllExperties,
    setAllGeog,
    setAllIndustry,
    setAllPreviousCompany,
    userData?.token,
  ]);

  const onlyFirstTimeAndSearch = useRef(false);

  useEffect(() => {
    if (!onlyFirstTimeAndSearch.current) {
      onlyFirstTimeAndSearch.current = true;
      let queryParams = queryString.parse(window.location.search);
      let handler;
      if (queryParams) {
        handler = setTimeout(async () => {
          getExperts(false, false, queryParams?.page);
        }, 1000);
      } else {
        getExperts();
      }
      return () => {
        clearTimeout(handler);
      };
    }
  }, [getExperts]);

  // function capitalizeFirstLetter(string) {
  //     return string.toLowerCase();
  //     // return newString.charAt(0).toUpperCase() + newString.slice(1);
  // }
  // function capitalizeFirstLetter(string) {
  //     return string.toLowerCase();
  //     // return newString.charAt(0).toUpperCase() + newString.slice(1);
  // }

  // useEffect(() => {
  //   let queryParams = queryString.parse(window.location.search);
  //   if (queryParams.ctPage) {
  //     console.log(queryParams)
  //     queryParams.ctPage = atob(queryParams.ctPage);
  //     if (!isNaN(parseInt(queryParams.ctPage))) {
  //       setCompany(queryParams.company);
  //       setpCompany(queryParams.previousCompany);
  //       setIndustry(queryParams.industry);
  //       setExpertise(queryParams.expertise);
  //       setCountry(queryParams.country);
  //       setSearchRgx(queryParams.search);
  //       setCurrentPage(parseInt(queryParams.ctPage));
  //       getExpertss(queryParams.ctPage);
  //       Replacepagechange(queryParams.ctPage,queryParams.country,queryParams.company,queryParams.previousCompany,queryParams.industry,queryParams.expertise,queryParams.search)
  //     }
  //   } else {
  //     setCurrentPage(1);
  //     getExpertss(1);
  //     Replacepagechange(1,country, company,previousCompany, tags, industry, searchRgx);
  //   }
  //   setLoading(false);
  //   setTimeout(() => {
  //     setLoading(true);
  //   }, 3000);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [currentPage, country, company,previousCompany, tags, industry, searchRgx]);

  // console.log(loading);

  // console.log(experts);

  const handleSearchExperts = (clearAll = false) => {
    let queryParams = queryString.parse(window.location.search);
    let handler;
    if (queryParams) {
      handler = setTimeout(async () => {
        getExperts(clearAll, true);
        filterRef.current = {
          ...filterRef.current,
          search: clearAll ? false : true,
        };
      }, 1000);
    } else {
      getExperts(clearAll, true);
    }
    return () => {
      clearTimeout(handler);
    };
  };

  const clearInput = () => {
    setWordEntered("");
    setSearchRgx("");
    if (!filterValues) {
      callFilter.current = false;
      callOnce.current = false;
      getExperts(true, true);
    }
  };

  document.title = "Nextyn | Consultants";
  const paginationSettings = {
    current: currentPage,
    onChange: handlePageChange,
    pageSize: 10,
    total: allDatas,
  };
  return (
    <>
      <div>
        <div className="d-block mobile-fill-width">
          <div className="top-bar-menu float-start" id="header"></div>
        </div>

        <div className="w-100">
          <div>
            <div className="top-bar-menu body-expart-div mb-3 mt-3">
              <div className="d-flex align-items-center justify-content-between">
                <TopMenu1
                  setSearchRgx={setSearchRgx}
                  searchRgx={searchRgx}
                  onlyFirstTimeAndSearch={onlyFirstTimeAndSearch}
                  wordEntered={wordEntered}
                  setWordEntered={setWordEntered}
                  clearInput={clearInput}
                />
                <div
                  style={{
                    fontSize: "30px",
                    marginLeft: "15px",
                    color: "#5746ec",
                    cursor: "pointer",
                  }}
                  className="d-xl-none"
                >
                  <Tooltip color="#5746EB" title={"Filter"} placement="topLeft">
                    {filter ? (
                      <>
                        <svg
                          stroke="currentColor"
                          onClick={() => setFilter(false)}
                          fill="currentColor"
                          stroke-width="0"
                          viewBox="0 0 24 24"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M10 14L4 5V3H20V5L14 14V20L10 22V14Z"></path>
                        </svg>
                      </>
                    ) : (
                      <>
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          onClick={() => setFilter(true)}
                          stroke-width="0"
                          viewBox="0 0 24 24"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M14 14V20L10 22V14L4 5V3H20V5L14 14ZM6.4037 5L12 13.3944L17.5963 5H6.4037Z"></path>
                        </svg>
                      </>
                    )}
                  </Tooltip>
                </div>
              </div>
            </div>
            <div className={filter ? "d-block" : "d-none d-xl-block"}>
              <SelectionOption
                stateList={{
                  current_company,
                  previous_company,
                  email,
                  industry,
                  country,
                  designation,
                  searchRgx,
                  currentPage,
                  experties,
                }}
                stateFunctionList={{
                  setCompany,
                  setPreviousCompany,
                  setEmail,
                  setIndustry,
                  setCountry,
                  setDesignation,
                  setExperties,
                  setCurrentPage,
                  setWordEntered,
                  setSearchRgx,
                }}
                handleSearchExperts={handleSearchExperts}
                stateListFilter={stateListFilter}
                stateFunctionListFilter={stateFunctionListFilter}
                callOnce={callOnce}
                endpoint="consultant_search"
                expert_type="consultant"
                callFilter={callFilter}
                getExpertsOptions={getExpertsOptions}
                filterRef={filterRef}
              />
            </div>
          </div>

          <div className="body-expart-div">
            <div className="d-flex align-items-center gap-2">
              <h2 style={{ marginTop: "5px", marginBottom: "10px" }}>
                {" "}
                Explore Our Customised Panel of Consultants
              </h2>
              <Tag color="processing" style={{}}>
                {allDatas}
              </Tag>
            </div>

            <div className="tab-content maxWidth" id="pills-tabContent">
              <div
                className="tab-pane pt-2 fade show active"
                id="pills-home"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
              >
                {!loading && !loadingOption ? (
                  <>
                    <TopIndustry experts={experts} getExperts={getExperts} />
                    {allDatas > 10 && (
                      <>
                        <>
                          <Pagination  {...paginationSettings}
                            showSizeChanger={false}
                            style={{ marginTop: 16, marginBottom: 16, textAlign: "right" }} />
                        </>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <div
                      className="d-flex justify-content-center"
                      style={{ marginTop: "20px" }}
                    >
                      <div role="status">
                        <Spin animation="border" />
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div
                className="tab-pane fade"
                id="pills-profile"
                role="tabpanel"
                aria-labelledby="pills-profile-tab"
              >
                ...
              </div>
              <div
                className="tab-pane fade"
                id="pills-contact"
                role="tabpanel"
                aria-labelledby="pills-contact-tab"
              >
                ...
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade projects-modal"
        id="expertmodal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog ">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Modal title
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="projects-modal-divs">
                <div className="d-flex position-relative align-items-center">
                  <h2 className="text-center mx-auto">
                    {" "}
                    How many experts would you like to consult?
                  </h2>
                </div>

                <div className="row mt-5">
                  <div className="col-lg-8 p-0 mx-auto d-flex justify-content-center">
                    <a id="#" className="one-ex-btn btn">
                      {" "}
                      One Expert{" "}
                    </a>
                    <a id="#" className="more-ex-btn btn">
                      {" "}
                      More than one{" "}
                    </a>
                  </div>
                  <hr className="col-lg-8 mt-4 mx-auto " />

                  <div className="notes col-lg-8 mx-auto p-0">
                    <span> NOTE: </span>{" "}
                    <span>
                      {" "}
                      If your project requires multiple experts, please click on
                      “More than one” and our consultants will reach out to you
                      to assist with a custom recruitment.{" "}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FindConsultant;
