import React, { useState, useEffect } from "react";
import { ExclamationCircleOutlined, CheckCircleOutlined } from "@ant-design/icons";
import { Modal, Tag, Tooltip, notification } from "antd";
import API_PATH from "../../Constants/api-path";
import { MdDelete } from "react-icons/md";
import { Spin } from "antd";

function NextynLi({ inviteTeamDashboard, project_id, getProjectDetails = () => { }, projectDetails = {} }) {
  const [Loading, setLoading] = useState(true);
  const [modal3Open, setModal3Open] = useState(false);
  const userData = JSON.parse(localStorage.getItem("userData"));

  const [intData, setInvtData] = useState("");

  useEffect(() => {
    setLoading(false);
  }, [inviteTeamDashboard]);

  const editName = (email) => {
    if (typeof email !== "string") {
      return "NoName";
    }

    let emailID = email.split("@")[0];
    let [firstName, lastName] = emailID.split(".");
    firstName = firstName.charAt(0).toUpperCase() + firstName.slice(1);
    lastName = lastName ? lastName.charAt(0).toUpperCase() + lastName.slice(1) : "";
    return `${firstName} ${lastName}`.trim();
  };

  const convertDate = (date) => {
    //console.log(date)
    if (date !== null && date !== "") {
      var d = new Date(date);
      var day = d.getDate();
      var x = d.toDateString().substr(4, 3);
      var year = d.getFullYear();
      let p = `${x} ${day}, ${year}`;
      //console.log(p)
      return p;
    } else {
      return "---------";
    }
  };


  // const editEmail = (email) => {
  //   const formattedName = email
  //     .split("@")[0]
  //     .split(".")
  //     .map((part) => part.charAt(0).toUpperCase() + part.slice(1))
  //     .join(" ");

  //   return `${formattedName}@nextyn.com`;
  // };

  const removeNextynFromEmail = (email) => {
    if (email && email.includes('@nextyn.com')) {
      return email.replace('@nextyn.com', '');
    }
    return email;
  };


  const DeleteInvite = async () => {
    // console.log(deleteId, "deleteId");
    // e.preventDefault();
    setLoading(true);

    try {
      const res = await fetch(`${API_PATH.PROJECT_INVITE_DELETE}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userData?.token}`,
        },
        body: JSON.stringify({
          project_id: project_id,
          invite_team: intData,
        }),
      });

      const result = await res.json();
      if (result) {
        if (result.status === 200 || result.status === true) {
          setModal3Open(false);
          notification.success({
            message: "Team member removed",
            icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
            style: {
              backgroundColor: "#2ecc71",
              color: "#fff !important",
              border: "1px solid #52c41a",
            },
            duration: 5,
            placement: "topRight",
          });
          // window.location.reload();
          getProjectDetails()
        } else {
          notification.error({
            message: "Error: Something went wrong server error",
            icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
            style: {
              backgroundColor: "#e74c3c",
              color: "#fff",
              border: "1px solid #c0392b",
            },
            duration: 5,
            placement: "topRight",
          });
        }
      }
    } catch (error) {
      setLoading(false);

      notification.error({
        message: "Error: Something went wrong server error",
        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
        style: {
          backgroundColor: "#e74c3c",
          color: "#fff",
          border: "1px solid #c0392b",
        },
        duration: 5,
        placement: "topRight",
      });
    }
    setLoading(false);
  };

  // const menu = (
  //   <Menu>
  //     <Menu.Item
  //       onClick={() => {
  //         setModal3Open(true);
  //       }}
  //       style={{
  //         display: "flex",
  //         alignItems: "center",
  //         justifyContent: "center",
  //       }}
  //     >
  //       <Space align="center" size={8}>
  //         <DeleteOutlined />
  //         <span>Delete</span>
  //       </Space>
  //     </Menu.Item>
  //   </Menu>
  // );

  const footer = (
    <div>
      <button key="cancel" className="secondaryBttn btn" onClick={() => setModal3Open(false)}>
        No
      </button>
      <button
        key="submit"
        className="viewButton btn ms-2"

        onClick={() => {
          setModal3Open(false);
          DeleteInvite();
        }}
      >
        Yes
      </button>
    </div>
  );

  const getInvite = (inviteData) => {
    console.log(inviteData, "inviteDatass");
    setInvtData(inviteData);
  };

  if (!Loading) {
    return (
      <>
        <div className="client-teams-ul-li mt-4">
          <ul className="list-unstyled">
            <>
              {projectDetails?.companies?.[0]
                ?.bd_owner?.[0]
                ?.name && projectDetails?.companies?.[0]
                  ?.cs_owner?.[0]
                  ?.name && <>  <li>
                    <div className="teams-li d-flex align-items-center">
                      <figure className="mb-0">
                        <div
                          key={"343534"}
                          className="avatarImg d-flex"
                        >
                          <img
                            width="40px"
                            src={`https://ui-avatars.com/api/?&rounded=true&font-size=0.38&background=EFEFEF&color=f33636&name=${editName(
                              projectDetails?.companies?.[0]
                                ?.bd_owner?.[0]
                                ?.name || "NoName"
                            )}`}
                            alt={
                              projectDetails?.companies?.[0]
                                ?.bd_owner?.[0]
                                ?.name || "NoName"
                            }
                          />
                        </div>
                      </figure>
                      <h5 className="mb-0 ms-2 mt-0" key={343534}>
                        {projectDetails?.companies?.[0]
                          ?.bd_owner?.[0]
                          ?.name
                          ? projectDetails?.companies?.[0]
                            ?.bd_owner?.[0]
                            ?.name
                          : null}{" "}
                        <Tag
                          size="small"
                          color="#5746ec"
                          style={{
                            color: "white",
                            fontSize: "10px",
                            paddingTop: "0px",
                            paddingBottom: "0px",
                            margin: "0px",
                            border: "none"
                          }}
                          bordered={false} >
                          BD OWNER
                        </Tag>
                        {projectDetails?.companies?.[0]
                          ?.cs_owner?.[0]
                          ?.email && (
                            <>
                              <span className="d-block">{`${projectDetails?.companies?.[0]
                                ?.bd_owner?.[0]
                                ?.email}`}</span>
                            </>
                          )}

                      </h5>
                    </div>
                  </li>
                  <li>
                    <div className="teams-li d-flex align-items-center">
                      <figure className="mb-0">
                        <div
                          key={"343534"}
                          className="avatarImg d-flex"
                        >
                          <img
                            width="40px"
                            src={`https://ui-avatars.com/api/?&rounded=true&font-size=0.38&background=EFEFEF&color=f33636&name=${editName(
                              projectDetails?.companies?.[0]
                                ?.cs_owner?.[0]
                                ?.name || "NoName"
                            )}`}
                            alt={
                              projectDetails?.companies?.[0]
                                ?.cs_owner?.[0]
                                ?.name || "NoName"
                            }
                          />
                        </div>
                      </figure>
                      <h5 className="mb-0 ms-2 mt-0" key={343534}>
                        {projectDetails?.companies?.[0]
                          ?.cs_owner?.[0]
                          ?.name
                          ? projectDetails?.companies?.[0]
                            ?.cs_owner?.[0]
                            ?.name
                          : null}{" "}
                        <Tag
                          size="small"
                          color="#5746ec"
                          style={{
                            color: "white",
                            fontSize: "10px",
                            paddingTop: "0px",
                            paddingBottom: "0px",
                            margin: "0px",
                            border: "none",
                            alignItems: "center"
                          }}
                          bordered={false}
                        >
                          CS OWNER
                        </Tag>
                        {projectDetails?.companies?.[0]
                          ?.cs_owner?.[0]
                          ?.email && (
                            <>
                              <span className="d-block">{`${projectDetails?.companies?.[0]
                                ?.cs_owner?.[0]
                                ?.email}`}</span>
                            </>
                          )}

                      </h5>
                    </div>
                  </li></>}
            </>
            {inviteTeamDashboard?.filter(item => item?.name !== userData?.data?.name) // Filter out items with matching name
              .map((item, index) => (
                <li key={index}>
                  <div className="teams-li">
                    <figure>
                      <div key={index} className="avatarImg d-flex">
                        <img
                          width="40px"
                          src={`https://ui-avatars.com/api/?&rounded=true&font-size=0.38&background=EFEFEF&color=f33636&name=${editName(
                            item.email || item.name || "NoName"
                          )}`}
                          alt={item.email || item.name || "NoName"}
                        />
                      </div>
                      {/* <Avatar
                  name={expert_name}
                  size="100%"
                  textSizeRatio={1.7}
                /> */}
                    </figure>
                    <h5 className="m-0" key={index}>
                      {item?.name ? editName(item?.email || item?.name) : null}
                      <Tag
                        size="small"
                        color="#5746ec"
                        style={{
                          color: "white",
                          fontSize: "10px",
                          paddingTop: "0px",
                          paddingBottom: "0px",
                          margin: "0px 3px",
                          border: "none"
                        }}
                        bordered={false} >
                        {item?.ownerType === "cs_owner" ? "CS OWNER" : item?.ownerType === "bd_owner" ? "BD OWNER" : ""}
                      </Tag>
                      {item?.email && !item?.email?.endsWith("@nextyn.com") ? (
                        <span className="d-block">{`${item?.email}@nextyn.com`}</span>
                      ) : (
                        <>
                          <span className="d-block">{`${item?.email}`}</span>
                        </>
                      )}
                      {/* <span className="d-block" style={{ color: "#c40a36" }}>
                        {item?.Invite === "Invite pending"
                          ? `Invite pending (sent ${convertDate(item?.created_at)})`
                          : null}
                      </span> */}
                    </h5>
                  </div>
                  {(item?.ownerType !== "cs_owner" && item?.ownerType !== "bd_owner") && <div className="dropdown">
                    <div>
                      <Tooltip color="#5746EB" title="Delete" placement="top">
                        <MdDelete
                          style={{ fontSize: "20px", cursor: "pointer" }}
                          onClick={() => {
                            setModal3Open(true);
                            getInvite(removeNextynFromEmail(item?.email));
                          }}
                        />
                      </Tooltip>

                      {/* <Dropdown overlay={menu} trigger={["click"]}>
                        <a onClick={(e) => e.preventDefault()}>
                          <EllipsisOutlined
                            style={{ fontSize: "20px" }}
                            onClick={() => getInvite(curElem)}
                          />
                        </a>
                      </Dropdown> */}
                    </div>
                  </div>}
                  <Modal
                    title={<span className="modalHeader">Are you sure?</span>}
                    open={modal3Open}
                    onOk={() => setModal3Open(false)}
                    onCancel={() => setModal3Open(false)}
                    visible={true}
                    footer={footer}
                    width={800}
                  >
                    <div
                      className="mt-4"
                      style={{ width: "100%", textAlign: "left" }}
                    >
                      <p>
                        Do you really want to delete these records? This process
                        cannot be undone.
                      </p>{" "}
                    </div>
                  </Modal>
                </li>
              ))}
          </ul>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="d-flex justify-content-center align-items-center text-center" style={{ minHeight: '100vh' }}>
          <Spin size="large" />
        </div>
      </>
    );
  }
}
export default NextynLi;
