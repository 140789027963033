import {
  UploadOutlined,
  ExclamationCircleOutlined,
  CheckCircleOutlined,
  LoadingOutlined
} from "@ant-design/icons";
import _ from "lodash";
import {
  Button,
  Dropdown,
  Menu,
  Modal,
  Pagination,
  Select,
  Space,
  Table,
  Upload,
  DatePicker,
  Tooltip,
  notification,
  Tag,
  Spin,
  Input,
  Divider,

} from "antd";
import { TbMessages } from "react-icons/tb";

import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import API_PATH from "../../Constants/api-path";
import "./CallRecords.css";
import SelectionOption from "./SelectionOption";
import moment from "moment";
import dayjs from "dayjs";
import { MdDelete, MdPayments } from "react-icons/md";
import { GrContactInfo } from "react-icons/gr";

import { LuPlus, LuRefreshCw } from "react-icons/lu";
import { Col, Row } from "react-bootstrap";
import axios from "axios";
import messageColoredSvg from "../../assests/images/message-colored.svg";
import { MdOutlineSpeakerNotes } from "react-icons/md";
import PaymentDetailsModal from "./PaymentDetailsModal";

const { Option } = Select;
const { RangePicker } = DatePicker;
const CallRecordTable = () => {
  let history = useNavigate();
  const [modal2Open, setModal2Open] = useState(false);
  const [clientInfoModalOpen, setClientInfoModalOpen] = useState(false);
  const [clientDetails, setClientDetails] = useState({});
  const [fileList, setFileList] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [showUpload, setShowUpload] = useState(false);
  const [hideFilter, setHideFilter] = useState(false);
  const userData = JSON.parse(localStorage.getItem("userData"));
  const [callList, setCallList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [callPage, setCallPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [isOpenCalModal, setIsOpenCalModal] = useState(false);
  const [managerList, setManagerList] = useState([]);
  const [callHistoryId, setCallHistoryId] = useState("");
  const [selectedExpert, setSelectedExpert] = useState(
    { open: false, data: {} }
  );
  const [selectedOptions, setSelectedOptions] = useState({
    project: "",
    company: "",
    expert: "",
    team_member: "",
    manager: "",
    invoice: "",
    sdate: "",
    edate: "",
    sortBy: "date",
    sortType: "-1"
  });
  const permission = useMemo(() => userData?.data?.roleData?.[0]?.role_permission,
    [userData?.data?.roleData]
  );
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const paginationSettings = useMemo(() => ({
    current: currentPage,
    onChange: handlePageChange,
    pageSize: 10,
    total: callPage,
  }), [callPage, currentPage]);


  const GetCallList = React.useCallback(async (page, selectedOptions) => {
    setLoading(true);

    try {
      let object = {};
      if (selectedOptions?.project) {
        object.project = selectedOptions?.project;
      }

      if (selectedOptions?.company) {
        object.company = selectedOptions?.company;
      }

      if (selectedOptions?.expert) {
        object.expert = selectedOptions?.expert;
      }

      if (selectedOptions?.team_member) {
        object.added_by = selectedOptions?.team_member;
      }
      if (selectedOptions?.manager) {
        object.manager_id = selectedOptions?.manager;
      }

      if (selectedOptions?.invoice) {
        object.invoice = selectedOptions.invoice;
      }

      if (selectedOptions?.sdate) {
        object.sdate = selectedOptions?.sdate;
      }

      if (selectedOptions?.edate) {
        object.edate = selectedOptions?.edate;
      }
      if (selectedOptions?.sortBy) {
        object.sortBy = selectedOptions?.sortBy;
      }
      if (selectedOptions?.sortType) {
        object.sortType = selectedOptions?.sortType;
      }
      object.email = userData?.data?.email

      const queryParams = new URLSearchParams(object); // Construct query parameters from the object properties

      const res = await fetch(
        `${API_PATH?.CALL_LIST
        }?page=${page}&limit=10&${queryParams?.toString()}`,
        {
          method: "GET",
          // params: {
          //   selectedOptions
          // },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userData?.token}`,
          },
        }
      );

      const result = await res.json();
      if (result) {
        setCallHistoryId(result?.data[0]?._id);
        setCallList(result?.data);
        setCallPage(result?.countData);
      } else {
        notification.error({
          message: "Error: Something went wrong server error",
          icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
          style: {
            backgroundColor: "#e74c3c",
            color: "#fff",
            border: "1px solid #c0392b",
          },
          duration: 5,
          placement: "topRight",
        });
      }
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }, [userData?.data?.email, userData?.token]);

  const handleDownloadCSV = React.useCallback(() => {
    // Extract specific properties and convert to CSV format
    const csvData = callList?.map((item) => {
      return [
        item?.date ? moment(item?.date)?.format("DD-MM-YYYY") : "NA",
        item?.project_title || "NA",
        Array.isArray(item?.user_id) && item?.user_id?.length > 0 ? `"${item?.user_id?.map((user) => user?.client_name)?.join(",")}"` : item?.client_name,
        Array.isArray(item?.user_id) && item?.user_id?.length > 0 ? `"${item?.user_id?.map((user) => user?.client_email)?.join(",")}"` : item?.client_email,
        `"${item?.client_rate || "NA"}${item?.client_rate ? item?.client_currency : ""}"` || "NA",
        item?.expert_email || "NA",
        item?.expert_name || "NA",
        `"${item?.expert_company}, ${item?.expert_designation}"` || "NA",
        `"${item?.expert_rate || "NA"}${item?.expert_rate ? item?.expert_currency : "NA"}"` || "NA",
        item?.call_duration || "NA",
        item?.invoice_status || "Not Sent",
        item?.client_payment_status || "NA",
        item?.expert_payment_status || "NA",
        item?.payment_mode || "NA",
        item?.payment_date
          ? dayjs(item?.payment_date)?.format("YYYY-MM-DD")
          : "NA",
        item?.lead_by || "NA",
        item?.manager?.[0]?.name || "NA",
        item?.expert_payment_status === "Yes" ? "Payment Done" : getPaymentDueIn(item) + " Days",
        item?.comment || "NA",
      ].join(",");
    });

    // Add headers for each column
    const csvContent = [
      [
        "Date",
        "Project Name",
        "Client Name",
        "Client Email",
        "Client Amount",
        "Expert Email",
        "Expert Name",
        "Expert Designation",
        "Expert Amount",
        "Call Duration",
        "Invoice Status",
        "Client Payment Status",
        "Expert Payment Status",
        "Payment Mode",
        "Payment Date",
        "Team",
        "Team Manager",
        "Payment Due In",
        "Comment",
      ]?.join(","),
      ...csvData,
    ]?.join("\n");

    // Create a Blob object and initiate a download
    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    // link.setAttribute("download", "call_list.csv");
    link.setAttribute("download", `Nextyn Call Records Data ${moment().format("DD-MM-YY")}.csv`);
    document.body.appendChild(link);
    link.click();

    // Cleanup
    URL.revokeObjectURL(url);
    document.body.removeChild(link);
  }, [callList]);

  const constructUrl = React.useCallback((selectedOptions) => {
    const { project, company, expert, team_member, manager, invoice, sdate, edate, sortBy, sortType } =
      selectedOptions || {};

    const projectParam = Array.isArray(project) ? project.join(", ") : project;
    const companyParam = Array.isArray(company) ? company.join(", ") : company;
    const expertParam = Array.isArray(expert) ? expert.join(", ") : expert;
    const teamParams = Array.isArray(team_member) ? team_member.join(", ") : team_member;
    const managerParams = Array.isArray(manager) ? manager.join(", ") : manager;
    const invoiceParam = Array.isArray(invoice) ? invoice.join(", ") : invoice;
    const sortByParam = sortBy;
    const sortTypeParam = sortType;

    const sanitizedProject = projectParam
      ? projectParam.replace(/%20/g, " ")
      : "";
    const sanitizedCompany = companyParam
      ? decodeURIComponent(companyParam)
      : "";
    const sanitizedExpert = expertParam ? decodeURIComponent(expertParam) : "";
    const sanitizedTeam = teamParams ? decodeURIComponent(teamParams) : "";
    const sanitizedManager = managerParams ? decodeURIComponent(managerParams) : "";
    const sanitizedInvoice = invoiceParam
      ? decodeURIComponent(invoiceParam)
      : "";

    const urlParams = [
      `project="${sanitizedProject}"`,
      `company="${sanitizedCompany}"`,
      `expert="${sanitizedExpert}"`,
      `added_by="${sanitizedTeam}"`,
      `manager_id="${sanitizedManager}"`,
      `invoice="${sanitizedInvoice}"`,
      `sdate=${sdate}`,
      `edate=${edate}`,
      `sortBy=${sortBy}`,
      `sortType=${sortType}`,
    ];

    return history({
      pathname: `/dashboard/call_records`,
      search: `?${urlParams.join("&")}`,
    });
  }, [history]);

  const GetManagerList = React.useCallback(async () => {
    setLoading(true);
    try {
      const res = await fetch(`${API_PATH.MANAGER_LIST}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userData?.token}`,
        },
      });

      const result = await res.json();
      if (result) {
        setManagerList(result?.data);
      } else {
        notification.error({
          message: "Error: Something went wrong server error",
          icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
          style: {
            backgroundColor: "#e74c3c",
            color: "#fff",
            border: "1px solid #c0392b",
          },
          duration: 5,
          placement: "topRight",
        });
      }
    } catch (error) {
      setLoading(false);

      notification.error({
        message: "Error: Something went wrong server error",
        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
        style: {
          backgroundColor: "#e74c3c",
          color: "#fff",
          border: "1px solid #c0392b",
        },
        duration: 5,
        placement: "topRight",
      });
    }
    setLoading(false);
  }, [userData?.token]);

  useEffect(() => {
    GetCallList(currentPage, selectedOptions);
    GetManagerList();
    constructUrl(selectedOptions);
  }, [GetCallList, GetManagerList, constructUrl, currentPage, selectedOptions]);

  const AddCallList = React.useCallback(async () => {
    setLoading(true);
    try {
      const res = await fetch(`${API_PATH.CALL_CREATE}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userData?.token}`,
        },
        body: JSON.stringify({
          company_id: "",
          date: "",
          project_title: "",
          project_id: "",
          user_id: "",
          client_company: "",
          expert_id: "",
          expert_email: "",
          client_email: "",
          client_rate: "",
          expert_rate: "",
          call_duration: "",
          invoice_status: "Not Sent",
          client_payment_status: "",
          expert_payment_status: "",
          payment_mode: "",
          comment: "",
          lead_by: "",
          extra: "",
          payment_date: "",
        }),
      });

      const result = await res.json();
      if (result) {
        if (res.status === 200 || res.status === true) {
          await GetCallList(currentPage);
          notification.success({
            message: "New row added",
            icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
            style: {
              backgroundColor: "#2ecc71",
              color: "#fff !important",
              border: "1px solid #52c41a",
            },
            duration: 5,
            placement: "topRight",
          });
        }
      } else {
        notification.error({
          message: "Error: Something went wrong server error",
          icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
          style: {
            backgroundColor: "#e74c3c",
            color: "#fff",
            border: "1px solid #c0392b",
          },
          duration: 5,
          placement: "topRight",
        });
      }
    } catch (error) {
      setLoading(false);

      notification.error({
        message: "Error: Something went wrong server error",
        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
        style: {
          backgroundColor: "#e74c3c",
          color: "#fff",
          border: "1px solid #c0392b",
        },
        duration: 5,
        placement: "topRight",
      });
    }
    setLoading(false);
  }, [GetCallList, currentPage, userData?.token]);

  const DeleteCallList = React.useCallback(async () => {
    setLoading(true);
    try {
      const res = await fetch(`${API_PATH.CALL_DELETE}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userData?.token}`,
        },
        body: JSON.stringify({
          call_history_id: [callHistoryId],
        }),
      });

      const result = await res.json();
      if (result) {
        GetCallList(currentPage);

        if (res.status === 200 || res.status === true) {
          notification.success({
            message: "Call records has been deleted",
            icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
            style: {
              backgroundColor: "#2ecc71",
              color: "#fff !important",
              border: "1px solid #52c41a",
            },
            duration: 5,
            placement: "topRight",
          });
        }
      } else {
        notification.error({
          message: "Error: Something went wrong server error",
          icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
          style: {
            backgroundColor: "#e74c3c",
            color: "#fff",
            border: "1px solid #c0392b",
          },
          duration: 5,
          placement: "topRight",
        });
      }
    } catch (error) {
      setLoading(false);

      notification.error({
        message: "Error: Something went wrong server error",
        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
        style: {
          backgroundColor: "#e74c3c",
          color: "#fff",
          border: "1px solid #c0392b",
        },
        duration: 5,
        placement: "topRight",
      });
    }
    setLoading(false);
  }, [GetCallList, callHistoryId, currentPage, userData?.token]);

  const UpdateCallList = React.useCallback(async (record) => {
    setLoading(true);
    try {
      const res = await fetch(`${API_PATH?.CALL_UPDATE}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userData?.token}`,
        },
        body: JSON.stringify({
          // company_id: record?.company_id || "",
          // date: record?.date || "",
          // project_title: record?.project_title || "",
          // project_id: record?.project_id || "",
          // user_id: record?.user_id || "",
          // client_company: record?.client_company || "",
          // expert_id: record?._id || "",
          // expert_email: record?.expert_email || "",
          // expert_name: record?.expert_name || "",
          // client_email: record?.client_email || "",
          // client_name: record?.client_name || "",
          // client_rate: record?.client_rate || "",
          // expert_rate: String(record?.expert_rate) || "",
          // call_duration: String(record?.call_duration) || "",
          invoice_status: record?.invoice_status || "Not Sent",
          client_payment_status: record?.client_payment_status || "",
          expert_payment_status: record?.expert_payment_status || "",
          payment_mode: record?.payment_mode || "",
          comment: record?.comment || "",
          // lead_by: record?.lead_by || "",
          payment_date: record?.payment_date || "",
          call_history_id: record?._id,
          // call_history_id: callHistoryId,
          _id: record?._id
        }),
      });

      const result = await res?.json();
      if (result) {
        GetCallList(currentPage);
        if (result?.status === 200 || result?.status === true) {
          // window.location.reload();
          notification.success({
            message: "Call Record Updated",
            icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
            style: {
              backgroundColor: "#2ecc71",
              color: "#fff !important",
              border: "1px solid #52c41a",
            },
            duration: 5,
            placement: "topRight",
          });
        }
      } else {
        notification.error({
          message: "Error: Something went wrong server error",
          icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
          style: {
            backgroundColor: "#e74c3c",
            color: "#fff",
            border: "1px solid #c0392b",
          },
          duration: 5,
          placement: "topRight",
        });
      }
    } catch (error) {
      setLoading(false);
      notification.error({
        message: "Error: Something went wrong server error",
        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
        style: {
          backgroundColor: "#e74c3c",
          color: "#fff",
          border: "1px solid #c0392b",
        },
        duration: 5,
        placement: "topRight",
      });
    }
    setLoading(false);
  }, [GetCallList, currentPage, userData?.token]);

  const handleDownload = React.useCallback(async () => {
    try {
      const response = await fetch(API_PATH.CALL_UPLOAD_CSV, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userData?.token}`,
        },
        body: JSON.stringify({ call_csv: "CALL_RECORDS.csv" }),
      });

      if (response.ok) {
        // Extract the CSV data from the response
        const csvData = await response.text();

        // Create a Blob object from the CSV data
        const blob = new Blob([csvData], { type: "text/csv" });

        // Create a URL for the Blob
        const url = window.URL.createObjectURL(blob);

        // Create a link element and trigger the download
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = "CALL_RECORDS.csv";
        document.body.appendChild(a);
        a.click();

        // Cleanup
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      } else {
        console.error("Failed to fetch CSV file:", response.status);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }, [userData?.token]);
  const rangePresets = [
    {
      label: 'Last 7 Days',
      value: [dayjs().add(-7, 'd'), dayjs()],
    },
    {
      label: 'Last 14 Days',
      value: [dayjs().add(-14, 'd'), dayjs()],
    },
    {
      label: 'Last 30 Days',
      value: [dayjs().add(-30, 'd'), dayjs()],
    },
    {
      label: 'Last 90 Days',
      value: [dayjs().add(-90, 'd'), dayjs()],
    },
  ];
  const menu = (
    <Menu>
      {/* <Menu.Item
        onClick={() => {
          AddCallList();
        }}
      >
        {permission?.[5]?.call_records?.add && <Space>
          <span>Add New Record</span>
        </Space>}
      </Menu.Item> */}
      <Menu.Item onClick={() => setShowUpload(!showUpload)}>
        <Space>
          <span>Upload CSV</span>
        </Space>
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          setIsOpenCalModal(true)
          // handleDownloadCSV()
        }}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Space align="center" size={8}>
          <span>Download As CSV</span>
        </Space>
      </Menu.Item>

      <Menu.Item
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        onClick={() => setHideFilter(!hideFilter)}
      >
        <Space align="center" size={8}>
          <span>{!hideFilter ? "Hide Filter" : "Filter"}</span>
        </Space>
      </Menu.Item>
    </Menu>
  );

  const inStatus = React.useMemo(() => [
    { key: 1, label: "Sent", value: "Sent" },
    { key: 2, label: "Not Sent", value: "Not Sent" },
  ], []);

  const client_pay = React.useMemo(() => [
    { key: 1, label: "Yes", value: "Yes" },
    { key: 2, label: "No", value: "No" },
  ], []);

  const expert_pay = React.useMemo(() => [
    { key: 1, label: "Yes", value: "Yes" },
    { key: 2, label: "No", value: "No" },
  ], []);

  const pay_mode = React.useMemo(() => [
    // { key: 1, label: "Select Payment Mode", value: "Select Payment Mode" },
    { key: 2, label: "Bank Transfer", value: "Bank Transfer" },
    { key: 3, label: "Amazon Gift Card", value: "Amazon Gift Card" },
    // { key: 4, label: "PayPal", value: "PayPal" },
    { key: 5, label: "Charity", value: "Charity" },
  ], []);

  const columns = [
    {
      title: "S.No.",
      dataIndex: "sr",
      key: "sr",
      render: (text, record, rowIndex) => (currentPage - 1) * 10 + (rowIndex + 1),
      width: "50px",
      align: "center"
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      width: "50px",
      align: "center",
      sorter: true
    },
    {
      title: "Project Name",
      dataIndex: "project_title",
      key: "project_title",
    },

    {
      title: "Client Name",
      dataIndex: "client_company",
      key: "client_company",
      align: "center"
    },
    // {
    //   title: "Client Users",
    //   dataIndex: "client_name",
    //   key: "client_name",
    // },
    {
      title: "Client Amount",
      dataIndex: "client_rate",
      key: "client_rate",
      align: "center"
    },
    {
      title: "Expert",
      dataIndex: "expert_name",
      key: "expert_name",
      align: "center"
    },
    {
      title: "Expert Designation",
      dataIndex: "expert_designation",
      key: "expert_designation",
      align: "center"
    },
    {
      title: "Expert Amount",
      dataIndex: "expert_rate",
      key: "expert_rate",
      align: "center"
    },
    {
      title: "Call Duration",
      dataIndex: "call_duration",
      key: "call_duration",
      align: "center"
    },
    {
      title: "Invoice Status",
      dataIndex: "invoice_status",
      key: "invoice_status",
      align: "center"
    },
    {
      title: "Client Payment Status",
      dataIndex: "client_payment_status",
      key: "client_payment_status",
      align: "center"
    },
    {
      title: "Expert Payment Status",
      dataIndex: "expert_payment_status",
      key: "expert_payment_status",
      align: "center"
    },
    {
      title: "Payment Mode",
      dataIndex: "payment_mode",
      key: "payment_mode",
      align: "center"
    },
    {
      title: "Payment Date",
      dataIndex: "payment_date",
      key: "payment_date",
      align: "center"
    },

    {
      title: "Team",
      dataIndex: "lead_by",
      key: "lead_by",
      align: "center"
    },
    {
      title: "Team Manager",
      dataIndex: "team_manager",
      key: "team_manager",
      align: "center"
    },
    {
      title: "Payment Due In",
      dataIndex: "payment_due_in",
      key: "payment_due_in",
      align: "center",
      sorter: true
    },
    {
      title: "Comment",
      dataIndex: "comment",
      key: "comment",
      align: "center"
    },
    {
      title: "Action",
      key: "action",
      dataIndex: "action",
      align: "center",
      render: (record) => (
        <>
          <div className="d-flex expertActions align-items-center gap-1">
            <Tooltip placement="top" title={!permission?.[5]?.call_records?.edit ? "Permission Denied" : "Update Data"} color="#5746EB">
              <span>
                <button
                  size="large"
                  disabled={!permission?.[5]?.call_records?.edit}
                  className="viewBttnExpert"
                  style={{
                    cursor: !permission?.[5]?.call_records?.edit ? "not-allowed" : "pointer",
                  }}
                  onClick={() => UpdateCallList(record)}
                >
                  {/* <LuPlus /> */}
                  <LuRefreshCw />
                </button>
              </span>
            </Tooltip>
            <Tooltip placement="top" title={!permission?.[5]?.call_records?.edit ? "Permission Denied" : "Delete"} color="#5746EB">
              <span>
                <button
                  size="large"
                  disabled={!permission?.[5]?.call_records?.edit}
                  className="viewBttnExpert"
                  onClick={() => {
                    setCallHistoryId(record?._id)
                    setModal2Open(true);
                  }}
                >
                  <MdDelete />
                </button>
              </span>
            </Tooltip>
          </div>
        </>
      ),
    },
  ];

  const handleChangeInvoice = React.useCallback((value, record, index) => {
    let updatedObj = { ...callList[index], invoice_status: value }
    let newCallList = [...callList]
    newCallList[index] = updatedObj
    setCallList(newCallList);
  }, [callList]);

  const handleChangeCLientPaymentRecieved = React.useCallback((value, record, index) => {
    let updatedObj = { ...callList[index], client_payment_status: value }
    let newCallList = [...callList]
    newCallList[index] = updatedObj
    setCallList(newCallList);
  }, [callList]);

  const handleChangeExpertPaymentStatus = React.useCallback((value, record, index) => {
    let updatedObj = { ...callList[index], expert_payment_status: value }
    let newCallList = [...callList]
    newCallList[index] = updatedObj
    setCallList(newCallList);
  }, [callList]);

  const handleChangePaymentMode = React.useCallback((value, record, index) => {
    let updatedObj = { ...callList[index], payment_mode: value }
    let newCallList = [...callList]
    newCallList[index] = updatedObj
    setCallList(newCallList);
  }, [callList]);

  const handleChangePaymentDate = React.useCallback((value, record, index) => {
    let updatedObj = { ...callList[index], payment_date: value }
    let newCallList = [...callList]
    newCallList[index] = updatedObj
    setCallList(newCallList);
  }, [callList]);

  const handleChangeComment = React.useCallback((value, record, index) => {
    let updatedObj = { ...callList[index], comment: value }
    let newCallList = [...callList]
    newCallList[index] = updatedObj
    setCallList(newCallList);
  }, [callList]);
  const truncatedComment = (comment, index) => {
    const truncatedTitle = comment
      ? comment.split(" ").slice(0, 5).join(" ")
      : "";
    const displayTitle =
      truncatedTitle !== comment
        ? truncatedTitle + "..."
        : truncatedTitle;
    return displayTitle
  }

  const handleChangeLeadBy = (value, record, index) => {
    record.lead_by = value;
    // setCallList([...callList]);
  };

  const navigate = useNavigate()
  const getPaymentDueIn = (user) => {
    let payment_terms = Number(user?.payment_terms)
    let current_date = moment()
    let call_date = moment(user?.date, "YYYY-MM-DD")
    let diffBetweenCurrentAndCallDate = current_date?.diff(call_date, 'days');
    return payment_terms - diffBetweenCurrentAndCallDate > 0 ? payment_terms - diffBetweenCurrentAndCallDate : 0

  }
  const dataSource = React.useMemo(() => callList?.map((client, index) => {
    let paymentModeModified = client?.payment_mode === "bank" ? "Bank Transfer" : client?.payment_mode === "amazon" ? "Amazon Gift Card" : client?.payment_mode === "charity" ? "Charity" : client?.payment_mode
    return ({
      date: <>
        <Space direction="vertical" size={24} width="100%">
          {client?.date ? moment.utc(client?.date)?.format("DD-MM-YYYY") : null}
        </Space>
      </>,
      project_title: <>
        <div className="link-text" style={{ cursor: "pointer", textAlign: "left", fontWeight: "600" }} onClick={() => {
          navigate(`/dashboard/team/project-overview?project_id=${client?.project_id}&status=1`)
        }} >
          {client?.project_title}
        </div>
      </>,
      client_company: <div style={{ cursor: "pointer" }}>
        {client?.client_company} {<Tooltip title={"Click for Client Users"} color="#5746EB"><GrContactInfo onClick={() => {
          setClientDetails(client)
          setClientInfoModalOpen(true)
        }} /></Tooltip>}
      </div>,
      expert_name: <div style={{ cursor: "pointer" }} >
        <div> {client?.expert_name} {client?.expert_payment_details?.length > 0 && <Tooltip title="Payment Details" color="#5746EB"><MdPayments onClick={() => {
          setSelectedExpert({
            open: true,
            data: client
          })
        }} /></Tooltip>}</div>
        <div style={{ fontSize: "10px" }}>
          {client?.expert_email}
        </div>
      </div>,
      expert_designation: <div style={{ cursor: "pointer" }} >
        <div>{client?.expert_company}</div>
        <div style={{ fontSize: "10px" }}>
          {<Tooltip color="#5746EB" placement="right" title={client?.expert_designation}>{_.truncate(client?.expert_designation, { length: 20, omission: "..." })}</Tooltip>}
        </div>
      </div>,
      client_name: <div style={{ cursor: "pointer" }} className="">
        {Array.isArray(client?.user_id) && client?.user_id?.length > 0 ?
          <div className="d-flex align-items-center gap-2">{client?.user_id?.map((user) =>
            <div className="">
              <div> {user?.client_name}</div>
              <div style={{ fontSize: 10 }}>{user?.client_email}</div>
            </div>
          )}
          </div> :
          <div className="">
            <div> {client?.client_name}</div>
            <div style={{ fontSize: 10 }}>{client?.client_email}</div>
          </div>}
      </div>,
      client_rate: <div style={{ cursor: "pointer", fontWeight: "600" }} >
        {![null, undefined]?.includes(client?.client_rate) ? client?.client_currency : ""}  {client?.client_rate ?? "NA"}
        {client?.client_remarks && <Tooltip title={client?.client_remarks} color="#5746EB">
          <MdOutlineSpeakerNotes className="ms-2 fs-6" />
        </Tooltip>}
      </div>,
      expert_rate: <div style={{ cursor: "pointer", fontWeight: "600" }} >
        {![null, undefined]?.includes(client?.expert_rate) ? client?.expert_currency : ""} {client?.expert_rate ?? "NA"}
        {client?.expert_remarks && <Tooltip title={client?.expert_remarks} color="#5746EB">
          <MdOutlineSpeakerNotes className="ms-2 fs-6" />
        </Tooltip>}
      </div>,
      call_duration: <span>{client?.call_duration} {!isNaN(client?.call_duration) && "mins."}</span>,
      invoice_status: <Select
        disabled={!permission?.[5]?.call_records?.edit}
        // || (client?.expert_payment_status === "Yes" && client?.expert_payment_status === "Yes")}
        value={client?.invoice_status || "Not Sent"}
        placeholder="Select Status"
        onChange={(e) => handleChangeInvoice(e, client, index)}
        style={{
          width: 120,
        }}
        size="large"
      >
        {inStatus.map((item) => (
          <Option key={item.key} value={item.value}>
            {item.label}
          </Option>
        ))}
      </Select>,
      client_payment_status: <Select
        disabled={!permission?.[5]?.call_records?.edit}

        value={client?.client_payment_status}
        onChange={(e) => handleChangeCLientPaymentRecieved(e, client, index)}
        style={{
          width: 120,
        }}
        size="large"
      >
        {client_pay.map((item) => (
          <Option key={item.key} value={item.value}>
            {item.label}
          </Option>
        ))}
      </Select>,
      expert_payment_status: <Select
        disabled={!permission?.[5]?.call_records?.edit}

        value={client?.expert_payment_status}
        onChange={(e) => handleChangeExpertPaymentStatus(e, client, index)}
        style={{
          width: 120,
        }}
        size="large"
      >
        {expert_pay.map((item) => (
          <Option key={item.key} value={item.value}>
            {item.label}
          </Option>
        ))}
      </Select>,
      payment_mode: <Select
        disabled={!permission?.[5]?.call_records?.edit}

        placeholder="Select Payment Mode"
        value={paymentModeModified ? paymentModeModified : null}
        onChange={(e) => handleChangePaymentMode(e, client, index)}
        style={{
          width: 180,
        }}
        size="large"
        virtual={false}
        allowClear
      >
        {pay_mode && pay_mode.length > 0 ? (
          pay_mode.map((item, index) => (
            <Option value={item?.value} key={index}>
              {item?.label}
            </Option>
          ))
        ) : (
          <option value="">No data found</option>
        )}
      </Select>,
      payment_date: <>
        <Space direction="vertical" size={24} width="100%">
          <DatePicker
            disabled={!permission?.[5]?.call_records?.edit || client?.expert_payment_status === "No"}

            value={client?.payment_date && dayjs(client?.payment_date, ["YYYY-MM-DD", "DD-MM-YYYY"])}
            format="YYYY-MM-DD"
            onChange={(date, dateString) =>
              handleChangePaymentDate(dateString, client, index)
            }
            className="custom-datepicker"
          />
        </Space>
      </>,
      payment_due_in: <>
        {client?.expert_payment_status === "Yes" ? <Tag
          color="blue"
          bordered={false}>Payment Done</Tag> : getPaymentDueIn(client) + " " + "Days"}</>,
      comment: <>
        {client?.comment && <Tooltip title={client?.comment} color="#5746EB">
          <MdOutlineSpeakerNotes className="ms-2 fs-6" />
        </Tooltip>}
      </>,
      lead_by: <div style={{ cursor: "pointer" }} >
        {client?.lead_by || "-"}
      </div>
      ,
      team_manager: <div>{client?.manager?.[0]?.name || "-"}</div>,
      action: client
    })
  })
    , [callList, client_pay, expert_pay, handleChangeCLientPaymentRecieved, handleChangeExpertPaymentStatus, handleChangeInvoice, handleChangePaymentDate, handleChangePaymentMode, inStatus, navigate, pay_mode, permission])



  const footer = (
    <div>
      <button
        key="cancel"
        className="secondaryBttn btn"
        onClick={() => setModal2Open(false)}
      >
        No
      </button>
      <button
        key="submit"
        className="viewButton btn ms-2"
        onClick={() => {
          DeleteCallList();
          setModal2Open(false);
        }}
      >
        Yes
      </button>
    </div>
  );

  const handleUpload = async () => {
    const formData = new FormData();
    fileList.forEach((file) => {
      formData.append("call_csv", file);
    });
    setUploading(true);
    try {
      await fetch(`${API_PATH.CALL_UPLOAD_CSV}`, {
        method: "POST",
        body: formData,
      })
        .then((res) => res.json())
        .then(() => {
          setFileList([]);
          notification.success({
            message: "Call records has been updated",
            icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
            style: {
              backgroundColor: "#2ecc71",
              color: "#fff !important",
              border: "1px solid #52c41a",
            },
            duration: 5,
            placement: "topRight",
          });
        })
        .catch(() => {
          notification.error({
            message: "Error: Ppload failed",
            icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
            style: {
              backgroundColor: "#e74c3c",
              color: "#fff",
              border: "1px solid #c0392b",
            },
            duration: 5,
            placement: "topRight",
          });
        })
        .finally(() => {
          setUploading(false);
        });
    } catch (error) {
      setLoading(false);

      notification.error({
        message: "Error: Something went wrong server error",
        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
        style: {
          backgroundColor: "#e74c3c",
          color: "#fff",
          border: "1px solid #c0392b",
        },
        duration: 5,
        placement: "topRight",
      });
    }
  };

  const props = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([...fileList, file]);
      return false;
    },
    fileList,
  };
  const [downloadRange, setDownloadRange] = useState({
    dates: null,
    dateStrings: ['', '']
  })
  const onRangeChange = (dates, dateStrings) => {
    setDownloadRange({
      dates: dates,
      dateStrings: dateStrings
    })
  };

  const handleDownloadCSVData = async () => {
    setIsDownloading(true);
    await axios.get(`${API_PATH.DOWNLOAD_CALL_HISTORY}?sdate=${downloadRange?.dateStrings[0]}&edate=${downloadRange?.dateStrings[1]}`)
      .then((res) => {
        setIsOpenCalModal(false)
        setDownloadRange({
          dates: null,
          dateStrings: ["", ""]
        })
        setIsDownloading(false)
        window.location = `${API_PATH.DOWNLOAD_CALL_HISTORY}?sdate=${downloadRange?.dateStrings[0]}&edate=${downloadRange?.dateStrings[1]}`;
      })
    // .catch(() => {
    //   notification.error({
    //     message: "Error: someting went wrong while downloading!",
    //     icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
    //     style: {
    //       backgroundColor: "#e74c3c",
    //       color: "#fff",
    //       border: "1px solid #c0392b",
    //     },
    //     duration: 5,
    //     placement: "topRight",
    //   });
    //   setIsDownloading(false)
    // });
  }
  return (
    <>
      <div>
        <div className="bk-div float-start w-100">
          {" "}
          {/* <hr className="sp-hr" /> */}
          <div className="body-expart-div">
            <div className="d-md-flex justify-content-between align-items-center">
              <h2>Call Records</h2>
              <div className="d-flex justify-content-end align-items-center">
                <Dropdown overlay={menu}>
                  <span
                    onClick={(e) => e.preventDefault()}
                    style={{
                      fontSize: "20px",
                      cursor: "pointer",
                      color: "rgb(87, 70, 235)",
                    }}
                  >
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      stroke-width="0"
                      viewBox="0 0 24 24"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill="none"
                        stroke-width="2"
                        d="M3,13 L3,11 L5,11 L5,13 L3,13 Z M11,12.9995001 L11,11 L12.9995001,11 L12.9995001,12.9995001 L11,12.9995001 Z M19,12.9995001 L19,11 L20.9995001,11 L20.9995001,12.9995001 L19,12.9995001 Z"
                      ></path>
                    </svg>
                  </span>
                </Dropdown>
              </div>
            </div>
            {showUpload && (
              <div>
                <div className="showUploadFunction mb-2">
                  <Button className="downloadBtn" onClick={handleDownload}>
                    Download
                  </Button>
                  <div>
                    <Upload {...props}>
                      <Button icon={<UploadOutlined />}>Select File</Button>
                    </Upload>
                    <Button
                      type="primary"
                      onClick={handleUpload}
                      disabled={fileList.length === 0}
                      loading={uploading}
                      style={{ marginTop: 16 }}
                    >
                      {uploading ? "Uploading" : "Start Upload"}
                    </Button>
                    <p style={{ fontSize: "11px", color: "red" }}>
                      Only CSV format Supported
                    </p>
                  </div>
                </div>
              </div>
            )}

            {!hideFilter ? (
              <div>
                <SelectionOption
                  setSelectedOptions={setSelectedOptions}
                  selectedOptions={selectedOptions}
                />
              </div>
            ) : null}

            <div className="mt-4">
              <Table
                dataSource={dataSource}
                columns={columns}
                // className="paddTable"
                onChange={(pagination, filter, sorter) => {
                  if (sorter?.columnKey === "date" && sorter?.order === "ascend") {
                    setSelectedOptions({
                      ...selectedOptions,
                      sortBy: "date",
                      sortType: 1
                    })

                  } else if (sorter?.columnKey === "date" && sorter?.order === "descend") {
                    setSelectedOptions({
                      ...selectedOptions,
                      sortBy: "date",
                      sortType: -1
                    })
                  } else if (sorter?.columnKey === "payment_due_in" && sorter?.order === "descend") {
                    setSelectedOptions({
                      ...selectedOptions,
                      sortBy: "payment_due_in",
                      sortType: -1
                    })
                  }
                  else if (sorter?.columnKey === "payment_due_in" && sorter?.order === "ascend") {
                    setSelectedOptions({
                      ...selectedOptions,
                      sortBy: "payment_due_in",
                      sortType: 1
                    })
                  }

                }}
                pagination={false}
                scroll={{ x: 800 }}
                loading={loading}
              />
              <Pagination
                {...paginationSettings}
                showSizeChanger={false}
                style={{ marginTop: 16, marginBottom: 16, textAlign: "right" }}
              />
            </div>
          </div>
        </div>
      </div>

      <Modal
        title={<div className="">Delete Call Record
          <Divider className="m-2" />
        </div>}
        open={modal2Open}
        onOk={() => setModal2Open(false)}
        onCancel={() => setModal2Open(false)}
        visible={true}
        footer={footer}
        width={800}
        centered
      >
        <div className="mt-4" style={{ width: "100%", textAlign: "left" }}>
          <p>
            Are you sure you want to delete this call record? The call will not be visible in the client and expert dashboard once deleted
          </p>{" "}
        </div>
      </Modal>
      <Modal
        title={
          <div className="modalHeader" style={{ fontSize: 18 }}>
            Client Users
            <Divider className="m-2" />
          </div>}
        open={clientInfoModalOpen}
        onOk={() => setClientInfoModalOpen(false)}
        onCancel={() => setClientInfoModalOpen(false)}
        visible={true}
        width={450}
        centered
        footer={false}
      >
        <div className="mt-4" style={{ width: "100%", textAlign: "left" }}>
          <div style={{ cursor: "pointer" }} className="">
            {clientDetails?.user_id?.length > 0 && Array.isArray(clientDetails?.user_id) ?
              < >
                {clientDetails?.user_id?.map((user, index) =>
                  <Row lg={12} className="d-flex align-items-center">
                    <Col lg={6} className="mb-2 gap-1 d-flex align-items-center">
                      <span>{index + 1}.</span>
                      <div style={{ fontSize: 15 }}> {user?.client_name}</div>
                    </Col>
                    <Col lg={6} className="" >
                      <div style={{ fontSize: 12 }}>{user?.client_email}</div>
                    </Col>
                  </Row>
                )}
              </> :
              <Row lg={12} className=" d-flex align-items-center">
                <Col lg={6} className="mb-2 gap-1 d-flex align-items-center">
                  <span>{1}.</span>
                  <div style={{ fontSize: 15 }}> {clientDetails?.client_name}</div>
                </Col>
                <Col lg={6} className="" >
                  <div style={{ fontSize: 12 }}>{clientDetails?.client_email}</div>
                </Col>
              </Row>
            }
          </div>
        </div>
      </Modal>

      <Modal
        title={<div className="modalHeader" style={{ fontSize: 18 }}>
          Select Date Range
          <Divider className="m-2" />
        </div>}
        open={isOpenCalModal}
        onOk={() => handleDownloadCSVData()}
        onCancel={() => setIsOpenCalModal(false)}
        width={450}
        className=""
        centered
        okText={isDownloading ? <><Spin indicator={<LoadingOutlined style={{
          color: "white",
        }} />} size="small" /> Downloading CSV</> : "Download CSV"}
      // footer={false}
      >
        <div className="mt-4" style={{ width: "100%", textAlign: "center" }}>
          <div style={{ cursor: "pointer", height: 50 }} className="">
            <RangePicker placement="bottomRight" value={downloadRange.dates} presets={rangePresets} onChange={onRangeChange} />

          </div>
        </div>
      </Modal>

      <PaymentDetailsModal selectedExpert={selectedExpert} setSelectedExpert={setSelectedExpert} />
    </>
  );
};

export default CallRecordTable;
